import React, { useState, useEffect } from "react";
import _, { escapeRegExp } from "lodash";
import { SearchResultData, Search, Input } from "semantic-ui-react";
import { GroceryDto } from "../../models/GroceryDto";
import classNames from "classnames";
interface GrocerySearch {
  availableGroceries: GroceryDto[];
  selectedGrocery?: GroceryDto;
  freetextValueChanged(freeTextGrocery: string): void;
  groceryChanged(grocery: GroceryDto): void;
  controlledValue?: string;
  helperClass?: string;
  placeholderText?: string;
}
export const GrocerySearch: React.FC<GrocerySearch> = (props) => {
  const {
    availableGroceries,
    selectedGrocery,
    groceryChanged,
    helperClass,
    placeholderText,
    controlledValue,
  } = props;
  const [searchValue, setSearchValue] = useState(
    selectedGrocery ? selectedGrocery.name : ""
  );
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    setSearchValue(controlledValue);
  }, [controlledValue]);
  const handleSearchChange = (e, { value }) => {
    setSearchValue(value);
    props.freetextValueChanged && props.freetextValueChanged(value);
    if (!value) {
      setSearchResults([]);
      return;
    }

    const re = new RegExp(escapeRegExp(value), "i");

    const isMatch = (result: GroceryDto) => re.test(result.name);
    const results = _.filter(availableGroceries, isMatch);

    setSearchResults(
      results
        .map((g) => {
          return {
            title: g.name,
          };
        })
        .sort((a, b) =>
          a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1
        )
    );
  };

  const onResultSelect = (e, data: SearchResultData) => {
    const name = data.result.title; //TODO type this
    const grocery = availableGroceries.find((g) => g.name === name);
    setSearchValue(grocery.name);
    groceryChanged(grocery);
  };

  return (
    <Search
      input={{ fluid: true }}
      size={"big"}
      icon={null}
      placeholder={placeholderText || "Search"}
      showNoResults={false}
      onResultSelect={onResultSelect}
      onSearchChange={handleSearchChange}
      results={searchResults}
      value={searchValue}
      aligned="left"
      className={classNames("grocery-search-input", helperClass)}
    />
  );
};
