import React, { useState } from "react";
import { RecipeDto } from "../../models/RecipeDto";
import _, { escapeRegExp } from "lodash";
import { SearchResultData, Search, Input } from "semantic-ui-react";
interface RecipeSearchProps {
  availableRecipes: RecipeDto[];
  selectedRecipe?: RecipeDto;
  recipeChanged(recipe: RecipeDto): void;
}
export const RecipeSearch: React.FC<RecipeSearchProps> = props => {
  const { availableRecipes, selectedRecipe, recipeChanged } = props;
  const [searchValue, setSearchValue] = useState(
    selectedRecipe ? selectedRecipe.recipeName : ""
  );
  const [searchResults, setSearchResults] = useState([]);

  const handleSearchChange = (e, { value }) => {
    setSearchValue(value);
    if (!value) {
      setSearchResults([]);
      return;
    }

    const re = new RegExp(escapeRegExp(value), "i");

    const isMatch = (result: RecipeDto) => re.test(result.recipeName);
    const results = _.filter(availableRecipes, isMatch);

    setSearchResults(
      results.map(g => {
        return {
          title: g.recipeName
        };
      })
    );
  };

  const onResultSelect = (e, data: SearchResultData) => {
    const name = data.result.title; //TODO type this
    const recipe = availableRecipes.find(r => r.recipeName === name);
    setSearchValue(recipe.recipeName);
    recipeChanged(recipe);
  };

  return (
    <Search
      as={Input}
      icon={null}
      placeholder="Search"
      showNoResults={false}
      onResultSelect={onResultSelect}
      onSearchChange={handleSearchChange}
      results={searchResults}
      value={searchValue}
      aligned="left"
    />
  );
};
