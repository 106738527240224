import * as React from "react";
import { EditNumberUpdate } from "../../components/EditNumberUpdate";
import { EditStringUpdate } from "../../components/EditStringUpdate";
import ClickToEditText from "../../components/inputs/ClickToEditText";
import UnitPicker from "../../components/inputs/UnitPicker";
import FlexContainer from "../../components/containers/FlexContainer";
import { Orientation } from "../../components/Enums";

enum PickerType {
  Cooking,
  Shopping
}

type QtyPickerProps = {
  unit?: string;
  qty?: number;
  itemId?: number;
  qtyUpdated(newVal: EditNumberUpdate): void;
  unitUpdated(newVal: EditStringUpdate): void;
  units: string[];
};

class QtyUnitPicker extends React.Component<QtyPickerProps, any> {
  render() {
    return (
      <FlexContainer orientation={Orientation.Horizontal}>
        <ClickToEditText
          currentValue={this.props.qty ? this.props.qty : 0}
          entityId={this.props.itemId ? this.props.itemId : 0}
          numberUpdated={this.props.qtyUpdated}
        />
        <span className="horizontal-child">-</span>
        <UnitPicker
          entityId={this.props.itemId ? this.props.itemId : 0}
          unitOptions={this.props.units}
          unitUpdate={this.props.unitUpdated}
          unitToPick={this.props.unit ? this.props.unit : "Each"}
        />
      </FlexContainer>
    );
  }
}

export default QtyUnitPicker;
export { PickerType };
