import { format } from "date-fns";
const formatStr = "EEE M/d";

const shortDateFormat = `MM-dd-yyyy`;
export const dayformat = (date: Date | string) => {
  if (typeof date === "string") {
    return format(new Date(date), formatStr);
  }

  return format(date, formatStr);
};

const superShortDateFormat = `MM-dd`;
export const superShortDate = (date: Date | string) => {
  if (typeof date === "string") {
    return format(new Date(date), superShortDateFormat);
  }

  return format(date, superShortDateFormat);
};

export const dayAndYearFormat = (date: Date | string) => {
  if (typeof date === "string") {
    return format(new Date(date), shortDateFormat);
  }

  return format(date, shortDateFormat);
};
