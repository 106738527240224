import React, { useState } from "react";
import { RecipeDto } from "../../models/RecipeDto";
import { GroceryDto } from "../../models/GroceryDto";
import { ClickableText } from "../../components/ClickableText";
import { eachDayOfInterval } from "date-fns";
import {
  Container,
  Table,
  Segment,
  Header,
  Button,
  Grid,
  Ref,
  Icon,
  Loader,
} from "semantic-ui-react";
import { PlannedRecipeDto } from "../../models/PlannedRecipeDto";
import FlexContainer from "../../components/containers/FlexContainer";
import {
  Orientation,
  JustifyContent,
  PushDirection,
} from "../../components/Enums";
import { PlanSummary } from "./PlanSummary";
import { RecipeAssignmentModal } from "./RecipeAssignmentModal";
import { dayformat } from "../../constants/dateFormatters";
import { PlannedFoodDto } from "../../models/PlannedFoodDto";
import FlexChild from "../../components/containers/FlexChild";
import { DayServingsDisplay } from "../display-components/DayServingsDisplay";
import { FoodAssignmentModal } from "./FoodAssignmentModal";
import { MealPlanDto } from "../../models/MealPlanDto";
import { convertToDate } from "../../util";
import { useFmgToast } from "../common/toast.hook";
import { MealPlanStatus } from "../../models/MealPlanStatus";
import { useHistory } from "react-router-dom";
import { routes } from "../../constants/routes";
import {
  DropResult,
  DragDropContext,
  Droppable,
  Draggable,
} from "react-beautiful-dnd";
import { PlanController } from "../../models/PlanController";

export enum PlanType {
  Recipe = "Recipe",
  Food = "Food",
}

export enum UnassignedSection {
  Recipe = "unassigned-recipes",
  Food = "unassigned-foods",
}
interface CreatePlanProps {
  mealPlan: MealPlanDto;
  availableRecipes: RecipeDto[];
  availableFoods: GroceryDto[];
  activatePlan(plan: MealPlanDto): void;
  onPlanChanged?(modifiedPlan: MealPlanDto): Promise<void>;
  isBusy: boolean;
  suggestedRecipes?: RecipeDto[];
}
export const CreatePlan: React.FC<CreatePlanProps> = ({
  mealPlan,
  availableRecipes,
  availableFoods,
  activatePlan,
  onPlanChanged,
  isBusy,
  suggestedRecipes,
}) => {
  const history = useHistory();

  const { plannedRecipes, plannedFoods } = mealPlan;
  const days = eachDayOfInterval({
    start: convertToDate(mealPlan.startDate),
    end: convertToDate(mealPlan.endDate),
  });

  const { addSuccess, addError } = useFmgToast();

  const [mealAssignmentModal, setMealAssignmentOpen] = useState<{
    visible: boolean;
    planRecipe?: PlannedRecipeDto;
    planFood?: PlannedFoodDto;
  }>({
    visible: false,
    planRecipe: null,
  });

  const [foodAssignmentModal, setFoodAssignmentOpen] = useState<{
    visible: boolean;
    planFood?: PlannedFoodDto;
  }>({
    visible: false,
    planFood: null,
  });

  const clearFoodModal = () => {
    setFoodAssignmentOpen({ visible: false, planFood: null });
  };

  const clearAssignmentModal = () => {
    setMealAssignmentOpen({
      visible: false,
      planRecipe: null,
    });
  };

  const recipeClicked = (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    plannedRecipe: PlannedRecipeDto
  ) => {
    e.stopPropagation();
    if (mealPlan.status === MealPlanStatus.Draft) {
      setMealAssignmentOpen({
        visible: true,
        planRecipe: plannedRecipe,
      });
    } else {
      history.push(routes.recipeDetails(plannedRecipe.recipeId));
    }
  };

  const onDragUpdate = (result) => {};

  const onDragStart = (dragger) => {
    console.log("drag start", dragger);
  };

  const onDragEnd = async (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const { destination, source, draggableId } = result;

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    //TODO allow dragging from assigned into unassigned area
    const updatedRecipe = plannedRecipes.find(
      (pr) =>
        pr.plannedRecipeId === Number(draggableId) ||
        String(pr.plannedRecipeId) === draggableId
    );

    const updatedFood = plannedFoods.find(
      (pf) =>
        pf.plannedFoodId === Number(draggableId) ||
        String(pf.plannedFoodId) === draggableId
    );

    if (!updatedRecipe && !updatedFood) {
      console.warn(`No match for draggable ID ${draggableId}`);
    }

    const dayIndex = Number(result.destination.droppableId);
    const newDay = days[dayIndex];
    if (!newDay) {
      return;
    }
    if (updatedRecipe) {
      const newRecipeValue: PlannedRecipeDto = {
        ...updatedRecipe,
        plannedDay: newDay,
      };
      const newRecipes = [
        ...plannedRecipes.filter(
          (p) => p.plannedRecipeId !== newRecipeValue.plannedRecipeId
        ),
        newRecipeValue,
      ];

      const updatedPlan: MealPlanDto = {
        ...mealPlan,
        plannedRecipes: newRecipes,
      };

      await onPlanChanged(updatedPlan);
    }

    if (updatedFood) {
      const newFood: PlannedFoodDto = {
        ...updatedFood,
        plannedDay: newDay,
      };

      const newFoods = [
        ...plannedFoods.filter(
          (f) => f.plannedFoodId !== newFood.plannedFoodId
        ),
        newFood,
      ];
      await onPlanChanged({
        ...mealPlan,
        plannedFoods: newFoods,
      });
    }
  };

  return (
    <Container>
      <PlanSummary
        servingsNeeded={mealPlan.targetServings}
        plannedFoods={mealPlan.plannedFoods || []}
        plannedRecipes={mealPlan.plannedRecipes || []}
        status={mealPlan.status}
      >
        <FlexChild pushDirection={PushDirection.Right}>
          {mealPlan.status === MealPlanStatus.Draft && (
            <Button onClick={() => activatePlan(mealPlan)} positive>
              Create Plan
            </Button>
          )}
        </FlexChild>
      </PlanSummary>
      <DragDropContext
        onDragEnd={onDragEnd}
        onDragUpdate={onDragUpdate}
        onDragStart={onDragStart}
      >
        <Segment loading={isBusy}>
          <Grid columns={2} divided stackable doubling>
            <Grid.Row>
              {mealPlan.status === MealPlanStatus.Draft && (
                <Grid.Column width={5}>
                  <FlexContainer orientation={Orientation.Horizontal}>
                    <Button
                      size={"big"}
                      positive
                      onClick={() => {
                        setMealAssignmentOpen({
                          visible: true,
                          planRecipe: {},
                        });
                      }}
                    >
                      <div>Add Recipe</div>
                    </Button>
                  </FlexContainer>
                </Grid.Column>
              )}
              <Grid.Column width={9}>
                <Header as="h4">Unassigned</Header>
                <Droppable droppableId={UnassignedSection.Recipe}>
                  {(droppable, snapshot) => (
                    <Ref innerRef={droppable.innerRef}>
                      <FlexContainer
                        {...droppable.droppableProps}
                        className={snapshot.isDraggingOver && "dragging-over"}
                        orientation={Orientation.Vertical}
                        justifyContent={JustifyContent.JustifyCenter}
                      >
                        {plannedRecipes &&
                          plannedRecipes
                            .filter((p) => !p.plannedDay)
                            .map((plannedRecipe, recIndex) => {
                              return (
                                <Draggable
                                  draggableId={String(
                                    plannedRecipe.plannedRecipeId
                                  )}
                                  key={plannedRecipe.plannedRecipeId}
                                  index={recIndex}
                                >
                                  {(provided, snapshot) => (
                                    <Ref innerRef={provided.innerRef}>
                                      <ClickableText
                                        isDragging={snapshot.isDragging}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        key={plannedRecipe.plannedRecipeId}
                                        onClick={(e) => {
                                          recipeClicked(e, plannedRecipe);
                                        }}
                                      >
                                        <DayServingsDisplay
                                          day={plannedRecipe}
                                        />
                                      </ClickableText>
                                    </Ref>
                                  )}
                                </Draggable>
                              );
                            })}
                        {droppable.placeholder}
                      </FlexContainer>
                    </Ref>
                  )}
                </Droppable>
                {mealPlan.status === MealPlanStatus.Draft && (
                  <>
                    <Header as="h4">Suggested</Header>
                    <Droppable droppableId={UnassignedSection.Food}>
                      {(droppable, snapshot) => (
                        <Ref innerRef={droppable.innerRef}>
                          <FlexContainer
                            className={
                              snapshot.isDraggingOver && "dragging-over"
                            }
                            {...droppable.droppableProps}
                            orientation={Orientation.Vertical}
                            justifyContent={JustifyContent.JustifyCenter}
                          >
                            {suggestedRecipes
                              .filter(
                                (sr) =>
                                  !mealPlan.plannedRecipes
                                    .map((r) => r.recipeId)
                                    .includes(sr.recipeId)
                              )
                              .map((recipe, recipeIndex) => {
                                return (
                                  <Draggable
                                    draggableId={String(recipe.recipeId)}
                                    key={recipe.recipeId}
                                    index={recipeIndex}
                                  >
                                    {(provided) => (
                                      <Ref innerRef={provided.innerRef}>
                                        <ClickableText
                                          key={recipe.recipeId}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          onClick={(e) => {
                                            recipeClicked(e, {
                                              addedIngredients:
                                                recipe.ingredients,
                                              recipeId: recipe.recipeId,
                                              recipeName: recipe.recipeName,
                                            });
                                            e.stopPropagation();
                                          }}
                                        >
                                          {recipe.recipeName}
                                        </ClickableText>
                                      </Ref>
                                    )}
                                  </Draggable>
                                );
                              })}
                            {droppable.placeholder}
                          </FlexContainer>
                        </Ref>
                      )}
                    </Droppable>
                  </>
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>

        <Segment className="no-padding" loading={isBusy}>
          <Table className="plan-table" striped unstackable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell className="day-header">Day</Table.HeaderCell>
                <Table.HeaderCell>Food</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {days.map((day, dayIndex) => {
                return (
                  <Table.Row key={dayIndex}>
                    <Table.Cell>
                      <ClickableText
                        onClick={() => {
                          setMealAssignmentOpen({
                            visible: true,
                            planRecipe: {
                              plannedDay: day,
                            },
                          });
                        }}
                      >
                        {dayformat(day)}
                      </ClickableText>
                    </Table.Cell>
                    <Droppable droppableId={String(dayIndex)}>
                      {(droppable, snapshot) => (
                        <Ref innerRef={droppable.innerRef}>
                          <Table.Cell
                            {...droppable.droppableProps}
                            className={
                              snapshot.isDraggingOver ? "dragging-over" : ""
                            }
                          >
                            <FlexContainer
                              orientation={Orientation.Vertical}
                              className="food-cell"
                            >
                              {plannedRecipes
                                .filter(
                                  (p) =>
                                    p.plannedDay &&
                                    dayformat(p.plannedDay) === dayformat(day)
                                )
                                .map((plannedRec, recIndex) => {
                                  return (
                                    <Draggable
                                      draggableId={String(
                                        plannedRec.plannedRecipeId
                                      )}
                                      key={plannedRec.plannedRecipeId}
                                      index={recIndex}
                                    >
                                      {(provided, snapshot) => (
                                        <Ref innerRef={provided.innerRef}>
                                          <ClickableText
                                            isDragging={snapshot.isDragging}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            key={plannedRec.plannedRecipeId}
                                            onClick={(e) => {
                                              recipeClicked(e, plannedRec);
                                            }}
                                          >
                                            <DayServingsDisplay
                                              day={plannedRec}
                                            />
                                          </ClickableText>
                                        </Ref>
                                      )}
                                    </Draggable>
                                  );
                                })}
                              {plannedFoods
                                .filter(
                                  (p) =>
                                    p.plannedDay &&
                                    dayformat(p.plannedDay) === dayformat(day)
                                )
                                .map((food, foodIndex) => {
                                  return (
                                    <Draggable
                                      draggableId={String(food.plannedFoodId)}
                                      key={food.plannedFoodId}
                                      index={foodIndex}
                                    >
                                      {(provided, snapshot) => (
                                        <Ref innerRef={provided.innerRef}>
                                          <ClickableText
                                            isDragging={snapshot.isDragging}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            key={food.plannedFoodId}
                                            onClick={() => {
                                              setFoodAssignmentOpen({
                                                visible: true,
                                                planFood: food,
                                              });
                                            }}
                                          >
                                            <DayServingsDisplay day={food} />
                                          </ClickableText>
                                        </Ref>
                                      )}
                                    </Draggable>
                                  );
                                })}
                            </FlexContainer>
                            {droppable.placeholder}
                          </Table.Cell>
                        </Ref>
                      )}
                    </Droppable>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </Segment>
      </DragDropContext>
      {foodAssignmentModal.visible && (
        <FoodAssignmentModal
          availableFoods={availableFoods}
          availableDates={days}
          alreadyPlannedFood={foodAssignmentModal.planFood}
          onAdd={async (newFood) => {
            try {
              const newFoods = [
                ...plannedFoods.filter(
                  (p) => p.plannedFoodId !== newFood.plannedFoodId
                ),
                newFood,
              ];
              await onPlanChanged({ ...mealPlan, plannedFoods: newFoods });
              setFoodAssignmentOpen({ visible: false, planFood: null });
            } catch (err) {
              addError(`There was an error saving, maybe try again`);
            }
          }}
          onCancel={() => clearFoodModal()}
          show={true}
          onDelete={async (planFoodId) => {
            const newFoods = plannedFoods.filter(
              (f) => f.plannedFoodId !== planFoodId
            );
            await onPlanChanged({ ...mealPlan, plannedFoods: newFoods });
            clearFoodModal();
          }}
        ></FoodAssignmentModal>
      )}
      {mealAssignmentModal.visible ? (
        <RecipeAssignmentModal
          show={true}
          onCancel={() => clearAssignmentModal()}
          onDelete={async (planRecipeId) => {
            const newRecipes = plannedRecipes.filter(
              (r) => r.plannedRecipeId !== planRecipeId
            );

            await onPlanChanged({
              ...mealPlan,
              plannedRecipes: newRecipes,
            });
            clearAssignmentModal();
          }}
          onSnooze={async (planRecipe) => {
            await PlanController.snoozeRecipe({
              recipeId: planRecipe.recipeId,
            });
            const newRecipes = plannedRecipes.filter(
              (r) => r.plannedRecipeId !== planRecipe.plannedRecipeId
            );

            await onPlanChanged({
              ...mealPlan,
              plannedRecipes: newRecipes,
            });
            clearAssignmentModal();
          }}
          alreadyPlannedRecipe={mealAssignmentModal.planRecipe}
          onAdd={async (newRec) => {
            const newRecipes = [
              ...plannedRecipes.filter(
                (p) => p.plannedRecipeId !== newRec.plannedRecipeId
              ),
              newRec,
            ];
            await onPlanChanged({
              ...mealPlan,
              plannedRecipes: newRecipes,
            });
            setMealAssignmentOpen({ visible: false, planRecipe: null });
          }}
          availableDates={days}
          availableRecipes={availableRecipes}
        />
      ) : null}
    </Container>
  );
};
