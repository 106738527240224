import React from "react";
import {
  Menu,
  Container,
  Button,
  Icon,
  Sidebar,
  Image,
  Responsive,
  Dropdown,
} from "semantic-ui-react";
import { UserInfo } from "../../models/UserInfo";
import { Link, NavLink } from "react-router-dom";
import _ from "lodash";
import { routes } from "../../constants/routes";

export const LoggedInNavBar: React.FC<{
  userInfo: UserInfo | undefined;
  logout?(): void | undefined;
}> = ({ userInfo, logout }) => {
  return (
    <NavBarContainer>
      <LogoMenuItem></LogoMenuItem>
      <Menu.Item as={NavLink} exact to="/">
        <Icon name={"clipboard list"} size="large"></Icon>
      </Menu.Item>
      <Menu.Item as={NavLink} to="/plans">
        <Icon size="large" name="map"></Icon>
      </Menu.Item>
      <Menu.Item as={NavLink} to={routes.todos}>
        <Icon size="large" name="checkmark box"></Icon>
      </Menu.Item>
      <Menu.Item>
        <Icon
          className={"hoverable"}
          name={"refresh"}
          onClick={() => window.location.reload()}
          size={"large"}
        ></Icon>
      </Menu.Item>
      <Menu.Menu className="horizontal-flex-container">
        <Dropdown item floating text="More">
          <Dropdown.Menu>
            <Dropdown.Item as={NavLink} to={routes.groceries}>
              Groceries
            </Dropdown.Item>
            <Dropdown.Item as={NavLink} to={routes.stores}>
              Stores
            </Dropdown.Item>
            <Dropdown.Item as={NavLink} to={routes.recipes}>
              Recipes
            </Dropdown.Item>
            <Dropdown.Item as={NavLink} to={routes.todoTemplates}>
              ToDo Templates
            </Dropdown.Item>
            <Dropdown.Item onClick={() => logout && logout()}>
              Log Out
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Menu.Menu>
      <Responsive minWidth={Responsive.onlyMobile.maxWidth}>
        <Menu.Item position="right">
          <Icon name="user"></Icon>
          {userInfo?.email}
        </Menu.Item>
      </Responsive>
    </NavBarContainer>
  );
};

export const NavBarContainer: React.FC = ({ children }) => {
  return (
    <Menu inverted fixed="top">
      <Container>{children}</Container>
    </Menu>
  );
};

export const LogoMenuItem = () => {
  return (
    <div>
      <Responsive minWidth={Responsive.onlyMobile.maxWidth}>
        <Menu.Item as={Link} to="/" header>
          <img src="/assets/cart.png" alt="logo"></img>
          FindMyGroceries
        </Menu.Item>
      </Responsive>
    </div>
  );
};

export const LoggedOutNavBar = () => {
  return (
    <NavBarContainer>
      <LogoMenuItem></LogoMenuItem>
      <Menu.Item as={NavLink} to="/login">
        <Button>Log In</Button>
      </Menu.Item>
      <Menu.Item as={NavLink} to="/register">
        Register
      </Menu.Item>
    </NavBarContainer>
  );
};

const NavBarMobile = ({
  children,
  leftItems,
  onPusherClick,
  onToggle,
  rightItems,
  visible,
}) => {
  return (
    <Sidebar.Pushable>
      <Sidebar
        as={Menu}
        animation="overlay"
        icon="labeled"
        inverted
        items={leftItems}
        vertical
        visible={visible}
      />
      <Sidebar.Pusher
        dimmed={visible}
        onClick={onPusherClick}
        style={{ minHeight: "100vh" }}
      >
        <Menu fixed="top" inverted>
          <Menu.Item>
            <Image size="mini" src="https://react.semantic-ui.com/logo.png" />
          </Menu.Item>
          <Menu.Item onClick={onToggle}>
            <Icon name="sidebar" />
          </Menu.Item>
          <Menu.Menu position="right">
            {_.map(rightItems, (item) => (
              <Menu.Item {...item} />
            ))}
          </Menu.Menu>
        </Menu>
        {children}
      </Sidebar.Pusher>
    </Sidebar.Pushable>
  );
};
