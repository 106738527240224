import React from "react";
import { Table } from "semantic-ui-react";
import QtyUnitPicker from "./QtyUnitPicker";
import { EditStringUpdate } from "../../components/EditStringUpdate";
import { EditNumberUpdate } from "../../components/EditNumberUpdate";
import BigCheckbox from "../../components/BigCheckbox";
import { unitsOfMeasure } from "../../constants/units";
import { IngredientDTO } from "../../models/IngredientDTO";
interface AddIngredientsTableProps {
  ingredients: IngredientDTO[];
  ingredientsUpdated(updatedIngredients: IngredientDTO[]): void;
}
export const AddIngredientsTable: React.FC<AddIngredientsTableProps> = (
  props
) => {
  const { ingredients, ingredientsUpdated } = props;

  function matchingIngredientByName(
    ingred: IngredientDTO
  ): IngredientDTO | undefined {
    const existingIngred = ingredients.find(
      (i) => i.ingredientName && i.ingredientName === ingred.ingredientName
    );

    return existingIngred;
  }

  return (
    <Table unstackable>
      <thead>
        <tr>
          <th>Ingredient</th>
          <th>Shopping Qty</th>
          <th>Add</th>
        </tr>
      </thead>
      <tbody>
        {ingredients &&
          ingredients.map((ingred) => {
            return (
              <tr key={ingred.ingredientId}>
                <td>{ingred.ingredientName}</td>
                <td>
                  <QtyUnitPicker
                    unit={ingred.shoppingUnit}
                    unitUpdated={(newVal: EditStringUpdate) => {
                      const existingIngred = matchingIngredientByName(ingred);
                      if (existingIngred) {
                        existingIngred.shoppingUnit = newVal.newVal;
                        ingredientsUpdated(ingredients);
                      }
                    }}
                    qty={ingred.shoppingQuantity}
                    qtyUpdated={(newVal: EditNumberUpdate) => {
                      if (ingredients) {
                        const existingIngred = matchingIngredientByName(ingred);
                        if (existingIngred) {
                          existingIngred.shoppingQuantity = newVal.newVal;
                          ingredientsUpdated(ingredients);
                        }
                      }
                    }}
                    units={unitsOfMeasure}
                  />
                </td>
                <td>
                  <BigCheckbox
                    isChecked={ingred.addToShoppingList ? true : false}
                    handlechange={() => {
                      if (ingredients) {
                        const existingIngred = matchingIngredientByName(ingred);
                        if (existingIngred) {
                          existingIngred.addToShoppingList = !existingIngred.addToShoppingList;
                          ingredientsUpdated(ingredients);
                        }
                      }
                    }}
                  />
                </td>
              </tr>
            );
          })}
      </tbody>
    </Table>
  );
};
