


    // $Classes/Enums/Interfaces(filter)[template][separator]
    // filter (optional): Matches the name or full name of the current item. * = match any, wrap in [] to match attributes or prefix with : to match interfaces or base classes.
    // template: The template to repeat for each matched item
    // separator (optional): A separator template that is placed between all templates e.g. $Properties[public $name: $Type][, ]

    // More info: http://frhagn.github.io/Typewriter/
import axios from "axios";
import {ApiResult} from  "./ApiResult";


import { RecipeDto } from './RecipeDto';
import { IngredientDTO } from './IngredientDTO';
import { RecipeStepDTO } from './RecipeStepDTO';


export interface IRecipesController {

 
        
         all():Promise<ApiResult<RecipeDto[]>>;
        
        
         modifyRecipeAttribute(recipe: RecipeDto):Promise<ApiResult<RecipeDto>>;
        
        
         upsertIngredient(ingredient: IngredientDTO):Promise<ApiResult<IngredientDTO>>;
        
        
         deleteIngredient(ingredient: IngredientDTO):Promise<ApiResult<IngredientDTO>>;
        
        
         saveAllSteps(steps: RecipeStepDTO[]):Promise<ApiResult<RecipeDto>>;
        
        
         createRecipe(recipe: RecipeDto):Promise<ApiResult<RecipeDto>>;
        
        
         deleteRecipe(recipe: RecipeDto):Promise<ApiResult<{}>>;
           
	
}
export const RecipesController :IRecipesController =
{

 
	all():Promise<ApiResult<RecipeDto[]>>{
		return new Promise<ApiResult<RecipeDto[]>>((resolve, reject) => {
		axios
			.post("/Recipes/All",)
			.then(resp => {
				const vm = resp.data as ApiResult<RecipeDto[]>;
				if(vm.successInd){
					resolve(vm);
				}
				
				reject(vm.errorMessage);
			})
			.catch(err => {
				reject(err);
			});
		});
	},

	modifyRecipeAttribute(recipe: RecipeDto):Promise<ApiResult<RecipeDto>>{
		return new Promise<ApiResult<RecipeDto>>((resolve, reject) => {
		axios
			.post("/Recipes/ModifyRecipeAttribute",recipe)
			.then(resp => {
				const vm = resp.data as ApiResult<RecipeDto>;
				if(vm.successInd){
					resolve(vm);
				}
				
				reject(vm.errorMessage);
			})
			.catch(err => {
				reject(err);
			});
		});
	},

	upsertIngredient(ingredient: IngredientDTO):Promise<ApiResult<IngredientDTO>>{
		return new Promise<ApiResult<IngredientDTO>>((resolve, reject) => {
		axios
			.post("/Recipes/UpsertIngredient",ingredient)
			.then(resp => {
				const vm = resp.data as ApiResult<IngredientDTO>;
				if(vm.successInd){
					resolve(vm);
				}
				
				reject(vm.errorMessage);
			})
			.catch(err => {
				reject(err);
			});
		});
	},

	deleteIngredient(ingredient: IngredientDTO):Promise<ApiResult<IngredientDTO>>{
		return new Promise<ApiResult<IngredientDTO>>((resolve, reject) => {
		axios
			.post("/Recipes/DeleteIngredient",ingredient)
			.then(resp => {
				const vm = resp.data as ApiResult<IngredientDTO>;
				if(vm.successInd){
					resolve(vm);
				}
				
				reject(vm.errorMessage);
			})
			.catch(err => {
				reject(err);
			});
		});
	},

	saveAllSteps(steps: RecipeStepDTO[]):Promise<ApiResult<RecipeDto>>{
		return new Promise<ApiResult<RecipeDto>>((resolve, reject) => {
		axios
			.post("/Recipes/SaveAllSteps",steps)
			.then(resp => {
				const vm = resp.data as ApiResult<RecipeDto>;
				if(vm.successInd){
					resolve(vm);
				}
				
				reject(vm.errorMessage);
			})
			.catch(err => {
				reject(err);
			});
		});
	},

	createRecipe(recipe: RecipeDto):Promise<ApiResult<RecipeDto>>{
		return new Promise<ApiResult<RecipeDto>>((resolve, reject) => {
		axios
			.post("/Recipes/CreateRecipe",recipe)
			.then(resp => {
				const vm = resp.data as ApiResult<RecipeDto>;
				if(vm.successInd){
					resolve(vm);
				}
				
				reject(vm.errorMessage);
			})
			.catch(err => {
				reject(err);
			});
		});
	},

	deleteRecipe(recipe: RecipeDto):Promise<ApiResult<{}>>{
		return new Promise<ApiResult<{}>>((resolve, reject) => {
		axios
			.post("/Recipes/DeleteRecipe",recipe)
			.then(resp => {
				const vm = resp.data as ApiResult<{}>;
				if(vm.successInd){
					resolve(vm);
				}
				
				reject(vm.errorMessage);
			})
			.catch(err => {
				reject(err);
			});
		});
	},


}


/*
Class:RecipesController
Get Type args:RecipeDto[]RecipeDto=False
Get Type args:RecipeDto
Get Type args:IngredientDTO
Get Type args:IngredientDTO
Get Type args:RecipeDto
Get Type args:RecipeDto
Returning null for method:DeleteRecipe
RecipeDto
IngredientDTO
IngredientDTO
RecipeStepDTO[]
Sub typeRecipeStepDTO[]
RecipeStepDTO
RecipeDto
RecipeDto
RecipeDto
RecipeDto
IngredientDTO
IngredientDTO
RecipeDto
RecipeDto
GenerateImportsForType nullimport { RecipeDto } from './RecipeDto';
import { IngredientDTO } from './IngredientDTO';
import { RecipeStepDTO } from './RecipeStepDTO';
Class:RecipesController
Get Type args:RecipeDto[]RecipeDto=False
Get Type args:RecipeDto
Get Type args:IngredientDTO
Get Type args:IngredientDTO
Get Type args:RecipeDto
Get Type args:RecipeDto
Returning null for method:DeleteRecipe
RecipeDto
IngredientDTO
IngredientDTO
RecipeStepDTO[]
Sub typeRecipeStepDTO[]
RecipeStepDTO
RecipeDto
RecipeDto
RecipeDto
RecipeDto
IngredientDTO
IngredientDTO
RecipeDto
RecipeDto
GenerateImportsForType nullimport { RecipeDto } from './RecipeDto';
import { IngredientDTO } from './IngredientDTO';
import { RecipeStepDTO } from './RecipeStepDTO';
Class:RecipesController
Get Type args:RecipeDto[]RecipeDto=False
Get Type args:RecipeDto
Get Type args:IngredientDTO
Get Type args:IngredientDTO
Get Type args:RecipeDto
Get Type args:RecipeDto
Returning null for method:DeleteRecipe
RecipeDto
IngredientDTO
IngredientDTO
RecipeStepDTO[]
Sub typeRecipeStepDTO[]
RecipeStepDTO
RecipeDto
RecipeDto
RecipeDto
RecipeDto
IngredientDTO
IngredientDTO
RecipeDto
RecipeDto
GenerateImportsForType nullimport { RecipeDto } from './RecipeDto';
import { IngredientDTO } from './IngredientDTO';
import { RecipeStepDTO } from './RecipeStepDTO';
Class:RecipesController
Get Type args:RecipeDto[]RecipeDto=False
Get Type args:RecipeDto
Get Type args:IngredientDTO
Get Type args:IngredientDTO
Get Type args:RecipeDto
Get Type args:RecipeDto
Returning null for method:DeleteRecipe
RecipeDto
IngredientDTO
IngredientDTO
RecipeStepDTO[]
Sub typeRecipeStepDTO[]
RecipeStepDTO
RecipeDto
RecipeDto
RecipeDto
RecipeDto
IngredientDTO
IngredientDTO
RecipeDto
RecipeDto
GenerateImportsForType nullimport { RecipeDto } from './RecipeDto';
import { IngredientDTO } from './IngredientDTO';
import { RecipeStepDTO } from './RecipeStepDTO';
Class:RecipesController
Get Type args:RecipeDto[]RecipeDto=False
Get Type args:RecipeDto
Get Type args:IngredientDTO
Get Type args:IngredientDTO
Get Type args:RecipeDto
Get Type args:RecipeDto
Returning null for method:DeleteRecipe
RecipeDto
IngredientDTO
IngredientDTO
RecipeStepDTO[]
Sub typeRecipeStepDTO[]
RecipeStepDTO
RecipeDto
RecipeDto
RecipeDto
RecipeDto
IngredientDTO
IngredientDTO
RecipeDto
RecipeDto
GenerateImportsForType nullimport { RecipeDto } from './RecipeDto';
import { IngredientDTO } from './IngredientDTO';
import { RecipeStepDTO } from './RecipeStepDTO';
Class:RecipesController
Get Type args:RecipeDto[]RecipeDto=False
Get Type args:RecipeDto
Get Type args:IngredientDTO
Get Type args:IngredientDTO
Get Type args:RecipeDto
Get Type args:RecipeDto
Returning null for method:DeleteRecipe
RecipeDto
IngredientDTO
IngredientDTO
RecipeStepDTO[]
Sub typeRecipeStepDTO[]
RecipeStepDTO
RecipeDto
RecipeDto
RecipeDto
RecipeDto
IngredientDTO
IngredientDTO
RecipeDto
RecipeDto
GenerateImportsForType nullimport { RecipeDto } from './RecipeDto';
import { IngredientDTO } from './IngredientDTO';
import { RecipeStepDTO } from './RecipeStepDTO';
Class:RecipesController
Get Type args:RecipeDto[]RecipeDto=False
Get Type args:RecipeDto
Get Type args:IngredientDTO
Get Type args:IngredientDTO
Get Type args:RecipeDto
Get Type args:RecipeDto
Returning null for method:DeleteRecipe
RecipeDto
IngredientDTO
IngredientDTO
RecipeStepDTO[]
Sub typeRecipeStepDTO[]
RecipeStepDTO
RecipeDto
RecipeDto
RecipeDto
RecipeDto
IngredientDTO
IngredientDTO
RecipeDto
RecipeDto
GenerateImportsForType nullimport { RecipeDto } from './RecipeDto';
import { IngredientDTO } from './IngredientDTO';
import { RecipeStepDTO } from './RecipeStepDTO';

*/