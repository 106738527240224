import React, { Component, useState, useContext } from "react";
import { Form, Button } from "semantic-ui-react";
import { UserLoginForm } from "../../models/UserInfo";
import { UserContext } from "../../UserProvider";

type LoginFormProps = {
  loginHandler(form: UserLoginForm): Promise<{}>;
};

export const LoginForm = (props: LoginFormProps) => {
  const [form, setForm] = useState({
    email: "",
    password: "",
    submittedEmail: "",
    submittedPassword: "",
    submitting: false,
  });
  const userContext = useContext(UserContext);

  const { operations } = userContext;

  const handlePasswordChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setForm({ ...form, password: e.target.value });
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setForm({ ...form, email: e.target.value });
  };

  return (
    <Form
      onSubmit={() => {
        setForm({ ...form, submitting: true });
        operations
          ?.login({ email: form.email, password: form.password })
          .then(() => {
            setForm({ ...form, submitting: false });
          })
          .catch((err) => {
            setForm({ ...form, submitting: false });
          });
      }}
      loading={form.submitting}
    >
      <Form.Field>
        <label>Email</label>
        <input
          value={form.email}
          onChange={handleEmailChange}
          placeholder="Email"
        />
      </Form.Field>
      <Form.Field>
        <label>Password</label>
        <input
          value={form.password}
          onChange={handlePasswordChange}
          type="password"
          placeholder="Password"
        />
      </Form.Field>
      <Button loading={form.submitting} type="submit">
        Submit
      </Button>
    </Form>
  );
};
