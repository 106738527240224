import React from "react";
import { AutoPlanFrequency } from "../models/AutoPlanFrequency";

type FrequencyProps = {
  mealType?: string;
  settings?: AutoPlanFrequency;
};

export const FrequencyDetailsDisplay = (props: FrequencyProps) => {
  const { settings, mealType } = props;
  return (
    <>
      <h4>Frequency</h4>
      <span>
        Every {settings?.value || 0} {settings?.frequency}
      </span>
      <h4>Meal Type</h4>
      <span>{mealType}</span>
    </>
  );
};
