import { useCallback } from "react";
import { useToasts } from "react-toast-notifications";

export const useFmgToast = () => {
  const { addToast } = useToasts();

  const addSuccess = useCallback(
    (message: string) => {
      addToast(message, { appearance: "success" });
    },
    [addToast]
  );

  const addError = useCallback(
    (message: string) => {
      addToast(message, { appearance: "error" });
    },
    [addToast]
  );

  const addWarning = useCallback(
    (message: string) => {
      addToast(message, { appearance: "warning" });
    },
    [addToast]
  );

  return {
    addSuccess,
    addError,
    addWarning,
  };
};
