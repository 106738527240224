import { useState, useEffect, useContext } from "react";
import { RecipesController } from "../../models/RecipesController";
import { filterRecipes, sortRecipes } from "../../sorting";
import { RecipeDto } from "../../models/RecipeDto";
import { SortDirection } from "../../components/Enums";
import { useRecipes } from "../recipes/useRecipes";

export type SortState = {
  columnName: keyof RecipeDto;
  direction: SortDirection;
};

export const useHomePageRecipes = (
  recipeSort: SortState,
  recipeFilter: string
) => {
  const { recipes, modifyRecipeDetails, addRecipe, isAdding, isModifying } =
    useRecipes();

  return {
    recipes,
    visibleRecipes: sortRecipes(
      filterRecipes(recipes, recipeFilter),
      recipeSort.columnName,
      recipeSort.direction
    ),
    modifyRecipeDetails,
    addRecipe,
    isAdding,
    isModifying,
  };
};
