import { PlannedFoodDto } from "../models/PlannedFoodDto";
import { PlannedRecipeDto } from "../models/PlannedRecipeDto";
import { PlanType } from "./plan/CreatePlan";

export function getPlanType(
  plannedSomething: PlannedFoodDto | PlannedRecipeDto
): PlanType {
  if ((plannedSomething as PlannedRecipeDto).recipeName) {
    return PlanType.Recipe;
  }

  // if ((plannedSomething as PlannedFoodDto).groceryName) {
  return PlanType.Food;
  // }
}
