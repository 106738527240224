import { useMutation, useQuery } from "react-query";
import { queryClient } from "../..";
import { ToDos } from "../../constants/magicStrings";
import { ToDoController } from "../../models/ToDoController";
import { ToDoListTemplateDto } from "../../models/ToDoListTemplateDto";
import { ToDoTemplateDto } from "../../models/ToDoTemplateDto";

export const useTemplates = () => {
  const { data, isLoading } = useQuery(
    ToDos.TEMPLATES,
    ToDoController.getActiveTemplates
  );

  const { mutateAsync: createTemplate, isLoading: creating } = useMutation(
    (dto: ToDoListTemplateDto) => {
      return ToDoController.createListTemplate(dto);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(ToDos.TEMPLATES);
      },
    }
  );

  return {
    createTemplate,
    creating,
    templates:
      data?.data.sort((a, b) => (a.listName > b.listName ? 1 : -1)) || [],
    isLoading,
  };
};

export const useTemplate = (templateId: number) => {
  const query = useQuery([ToDos.TEMPLATE_DETAILS, templateId], async () => {
    return await ToDoController.templateDetails({
      toDoListTemplateId: templateId,
    });
  });

  const { mutateAsync: deleteTemplate, isLoading: deleting } = useMutation(
    (dto: ToDoListTemplateDto) => {
      return ToDoController.deleteTemplate(dto);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(ToDos.TEMPLATES);
      },
    }
  );

  const { mutateAsync: removeFromTemplate, isLoading: removing } = useMutation(
    (dto: ToDoTemplateDto) => {
      return ToDoController.deleteFromTemplate(dto);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([ToDos.TEMPLATE_DETAILS, templateId]);
      },
    }
  );

  const {
    mutateAsync: upsertToDoTemplate,
    isLoading: isUpdating,
    isSuccess: mutationSuccess,
  } = useMutation(
    (dto: ToDoTemplateDto) => {
      return ToDoController.upsertToDoTemplate(dto);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([ToDos.TEMPLATE_DETAILS, templateId]);
      },
    }
  );

  return {
    details: query.data?.data,
    isLoading: query.isLoading,
    isMutating: query.isLoading || removing || isUpdating || deleting,
    mutationSuccess,
    removeFromTemplate,
    upsertToDoTemplate,
    deleteTemplate,
  };
};
