import * as React from "react";
import { useGrocerySelection } from "../home/shoppingListHook";
import { RouteComponentProps } from "react-router-dom";
import { useFmgToast } from "../common/toast.hook";
import { CreatePlan } from "./CreatePlan";
import { useRecipes } from "../recipes/useRecipes";
import { Loader } from "semantic-ui-react";
import { PlanController } from "../../models/PlanController";
import { usePlanDetails } from "./usePlan";

type RouteProps = {
  mealPlanId: string;
};

type ModalOrRoute = RouteComponentProps<RouteProps>;

export const MealPlanDetailsPage: React.FC<ModalOrRoute> = (props) => {
  const mealPlanId = props.match.params.mealPlanId;

  const {
    planDetails: thisMealPlan,
    updateMealPlan,
    suggestedRecipes,
    invalidateQuery,
  } = usePlanDetails(Number(mealPlanId));
  const [isPlanUpdating, setIsPlanUpdating] = React.useState(false);

  const { recipes } = useRecipes();
  const { availableGroceries } = useGrocerySelection();
  const { addSuccess, addError } = useFmgToast();

  if (!thisMealPlan) {
    return <Loader></Loader>;
  }

  return (
    <CreatePlan
      mealPlan={thisMealPlan}
      isBusy={isPlanUpdating}
      suggestedRecipes={suggestedRecipes}
      availableFoods={availableGroceries}
      availableRecipes={recipes}
      onPlanChanged={async (modifiedPlan) => {
        try {
          setIsPlanUpdating(true);
          await updateMealPlan(modifiedPlan);
          addSuccess(`Meal plan updated successfully`);
        } finally {
          setIsPlanUpdating(false);
        }
      }}
      activatePlan={async (planToCreate) => {
        try {
          setIsPlanUpdating(true);
          await PlanController.activatePlan({
            ...planToCreate,
            mealPlanId: thisMealPlan.mealPlanId,
          });
          addSuccess(`Meal plan created successfully`);
          invalidateQuery();
        } catch (error) {
          addError(`There was an error saving the plan`);
        } finally {
          setIsPlanUpdating(false);
        }
      }}
    ></CreatePlan>
  );
};
