export const routes = {
  login: "/user/login",
  home: "/",
  recipes: "/recipes",
  stores: "/stores",
  plans: "/plans",
  storeDetails: (id: string | number) => `/stores/${id}`,
  recipeDetails: (id: string | number) => `/recipes/${id}`,
  planDetails: (id: string | number) => `/plans/${id}`,
  recipePhoto: (id: string | number) => `/photo/GetRecipePhoto/${id}`,
  saveRecipePhoto: `/photo/AddRecipePhoto`,
  groceryDetails: (id: string | number) => `/groceries/${id}`,
  groceries: "/groceries",
  todos: "/todos",
  todoGroup: (groupId: string | number) => `/todos/group/${groupId}`,
  todoList: (id: string | number) => `todos/lists/${id}`,
  todoTemplates: `/todoTemplates`,
  todoTemplate: {
    route: `/todoTemplates/:templateId`,
    navigate: (id: string | number) => `/todoTemplates/${id}`,
  },
};
