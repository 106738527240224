export const jwtKey = "jwt";

export const ToDos = {
  ACTIVE_LISTS: "activeLists",
  COMPLETED_LISTS: "completedLists",
  LIST_DETAILS: "listDetails",
  TEMPLATES: "templates",
  TEMPLATE_DETAILS: "templateDetails",
};

export const Shopping = {
  SHOPPING_LIST: "shoppingList",
};

export const GROCERIES = {
  AVAILABLE_GROCERIES: "availableGroceries",
  GROCERY_DETAILS: "groceryDetails",
};

export const RECIPES = {
  RECIPES: "recipes",
  RECIPE_DETAILS: "recipeDetails",
};

export const Plan = {
  PLAN_DETAILS: "planDetails",
  MEAL_PLANS: "mealPlans",
  SUGGESTED_RECIPES: "suggestedRecipes",
};
