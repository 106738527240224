import { RouteComponentProps, useHistory } from "react-router-dom";
import React from "react";
import {
  Header,
  Segment,
  Table,
  Button,
  Icon,
  Grid,
  Loader,
  Responsive,
} from "semantic-ui-react";
import { GroceryDto } from "../../models/GroceryDto";
import { ClickableText } from "../../components/ClickableText";
import { routes } from "../../constants/routes";
import { useFmgToast } from "../common/toast.hook";
import { useAvailableGroceries } from "./useAvailableGroceries";

export const GroceryDashboard: React.FC<RouteComponentProps> = (props) => {
  const { groceries, isBusy, deleteGrocery } = useAvailableGroceries();

  const { addWarning } = useFmgToast();

  return (
    <Grid.Column mobile={16} computer={8}>
      <GroceryTable count={groceries.length} isBusy={isBusy}>
        {groceries
          .sort((a, b) =>
            a.name?.toLocaleLowerCase() > b.name?.toLocaleLowerCase() ? 1 : -1
          )
          .map((grocery) => {
            return (
              <GroceryTableRow
                key={grocery.groceryId}
                grocery={grocery}
                onDelete={async () => {
                  await deleteGrocery(grocery);
                  addWarning(`Removed ${grocery.name}`);
                }}
              ></GroceryTableRow>
            );
          })}
      </GroceryTable>
    </Grid.Column>
  );
};

type GroceryRowProps = {
  grocery: GroceryDto;
  onDelete: (grocery: GroceryDto) => void;
};

function GroceryTableRow(props: GroceryRowProps) {
  const { grocery, onDelete } = props;
  const history = useHistory();
  return (
    <Table.Row>
      <Table.Cell>
        <ClickableText
          onClick={() => history.push(routes.groceryDetails(grocery.groceryId))}
        >
          {grocery.name}
        </ClickableText>
      </Table.Cell>
      <Table.Cell>
        {grocery.organic ? (
          <span>
            Yes <Icon size="small" name="leaf" color={"green"}></Icon>
          </span>
        ) : (
          "No"
        )}
      </Table.Cell>
      <Table.Cell>
        {grocery.isSaving ? (
          <Loader inline active></Loader>
        ) : (
          <Button
            icon
            negative
            size={"tiny"}
            onClick={() => {
              onDelete(grocery);
            }}
          >
            <Icon name="trash"></Icon>
          </Button>
        )}
      </Table.Cell>
      <Table.Cell>
        {grocery.autoPlanSettings?.value &&
          `${grocery.autoPlanSettings?.value} ${grocery.autoPlanSettings?.frequency}`}
      </Table.Cell>
      <Responsive as={Table.Cell} minWidth={Responsive.onlyMobile.maxWidth}>
        {grocery.mealType}
      </Responsive>
    </Table.Row>
  );
}

type GroceryTableProps = {
  isBusy: boolean;
  count: number;
};
function GroceryTable(props: React.PropsWithChildren<GroceryTableProps>) {
  const { children, isBusy } = props;

  return (
    <React.Fragment>
      <Header attached="top" as="h4" block>
        Groceries - {props.count}
      </Header>
      <Segment loading={isBusy} attached={"bottom"}>
        <Table unstackable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>Organic</Table.HeaderCell>
              <Table.HeaderCell>Delete</Table.HeaderCell>
              <Table.HeaderCell>
                <Icon name="calendar"></Icon>
                <Icon name="sync"></Icon>
              </Table.HeaderCell>
              <Responsive
                as={Table.HeaderCell}
                minWidth={Responsive.onlyMobile.maxWidth}
              >
                Meal Type
              </Responsive>
            </Table.Row>
          </Table.Header>
          <Table.Body>{children}</Table.Body>
        </Table>
      </Segment>
    </React.Fragment>
  );
}
