import * as React from "react";
import {
  Table,
  TransitionGroup,
  Responsive,
  Header,
  Segment,
} from "semantic-ui-react";
import FlexContainer from "../../../components/containers/FlexContainer";
import FlexChild from "../../../components/containers/FlexChild";
import {
  PushDirection,
  Orientation,
  Alignment,
} from "../../../components/Enums";

export const GroceryTable = ({
  grocerySearchComponent,
  showHideButtons,
  children,
  checkedCount,
  totalCount,
  isBusy,
  // onRefresh,
  ...other
}) => {
  return (
    <div>
      <Header attached="top" as="h4" block>
        <FlexContainer
          orientation={Orientation.Horizontal}
          alignment={Alignment.Center}
        >
          <span>
            Your List - {checkedCount || 0} / {totalCount || 0}
          </span>
          <FlexChild pushDirection={PushDirection.Right}>
            {showHideButtons}
          </FlexChild>
        </FlexContainer>
      </Header>
      <Segment loading={isBusy} attached="bottom" className="table-container">
        {grocerySearchComponent}
        <Table striped id="mainTable" unstackable {...other}>
          <Table.Header>
            <Table.Row>
              <Responsive
                as={Table.HeaderCell}
                minWidth={Responsive.onlyMobile.maxWidth}
              />
              <Table.HeaderCell>Item</Table.HeaderCell>
              <Table.HeaderCell>Location</Table.HeaderCell>
              <Table.HeaderCell>Qty</Table.HeaderCell>
              <Table.HeaderCell>Unit</Table.HeaderCell>
              <Table.HeaderCell textAlign={"center"}>Done</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <TransitionGroup children={children}></TransitionGroup>
          </Table.Body>
        </Table>
      </Segment>
    </div>
  );
};
