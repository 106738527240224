import React from "react";
import { Table } from "semantic-ui-react";
export const PlanDashboardTable = ({ children }) => {
  return (
    <Table color={"green"} sortable unstackable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Start Date</Table.HeaderCell>
          <Table.HeaderCell>EndDate</Table.HeaderCell>
          <Table.HeaderCell>Status</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>{children}</Table.Body>
    </Table>
  );
};
