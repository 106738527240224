import React, { useContext } from "react";
import LocationPicker from "../../../components/inputs/LocationPicker";
import QuantityBox from "../../../components/inputs/QuantityBox";
import UnitPicker from "../../../components/inputs/UnitPicker";
import BigCheckbox from "../../../components/BigCheckbox";
import {
  Table,
  Icon,
  Button,
  Responsive,
  Loader,
  Popup,
} from "semantic-ui-react";
import cn from "classnames";
import { ActiveGroceryDto } from "../../../models/ActiveGroceryDto";
import { StoreLocationDto } from "../../../models/StoreLocationDto";
import { unitsOfMeasure } from "../../../constants/units";
import { ListUpdate } from "../../../models/ListUpdate";
import { UpdateType } from "../../../models/UpdateType";
import { GroceryStoreLocationDto } from "../../../models/GroceryStoreLocationDto";
import { ClickableText } from "../../../components/ClickableText";
import { useHistory } from "react-router-dom";
import { routes } from "../../../constants/routes";
import { useAvailableGroceries } from "../../groceries/useAvailableGroceries";
interface RowProps {
  grocery: ActiveGroceryDto;
  rowUpdate?(update: ListUpdate): void;
  isUpdating?: boolean;
  locationUpdate?(dto: GroceryStoreLocationDto): void;
  storeLocations?: StoreLocationDto[];
}

export const GroceryTableRow = (props: RowProps) => {
  const { grocery, storeLocations: locationOptions } = props;
  const { groceries } = useAvailableGroceries();
  const largestWord = grocery.groceryName
    ?.split(" ")
    .reduce((a, b) => (a.length > b.length ? a : b));

  const history = useHistory();
  const groceryClicked = (grocery: ActiveGroceryDto) => {
    history.push(routes.groceryDetails(grocery.groceryId));
  };

  const isOrganic =
    groceries?.find((g) => g.groceryId === grocery.groceryId)?.organic || false;

  return (
    <Responsive
      as={Table.Row}
      key={grocery.groceryId}
      className="check-element"
    >
      <Responsive as={Table.Cell} minWidth={Responsive.onlyMobile.maxWidth}>
        {!grocery.isSaving && (
          <Button
            icon
            negative
            size={"tiny"}
            onClick={() => {
              props.rowUpdate({
                groceryId: grocery.groceryId,
                updateType: UpdateType.Deleted,
              });
            }}
          >
            <Icon name="trash"></Icon>
          </Button>
        )}
      </Responsive>
      <Responsive
        className={cn(
          "grocery-name",
          largestWord && largestWord?.length > 11 && "grocery-name-shrink"
        )}
        as={Table.Cell}
        maxWidth={Responsive.onlyMobile.maxWidth}
      >
        <ClickableText onClick={() => groceryClicked(grocery)}>
          {grocery.groceryName}
          {grocery.addedFromRecipeName && (
            <Popup
              content="Added from recipe"
              trigger={<Icon name={"book"} />}
            />
          )}
        </ClickableText>
        {isOrganic && <Icon size="small" name="leaf" color={"green"}></Icon>}
      </Responsive>
      <Responsive
        className={cn("grocery-name")}
        as={Table.Cell}
        minWidth={Responsive.onlyMobile.maxWidth}
      >
        <ClickableText onClick={() => groceryClicked(grocery)}>
          {grocery.groceryName}
          {grocery.addedFromRecipeName && (
            <Popup
              content="Added from recipe"
              trigger={<Icon name={"book"} />}
            />
          )}
        </ClickableText>
        {isOrganic && <Icon size="small" name="leaf" color={"green"}></Icon>}
      </Responsive>
      <Table.Cell
        className={cn("grocery-location", !grocery.locationName && "unknown")}
      >
        <LocationPicker
          locationToPick={{ locationName: grocery.locationName }}
          locationOptions={locationOptions}
          locationUpdated={(newLocation, grocery) => {
            const locDto: GroceryStoreLocationDto = {
              storeId: newLocation.storeId,
              groceryId: grocery.groceryId,
              locationName: newLocation.locationName,
              storeLocationId: newLocation.storeLocationId,
            };
            props.locationUpdate(locDto);
          }}
          grocery={grocery}
        />
      </Table.Cell>
      <Table.Cell className="groceryQty">
        <QuantityBox
          grocery={grocery}
          qtyUpdated={(newQty) =>
            props.rowUpdate({
              updateType: UpdateType.Quantity,
              groceryId: grocery.groceryId,
              quantity: newQty,
            })
          }
        />
      </Table.Cell>
      <Table.Cell>
        <UnitPicker
          entityId={grocery.groceryId}
          unitOptions={unitsOfMeasure}
          unitToPick={grocery.unit}
          unitUpdate={(newUnit) => {
            props.rowUpdate({
              groceryId: grocery.groceryId,
              updateType: UpdateType.UnitOFMeasure,
              unit: newUnit.newVal,
            });
          }}
        />
      </Table.Cell>
      <Table.Cell textAlign={"center"}>
        {grocery.isSaving ? (
          <Loader inline active></Loader>
        ) : (
          <BigCheckbox
            isChecked={grocery.isChecked}
            handlechange={(e) => {
              props.rowUpdate({
                groceryId: grocery.groceryId,
                updateType: UpdateType.ListState,
                isChecked: !grocery.isChecked,
              });
            }}
          />
        )}
      </Table.Cell>
    </Responsive>
  );
};
