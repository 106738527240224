


    // $Classes/Enums/Interfaces(filter)[template][separator]
    // filter (optional): Matches the name or full name of the current item. * = match any, wrap in [] to match attributes or prefix with : to match interfaces or base classes.
    // template: The template to repeat for each matched item
    // separator (optional): A separator template that is placed between all templates e.g. $Properties[public $name: $Type][, ]

    // More info: http://frhagn.github.io/Typewriter/
import axios from "axios";
import {ApiResult} from  "./ApiResult";


import { MealPlanDto } from './MealPlanDto';
import { GroceryDto } from './GroceryDto';
import { RecipeDto } from './RecipeDto';


export interface IPlanController {

 
        
         createDraftPlan(plan: MealPlanDto):Promise<ApiResult<MealPlanDto>>;
        
        
         getNonArchivedPlans():Promise<ApiResult<MealPlanDto[]>>;
        
        
         getAllPlans():Promise<ApiResult<MealPlanDto[]>>;
        
        
         activatePlan(updatedPlan: MealPlanDto):Promise<ApiResult<MealPlanDto>>;
        
        
         updatePlan(updatedPlan: MealPlanDto):Promise<ApiResult<MealPlanDto>>;
        
        
         snoozeFood(grocery: GroceryDto):Promise<ApiResult<GroceryDto>>;
        
        
         snoozeRecipe(recipe: RecipeDto):Promise<ApiResult<RecipeDto>>;
        
        
         planDetails(plan: MealPlanDto):Promise<ApiResult<MealPlanDto>>;
        
        
         suggestedRecipes(plan: MealPlanDto):Promise<ApiResult<RecipeDto[]>>;
           
	
}
export const PlanController :IPlanController =
{

 
	createDraftPlan(plan: MealPlanDto):Promise<ApiResult<MealPlanDto>>{
		return new Promise<ApiResult<MealPlanDto>>((resolve, reject) => {
		axios
			.post("/Plan/CreateDraftPlan",plan)
			.then(resp => {
				const vm = resp.data as ApiResult<MealPlanDto>;
				if(vm.successInd){
					resolve(vm);
				}
				
				reject(vm.errorMessage);
			})
			.catch(err => {
				reject(err);
			});
		});
	},

	getNonArchivedPlans():Promise<ApiResult<MealPlanDto[]>>{
		return new Promise<ApiResult<MealPlanDto[]>>((resolve, reject) => {
		axios
			.post("/Plan/GetNonArchivedPlans",)
			.then(resp => {
				const vm = resp.data as ApiResult<MealPlanDto[]>;
				if(vm.successInd){
					resolve(vm);
				}
				
				reject(vm.errorMessage);
			})
			.catch(err => {
				reject(err);
			});
		});
	},

	getAllPlans():Promise<ApiResult<MealPlanDto[]>>{
		return new Promise<ApiResult<MealPlanDto[]>>((resolve, reject) => {
		axios
			.post("/Plan/GetAllPlans",)
			.then(resp => {
				const vm = resp.data as ApiResult<MealPlanDto[]>;
				if(vm.successInd){
					resolve(vm);
				}
				
				reject(vm.errorMessage);
			})
			.catch(err => {
				reject(err);
			});
		});
	},

	activatePlan(updatedPlan: MealPlanDto):Promise<ApiResult<MealPlanDto>>{
		return new Promise<ApiResult<MealPlanDto>>((resolve, reject) => {
		axios
			.post("/Plan/ActivatePlan",updatedPlan)
			.then(resp => {
				const vm = resp.data as ApiResult<MealPlanDto>;
				if(vm.successInd){
					resolve(vm);
				}
				
				reject(vm.errorMessage);
			})
			.catch(err => {
				reject(err);
			});
		});
	},

	updatePlan(updatedPlan: MealPlanDto):Promise<ApiResult<MealPlanDto>>{
		return new Promise<ApiResult<MealPlanDto>>((resolve, reject) => {
		axios
			.post("/Plan/UpdatePlan",updatedPlan)
			.then(resp => {
				const vm = resp.data as ApiResult<MealPlanDto>;
				if(vm.successInd){
					resolve(vm);
				}
				
				reject(vm.errorMessage);
			})
			.catch(err => {
				reject(err);
			});
		});
	},

	snoozeFood(grocery: GroceryDto):Promise<ApiResult<GroceryDto>>{
		return new Promise<ApiResult<GroceryDto>>((resolve, reject) => {
		axios
			.post("/Plan/SnoozeFood",grocery)
			.then(resp => {
				const vm = resp.data as ApiResult<GroceryDto>;
				if(vm.successInd){
					resolve(vm);
				}
				
				reject(vm.errorMessage);
			})
			.catch(err => {
				reject(err);
			});
		});
	},

	snoozeRecipe(recipe: RecipeDto):Promise<ApiResult<RecipeDto>>{
		return new Promise<ApiResult<RecipeDto>>((resolve, reject) => {
		axios
			.post("/Plan/SnoozeRecipe",recipe)
			.then(resp => {
				const vm = resp.data as ApiResult<RecipeDto>;
				if(vm.successInd){
					resolve(vm);
				}
				
				reject(vm.errorMessage);
			})
			.catch(err => {
				reject(err);
			});
		});
	},

	planDetails(plan: MealPlanDto):Promise<ApiResult<MealPlanDto>>{
		return new Promise<ApiResult<MealPlanDto>>((resolve, reject) => {
		axios
			.post("/Plan/PlanDetails",plan)
			.then(resp => {
				const vm = resp.data as ApiResult<MealPlanDto>;
				if(vm.successInd){
					resolve(vm);
				}
				
				reject(vm.errorMessage);
			})
			.catch(err => {
				reject(err);
			});
		});
	},

	suggestedRecipes(plan: MealPlanDto):Promise<ApiResult<RecipeDto[]>>{
		return new Promise<ApiResult<RecipeDto[]>>((resolve, reject) => {
		axios
			.post("/Plan/SuggestedRecipes",plan)
			.then(resp => {
				const vm = resp.data as ApiResult<RecipeDto[]>;
				if(vm.successInd){
					resolve(vm);
				}
				
				reject(vm.errorMessage);
			})
			.catch(err => {
				reject(err);
			});
		});
	},


}


/*
Class:PlanController
Get Type args:MealPlanDto
Get Type args:MealPlanDto[]MealPlanDto=False
Get Type args:MealPlanDto[]MealPlanDto=False
Get Type args:MealPlanDto
Get Type args:MealPlanDto
Get Type args:GroceryDto
Get Type args:RecipeDto
Get Type args:MealPlanDto
Get Type args:RecipeDto[]RecipeDto=False
MealPlanDto
MealPlanDto
MealPlanDto
GroceryDto
RecipeDto
MealPlanDto
MealPlanDto
MealPlanDto
MealPlanDto
MealPlanDto
MealPlanDto
MealPlanDto
GroceryDto
RecipeDto
MealPlanDto
RecipeDtoimport { MealPlanDto } from './MealPlanDto';
import { GroceryDto } from './GroceryDto';
import { RecipeDto } from './RecipeDto';

*/