import * as React from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { DropdownOption } from "../../models/DropdownOption";
import { Dropdown } from "semantic-ui-react";
interface ClickToSelectProps {
  options: DropdownOption[];
  itemToSelect?: DropdownOption;
  itemSelected(newSelection: DropdownOption): void;
}

interface ClickToSelectState {
  isOpen: boolean;
}

//TODO memory leak here for setState when it's laready been unmounted
class ClickToSelectDropdown extends React.Component<
  ClickToSelectProps,
  ClickToSelectState
> {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    } as ClickToSelectState;
  }

  showDropDown = () => {
    this.setState({ isOpen: true });
  };

  dropdownItemSelected = (e, data) => {
    this.setState({ isOpen: false });
    console.log(data.value);
    let item = this.props.options.find(o => o.displayValue === data.value);
    console.log(item);
    if (item) {
      this.props.itemSelected(item);
    }
  };

  render() {
    if (!this.state.isOpen) {
      return (
        <OutsideClickHandler
          onOutsideClick={() => {
            if (this.state.isOpen) {
              this.setState({ isOpen: false });
            }
          }}
        >
          <div onClick={this.showDropDown}>
            {this.props.itemToSelect
              ? this.props.itemToSelect.displayValue
              : "Unknown"}
          </div>
        </OutsideClickHandler>
      );
    }
    const { options } = this.props;

    return (
      <OutsideClickHandler
        onOutsideClick={() => {
          if (this.state.isOpen) {
            this.setState({ isOpen: false });
          }
        }}
      >
        {this.state.isOpen ? (
          <Dropdown
            className="auto-width"
            selection
            options={options
              .map(o => {
                return {
                  key: o.id,
                  text: o.displayValue,
                  value: o.displayValue
                };
              })
              .concat({
                key: "unknown",
                text: "Unknown",
                value: "unknown"
              })}
            value={
              this.props.itemToSelect
                ? this.props.itemToSelect.displayValue
                : "unknown"
            }
            onChange={this.dropdownItemSelected}
          ></Dropdown>
        ) : null}
      </OutsideClickHandler>
    );
  }
}

export default ClickToSelectDropdown;
