import React from "react";
import { Button, Form, Icon, Modal } from "semantic-ui-react";
import FlexContainer from "../../components/containers/FlexContainer";
import { Orientation } from "../../components/Enums";

type ListOptionsProps = {
  show: boolean;
  onClose: () => void;
  onArchive?: () => void;
  onDelete: () => void;
  onSaveAsTemplate: () => void;
  onPrint: () => void;
  busy: boolean;
};

export function ListOptionsModal(props: ListOptionsProps) {
  const {
    show,
    onClose,
    busy,
    onArchive,
    onDelete,
    onSaveAsTemplate,
    onPrint,
  } = props;
  return (
    <Modal size={"small"} open={show} onClose={onClose} closeIcon>
      <Modal.Header>List Options</Modal.Header>
      <Modal.Content>
        <Form loading={busy}>
          <FlexContainer orientation={Orientation.Horizontal}>
            <Button size="small" positive onClick={() => onSaveAsTemplate()}>
              Save As Template
            </Button>
            <Button
              icon="print"
              content="Print"
              onClick={() => onPrint()}
              color="blue"
              size="small"
            />
            <Button size="small" color="orange" onClick={() => onArchive()}>
              Archive
            </Button>
            <Button
              size="small"
              floated="right"
              negative
              onClick={() => onDelete()}
            >
              Delete
            </Button>
          </FlexContainer>
        </Form>
      </Modal.Content>
      <Modal.Actions
        className={"horizontal-flex-container justify-space-between"}
      >
        <Button negative onClick={() => onClose()}>
          Cancel
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
