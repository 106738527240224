import { useMutation, useQuery } from "react-query";
import { queryClient } from "../..";
import { Plan } from "../../constants/magicStrings";
import { MealPlanDto } from "../../models/MealPlanDto";
import { PlanController } from "../../models/PlanController";

export const usePlanDetails = (planId: number) => {
  const query = useQuery([Plan.PLAN_DETAILS, planId], async () => {
    return await PlanController.planDetails({ mealPlanId: planId });
  });

  const suggestedRecipes = useQuery(
    [Plan.SUGGESTED_RECIPES, planId],
    async () => {
      return await PlanController.suggestedRecipes({ mealPlanId: planId });
    }
  );

  const {
    mutate: updateMealPlan,
    data: mutationData,
    status: mutationStatus,
    isLoading: mutationIsLoading,
  } = useMutation(
    (updatedPlan: MealPlanDto) => {
      return PlanController.updatePlan(updatedPlan);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([Plan.PLAN_DETAILS, planId]);
        queryClient.invalidateQueries([Plan.SUGGESTED_RECIPES, planId]);
      },
    }
  );

  const invalidateQuery = () => {
    queryClient.invalidateQueries([Plan.PLAN_DETAILS, planId]);
  };

  return {
    planDetails: query.data?.data,
    updateMealPlan,
    invalidateQuery,
    suggestedRecipes: suggestedRecipes?.data?.data || [],
  };
};

export const useMealPlans = () => {
  const query = useQuery(Plan.MEAL_PLANS, async () => {
    return await PlanController.getAllPlans();
  });

  const {
    mutate: createPlan,
    data: mutationData,
    status: createStatus,
    isLoading: createIsLoading,
  } = useMutation(
    (newPlan: MealPlanDto) => {
      return PlanController.createDraftPlan(newPlan);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(Plan.MEAL_PLANS);
      },
    }
  );

  return {
    mealPlans: query.data?.data ? sortByDateDesc(query.data?.data) : [],
    createPlan,
  };
};

function sortByDateDesc(plans: Array<MealPlanDto>) {
  return plans.sort((a, b) => (a.startDate > b.startDate ? -1 : 1));
}
