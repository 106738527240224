import React, { useState } from "react";
import { Image, Modal } from "semantic-ui-react";

export type ClickableImageProps = {
  src: string;
  alt: string;
};
export const ClickableImage = (
  props: React.ImgHTMLAttributes<ClickableImageProps>
) => {
  const { src, alt, ...rest } = props;
  const [fullScreen, setFullScreen] = useState(false);
  return (
    <>
      <Image
        fluid
        src={src}
        alt={alt}
        onClick={() => setFullScreen(true)}
        {...rest}
      ></Image>
      <Modal
        onClose={() => setFullScreen(false)}
        size={"fullscreen"}
        open={fullScreen}
        closeIcon
      >
        <Image fluid src={src} alt={alt}></Image>
      </Modal>
    </>
  );
};
