import React from "react";
import ConfirmModal from "../../components/ConfirmModal";
import { RecipeDto } from "../../models/RecipeDto";
export const TestPage = () => {
  const testRecipe: RecipeDto = {
    ingredients: [{ ingredientName: "cumin" }],
    recipeDescription: "Some description",
    recipeId: 200,
    recipeName: "Cooked Chicken",
    steps: [
      { instructions: "Defrost the chicken", sequence: 1 },
      { instructions: "Cook the chicken", sequence: 2 },
      { instructions: "eat the chicken", sequence: 3 },
    ],
  };
  return (
    <div>
      <h1>test page</h1>
      <ConfirmModal
        prompt={"hey"}
        show={false}
        title="Confirm"
        onHide={() => {
          alert("hide");
        }}
        onClose={(val) => alert(val)}
      ></ConfirmModal>
    </div>
  );
};
