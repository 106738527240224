export enum Orientation {
  Horizontal = "horizontal-flex-container",
  Vertical = "vertical-flex-container",
}

export enum PushDirection {
  Left = "flex-push-right",
  Right = "flex-push-left",
}

export enum Alignment {
  Baseline = "align-baseline",
  Center = "align-center",
  FlexEnd = "align-end",
}

export enum Gap {
  None = "gap-none",
  Small = "gap-small",
  Medium = "gap-medium",
  Large = "gap-large",
}

export enum JustifyContent {
  JustifyBaseline = "justify-baseline",
  JustifyCenter = "justify-center",
  JustifyFlexEnd = "justify-end",
  SpaceBetween = "justify-space-between",
}

export enum Margin {
  None = "no-margin",
  Small = "small-margin",
  Medium = "medium-margin",
}

export enum MarginType {
  Left,
  Right,
  Top,
  Botom,
  Sides,
  TopAndBottom,
  All,
}

export enum SortDirection {
  Ascending = "ascending",
  Descending = "descending",
}
