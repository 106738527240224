import React, { useState } from "react";
import { GroceryDto } from "../../models/GroceryDto";
import { Modal, Button, Dropdown, Form, Checkbox } from "semantic-ui-react";
import { format } from "date-fns";
import FlexContainer from "../../components/containers/FlexContainer";
import {
  JustifyContent,
  Orientation,
  Alignment,
  PushDirection,
} from "../../components/Enums";
import FlexChild from "../../components/containers/FlexChild";
import { GrocerySearch } from "../home/GrocerySearch";
import nanoid from "nanoid";
import { PlannedFoodDto } from "../../models/PlannedFoodDto";
import { UnitOfMeasure } from "../../models/UnitOfMeasure";
import { unitsOfMeasure } from "../../constants/units";
import { dayformat } from "../../constants/dateFormatters";

interface FoodAssignmentModalProps {
  availableFoods: GroceryDto[];
  availableDates: Date[];
  alreadyPlannedFood?: PlannedFoodDto;
  show: boolean;
  onCancel(): void;
  onDelete(uniqueId: number): void;
  onAdd(plannedFood: PlannedFoodDto | undefined): void;
}

type DateDropdownOption = {
  text: string;
  value: number;
};

//TODO
// Add a "countTowardsPlanServings" prop wired up to a checkbox
// this prop will determine if this food should count towards meal prep # of servings
// Otherwise still allow the option to pick a quantity that will get added to the grocery list but not count towards meal prep

export const FoodAssignmentModal: React.FC<FoodAssignmentModalProps> = (
  props
) => {
  const {
    show,
    onCancel,
    onAdd,
    onDelete,
    availableDates,
    alreadyPlannedFood,
    availableFoods,
  } = props;
  let initialQty = alreadyPlannedFood?.shoppingQuantity || 0;
  const [planFood, setPlanFood] = useState(alreadyPlannedFood);
  const [freetextedGrocery, setFreetextGrocery] = useState(
    alreadyPlannedFood?.groceryName || ""
  );

  const [quantity, setQuantity] = useState(initialQty);
  const unassignedValue = {
    text: "Unassigned",
    value: availableDates ? availableDates.length : 0,
  };

  const dateOptions: DateDropdownOption[] = availableDates
    .map((d, index) => {
      return {
        text: format(d, "EEE M/d"),
        value: index,
      };
    })
    .concat(unassignedValue);
  let preselectedDateValue;
  if (alreadyPlannedFood) {
    preselectedDateValue =
      alreadyPlannedFood && alreadyPlannedFood.plannedDay !== undefined
        ? dateOptions.find(
            (d) => d.text === dayformat(alreadyPlannedFood.plannedDay)
          )
        : unassignedValue;
  } else {
    preselectedDateValue = unassignedValue;
  }
  const [plannedDayValue, setPlannedDayValue] =
    useState<DateDropdownOption>(preselectedDateValue);
  const showDelete = planFood && planFood.plannedFoodId;
  const [addServingsChecked, setServingsChecked] = useState(
    alreadyPlannedFood?.countServings || false
  );

  const [shoppingUnit, setShoppingUnit] = useState<UnitOfMeasure | string>(
    planFood?.shoppingUnit
  );
  return (
    <Modal open={show}>
      <Modal.Header closeButton>
        <FlexContainer orientation={Orientation.Horizontal}>
          <span>Add Food</span>
          {showDelete && (
            <FlexChild pushDirection={PushDirection.Right}>
              <Button
                onClick={() => {
                  const uniqueId = planFood ? planFood.plannedFoodId : null;
                  onDelete(uniqueId);
                }}
                negative
              >
                Delete
              </Button>
            </FlexChild>
          )}
        </FlexContainer>
      </Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Field>
            <label>Search a grocery:</label>
            <GrocerySearch
              availableGroceries={availableFoods}
              freetextValueChanged={(freetext) => setFreetextGrocery(freetext)}
              selectedGrocery={
                planFood
                  ? {
                      name: planFood.groceryName,
                      groceryId: planFood.groceryId,
                    }
                  : null
              }
              groceryChanged={(food) => {
                setPlanFood({
                  ...planFood,
                  groceryId: food.groceryId,
                  groceryName: food.name,
                  plannedDay: planFood?.plannedDay,
                });
              }}
            ></GrocerySearch>
          </Form.Field>
          <Form.Field>
            <label>Pick a day:</label>
            <Dropdown
              placeholder="Day"
              fluid
              selection
              value={plannedDayValue.value}
              onChange={(e, data) => {
                const dispValue = dateOptions.find(
                  (d) => d.value === data.value
                );
                setPlannedDayValue(dispValue);
              }}
              options={dateOptions}
            ></Dropdown>
          </Form.Field>
          <Form.Group widths="equal">
            <Form.Field>
              <label>Quantity:</label>
              <Form.Input
                name="quantity"
                type="number"
                value={quantity}
                onChange={(e, data) => {
                  setQuantity(parseInt(data.value));
                }}
              ></Form.Input>
            </Form.Field>
            <Form.Field>
              <label>Unit:</label>
              <Form.Dropdown
                fluid
                placeholder="Unit"
                selection
                value={shoppingUnit}
                onChange={(e, data) => {
                  const dispValue = unitsOfMeasure.find(
                    (d) => d === data.value
                  );
                  setShoppingUnit(dispValue);
                }}
                options={unitsOfMeasure.map((u) => {
                  return { value: u, text: u };
                })}
              ></Form.Dropdown>
            </Form.Field>
          </Form.Group>
          <Form.Field>
            <Checkbox
              label="Count servings"
              checked={addServingsChecked}
              onChange={() => setServingsChecked(!addServingsChecked)}
            />
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button color={"orange"} onClick={() => onCancel()}>
          Cancel
        </Button>
        <Button
          disabled={
            !planFood?.groceryId && !freetextedGrocery && !planFood.groceryName
          }
          onClick={() => {
            const dateValue = {
              plannedDay: availableDates.find(
                (d) => format(d, "EEE M/d") === plannedDayValue.text
              ),
            };

            const valueToAdd = {
              ...planFood,
              ...dateValue,
              shoppingQuantity: quantity,
              countServings: addServingsChecked,
              shoppingUnit: shoppingUnit,
              ...(!planFood.groceryId && { groceryName: freetextedGrocery }),
            };

            onAdd(valueToAdd);
          }}
          positive
          icon="checkmark"
          labelPosition="right"
          content="Save"
        />
      </Modal.Actions>
    </Modal>
  );
};
