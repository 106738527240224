import React from "react";
import { useHistory } from "react-router";
import {
  Header,
  Button,
  Segment,
  Table,
  Responsive,
  Progress,
} from "semantic-ui-react";
import { ClickableText } from "../../components/ClickableText";
import FlexChild from "../../components/containers/FlexChild";
import FlexContainer from "../../components/containers/FlexContainer";
import { Alignment, Orientation, PushDirection } from "../../components/Enums";
import { routes } from "../../constants/routes";
import { CreateListModal } from "./CreateListModal";
import { useActiveLists } from "./useList";
export function ToDoDashboard() {
  const { status, allLists, error, summaries, createList } = useActiveLists();

  const [showCreateListModal, setShowCreateListModal] = React.useState(false);
  const history = useHistory();
  return (
    <div>
      <CreateListModal
        show={showCreateListModal}
        busy={false}
        onClose={() => setShowCreateListModal(false)}
        onSubmit={async (newList) => {
          await createList(newList);
          setShowCreateListModal(false);
        }}
      />
      <Header attached="top" as="h4" block>
        <FlexContainer
          alignment={Alignment.Center}
          orientation={Orientation.Horizontal}
        >
          Active Lists
          <FlexChild pushDirection={PushDirection.Right}>
            <Button onClick={() => setShowCreateListModal(true)} positive>
              Create New
            </Button>
          </FlexChild>
        </FlexContainer>
      </Header>
      <Segment
        loading={status === "loading"}
        attached="bottom"
        className="table-container"
      >
        <Table striped id="mainTable" unstackable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell textAlign={"center"}>Progress</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {summaries?.map((summary) => (
              <Responsive
                as={Table.Row}
                key={summary.toDoListId}
                className="check-element"
              >
                <Table.Cell>
                  <ClickableText
                    onClick={() =>
                      history.push(routes.todoList(summary.toDoListId))
                    }
                  >
                    {summary.name}
                  </ClickableText>
                </Table.Cell>
                <Table.Cell>
                  <Progress
                    className="table-progress"
                    indicating
                    value={summary.done}
                    total={summary.done + summary.notDone}
                    progress="ratio"
                  ></Progress>
                </Table.Cell>
              </Responsive>
            ))}
          </Table.Body>
        </Table>
      </Segment>
    </div>
  );
}
