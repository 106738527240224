import React from "react";
import { Modal, Button, Form, Input } from "semantic-ui-react";
import { ToDoListDto } from "../../models/ToDoListDto";

type CreateListModalProps = {
  show: boolean;
  onClose: () => void;
  onSubmit: (newList: ToDoListDto) => void;
  busy: boolean;
  title?: string;
};

export function CreateListModal(props: CreateListModalProps) {
  const { show, onClose, onSubmit, busy, title } = props;

  const [listName, setListName] = React.useState("");

  return (
    <Modal size={"small"} open={show} onClose={onClose} closeIcon>
      <Modal.Header>{title ? title : "Create New Active List"}</Modal.Header>
      <Modal.Content>
        <Form loading={busy}>
          <Form.Field>
            <label>List Name</label>
            <Input
              value={listName}
              onChange={(e) => setListName(e.currentTarget.value)}
            ></Input>
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions
        className={"horizontal-flex-container justify-space-between"}
      >
        <Button negative onClick={() => onClose()}>
          Cancel
        </Button>
        <Button
          positive
          disabled={!listName}
          onClick={() =>
            onSubmit({
              listName: listName,
            })
          }
        >
          Add
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
