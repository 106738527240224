import * as React from "react";
import ClickToSelectDropdown from "./ClickToSelectDropdown";
import { EditStringUpdate } from "../EditStringUpdate";
import { DropdownOption } from "../../models/DropdownOption";

interface UnitPickerProps {
  unitOptions: string[];
  unitToPick?: string;
  entityId?: number;
  unitUpdate(newVal: EditStringUpdate): void;
}
class UnitPicker extends React.Component<UnitPickerProps, any> {
  handleUnitChange = (item: DropdownOption) => {
    let newUnit = this.props.unitOptions.find(f => f === item.displayValue);
    if (newUnit) {
      let updt: EditStringUpdate = {
        newVal: newUnit,
        entityId: this.props.entityId || 0
      };
      this.props.unitUpdate(updt);
    }
  };
  render() {
    // return <div>{this.props.grocery.unit}</div>;
    let options = this.props.unitOptions.map(u => {
      let opt: DropdownOption = {
        displayValue: u,
        id: u
      };
      return opt;
    });

    let unitToSelect = options.find(
      u => u.displayValue === this.props.unitToPick
    );
    if (!unitToSelect) {
      unitToSelect = options.find(u => u.displayValue === "Each");
    }
    return (
      <ClickToSelectDropdown
        options={options}
        itemSelected={this.handleUnitChange}
        itemToSelect={unitToSelect}
      />
    );
  }
}

export default UnitPicker;
