import React, { useState } from "react";
import { SortState, useHomePageRecipes } from "../home/recipeList.hook";
import { Grid } from "semantic-ui-react";
import { QuickRecipeTable, QuickRecipeRow } from "./QuickRecipeTable";
import { routes } from "../../constants/routes";
import { RouteProps, useHistory } from "react-router-dom";
import AddRecipeModal from "./AddRecipeModal";
import { useFmgToast } from "../common/toast.hook";
import { SortDirection } from "../../components/Enums";
import { RecipeDto } from "../../models/RecipeDto";
import { useRecipeFilter } from "./useRecipeFilter";

export const RecipeDashboard: React.FC<RouteProps> = (props) => {
  const { recipeFilter, setFilter: setRecipeFilter } = useRecipeFilter();
  const [recipeSort, setRecipeSort] = useState<SortState>({
    columnName: "recipeName",
    direction: SortDirection.Ascending,
  });

  const { modifyRecipeDetails, visibleRecipes, addRecipe } = useHomePageRecipes(
    recipeSort,
    recipeFilter
  );
  const sortRecipesChanged = (column: keyof RecipeDto) => {
    if (recipeSort.columnName === column) {
      const oppositeDirection =
        recipeSort.direction === SortDirection.Ascending
          ? SortDirection.Descending
          : SortDirection.Ascending;
      setRecipeSort({
        columnName: column,
        direction: oppositeDirection,
      });
    } else {
      setRecipeSort({
        columnName: column,
        direction: SortDirection.Ascending,
      });
    }
  };

  const history = useHistory();
  const { addSuccess } = useFmgToast();
  const [showNewModal, setShowNew] = useState(false);
  const [newLoading, setNewLoading] = useState(false);
  return (
    <Grid.Column mobile={16} computer={8}>
      {showNewModal && (
        <AddRecipeModal
          show={true}
          onHide={() => setShowNew(false)}
          loading={newLoading}
          addRecipe={async (recipeForm) => {
            await addRecipe(recipeForm);
            addSuccess("Recipe added successfully");
            setShowNew(false);
          }}
        ></AddRecipeModal>
      )}
      <QuickRecipeTable
        currentSort={recipeSort}
        onSort={sortRecipesChanged}
        filterValue={recipeFilter}
        onFilterChange={(newVal) => setRecipeFilter(newVal)}
        isBusy={false}
        onNewClick={() => setShowNew(true)}
      >
        {visibleRecipes.map((recipe) => {
          return (
            <QuickRecipeRow
              key={recipe.recipeId}
              recipe={recipe}
              recipeClicked={() =>
                history.push(routes.recipeDetails(recipe.recipeId))
              }
              ratingChanged={async (newRating) => {
                await modifyRecipeDetails({ ...recipe, rating: newRating });
                addSuccess("Saved Successfully");
              }}
            ></QuickRecipeRow>
          );
        })}
      </QuickRecipeTable>
    </Grid.Column>
  );
};
