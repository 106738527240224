import React, { useState } from "react";
import { Segment, Grid, Message, Button } from "semantic-ui-react";
import { GroceryTable } from "./grocery-table/GroceryTable";
import { GroceryTableRow } from "./grocery-table/GroceryTableRow";
import { StoreDropdown } from "./StoreDropdown";
import { useToasts } from "react-toast-notifications";
import {
  useGrocerySelection,
  useVisibleShoppingList,
} from "./shoppingListHook";
import { GrocerySearch } from "./GrocerySearch";
import { useGroceryUpdates } from "./useShoppingListData";
import { useFmgToast } from "../common/toast.hook";
export const HomePage = () => {
  const {
    groceryFreetext,
    setGroceryFreetext,
    selectedGrocery,
    setSelectedGrocery,
    availableGroceries,
  } = useGrocerySelection();

  const { addSuccess, addError } = useFmgToast();
  const [showDone, setShowDone] = useState(false);
  const {
    visibleList,
    allItems,
    availableStores,
    selectedStore,
    setSelectedStore,
    busy,
  } = useVisibleShoppingList(showDone);

  const { addGroceryToList, removeDone, updateLocation, listUpdate } =
    useGroceryUpdates();

  const { addToast } = useToasts();

  return (
    <Grid.Column mobile={16} computer={10}>
      <div className={"store-container"}>
        <Message attached="top">Pick a store</Message>
        <Segment attached={"bottom"}>
          {availableStores?.length > 0 && selectedStore && (
            <StoreDropdown
              selectedStore={selectedStore}
              onChange={(storeId) =>
                setSelectedStore(
                  availableStores.find((s) => s.storeId === storeId)
                )
              }
              stores={availableStores}
            ></StoreDropdown>
          )}
        </Segment>
      </div>
      <GroceryTable
        isBusy={busy}
        checkedCount={allItems.filter((a) => a.isChecked)?.length}
        totalCount={allItems.length}
        showHideButtons={
          <>
            <Button
              color="blue"
              size={"mini"}
              onClick={() => setShowDone(!showDone)}
            >
              {showDone ? "Hide Done" : "Show Done"}
            </Button>
            <Button
              color="yellow"
              size={"mini"}
              onClick={async () => {
                await removeDone();
                addSuccess("Removed done items");
              }}
            >
              Remove Done
            </Button>
          </>
        }
        grocerySearchComponent={
          <div className="grocery-search-container">
            <GrocerySearch
              availableGroceries={availableGroceries}
              freetextValueChanged={(freetext) => setGroceryFreetext(freetext)}
              selectedGrocery={selectedGrocery}
              controlledValue={groceryFreetext}
              groceryChanged={(newGroc) => {
                setSelectedGrocery(newGroc);
              }}
            ></GrocerySearch>
            <Button
              positive
              size={"large"}
              disabled={!groceryFreetext && !selectedGrocery}
              onClick={async () => {
                try {
                  await addGroceryToList(selectedGrocery || groceryFreetext);
                  addSuccess(
                    `Added ${
                      selectedGrocery ? selectedGrocery.name : groceryFreetext
                    }`
                  );
                  setGroceryFreetext("");
                } catch (err) {
                  addError("Failed to add");
                }
              }}
            >
              Add
            </Button>
          </div>
        }
      >
        {visibleList.map((g) => {
          return (
            <GroceryTableRow
              storeLocations={selectedStore?.storeLocations}
              key={g.activeGroceryId}
              grocery={g}
              locationUpdate={async (newLocation) => {
                await updateLocation(newLocation);

                addToast(
                  `Saved Location ${newLocation?.locationName || ""} to ${
                    g.groceryName || ""
                  }`,
                  { appearance: "success" }
                );
              }}
              rowUpdate={async (update) => {
                await listUpdate(update);

                addToast(`Saved ${g.groceryName} Successfully`, {
                  appearance: "success",
                });
              }}
            ></GroceryTableRow>
          );
        })}
      </GroceryTable>
    </Grid.Column>
  );
};
