import { useState } from "react";

type SavedPrefs = {
  [key: number]: boolean;
};

const storageKey = `todos-show-done`;

export const useShowDone = (listId: number) => {
  const [showDone, setShowDone] = useState(() => {
    const preferences = getPrefs();
    if (preferences) {
      return preferences[listId];
    } else {
      return false;
    }
  });

  const toggleShowDone = () => {
    const newVal = !showDone;
    const existingPrefs = getPrefs();
    const newPrefs = {
      ...existingPrefs,
      [listId]: newVal,
    };
    localStorage.setItem(storageKey, JSON.stringify(newPrefs));
    setShowDone(newVal);
  };

  return { showDone, toggleShowDone };
};

function getPrefs() {
  const preferences = localStorage.getItem(storageKey);
  if (preferences) {
    const parsed = JSON.parse(preferences) as SavedPrefs;
    return parsed;
  }
}
