import React from "react";
import { Table, Responsive, Checkbox } from "semantic-ui-react";
import BigCheckbox from "../../components/BigCheckbox";
import FlexContainer from "../../components/containers/FlexContainer";
import { Alignment, Gap, Orientation } from "../../components/Enums";
import { superShortDate } from "../../constants/dateFormatters";
import { ActiveToDoDto } from "../../models/ActiveToDoDto";
import { ToDoListDto } from "../../models/ToDoListDto";
import { convertToDate } from "../../util";

type PrintableListProps = {
  list: ToDoListDto;
};
export function PrintableList(props: PrintableListProps) {
  const { list } = props;
  return (
    <div className="section-to-print">
      <h2>
        {list?.listName} - {list?.toDos?.length || 0}
      </h2>
      <FlexContainer
        style={{ maxHeight: "95%", flexWrap: "wrap" }}
        orientation={Orientation.Vertical}
        gap={Gap.Medium}
      >
        {list?.toDos
          ?.sort((a, b) => (a.order > b.order ? 1 : -1))
          .map((todo, i) => {
            const indx = i + 1;
            return (
              <FlexContainer
                style={{ maxWidth: "30%" }}
                orientation={Orientation.Horizontal}
                gap={Gap.Small}
                alignment={Alignment.Center}
                key={todo.activeToDoId}
              >
                <Checkbox type={"checkbox"} checked={todo.isChecked}></Checkbox>
                <span>{todo.toDo.text}</span>
                <span className="italic">
                  {indx % 10 === 0 || indx === 1 ? `#${indx}` : null}
                </span>
              </FlexContainer>
            );
          })}
      </FlexContainer>
    </div>
  );
}
