import * as React from "react";
import ClickToSelectDropdown from "./ClickToSelectDropdown";
import { GroceryDto } from "../../models/GroceryDto";
import { DropdownOption } from "../../models/DropdownOption";
import { StoreLocationDto } from "../../models/StoreLocationDto";

interface PickerProps {
  locationOptions?: StoreLocationDto[];
  locationToPick: StoreLocationDto;
  grocery: GroceryDto;
  locationUpdated(newLocation: StoreLocationDto, grocery: GroceryDto): void;
}

class LocationPicker extends React.Component<PickerProps, any> {
  handleLocChange = (item: DropdownOption) => {
    if (item.id) {
      let locationId = parseInt(item.id);
      let newLoc = this.props.locationOptions.find(
        f => f.storeLocationId === locationId
      );
      if (newLoc) {
        this.props.locationUpdated(newLoc, this.props.grocery);
      }
    }
  };

  render() {
    if (!this.props.locationOptions) {
      return (
        <span>
          {this.props.grocery.locationName ||
            this.props.locationToPick.locationName}
        </span>
      );
    }

    let options = this.props.locationOptions?.map(o => {
      let option: DropdownOption = {
        id: o.storeLocationId ? o.storeLocationId.toString() : undefined,
        displayValue: o.locationName
      };
      return option;
    });

    let itemToSelect;
    if (this.props.locationToPick) {
      itemToSelect = options?.find(
        o => o.displayValue === this.props.locationToPick.locationName
      );
    }

    return (
      <ClickToSelectDropdown
        itemSelected={this.handleLocChange}
        itemToSelect={itemToSelect}
        options={options}
      />
    );
  }
}

export default LocationPicker;
