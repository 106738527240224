import { RecipeStepDTO } from "./models/RecipeStepDTO";
import { ActiveGroceryDto } from "./models/ActiveGroceryDto";
import { StoreDto } from "./models/StoreDto";
import { RecipeDto } from "./models/RecipeDto";
import { SortDirection } from "./components/Enums";

export function sortSteps(steps: RecipeStepDTO[]) {
  return steps.sort((a, b) => a.sequence - b.sequence);
}

type LoadindUpdate = {
  groceryId: number;
  isLoading: boolean;
};

export function updateLoadingState(
  groceries: Array<ActiveGroceryDto>,
  updateType: LoadindUpdate
): Array<ActiveGroceryDto> {
  return groceries.map((grocery) => {
    if (grocery.groceryId === updateType.groceryId) {
      return {
        ...grocery,
        isSaving: updateType.isLoading,
      };
    }
    return grocery;
  });
}

export function sortGroceryList(
  storeId: number,
  groceries: ActiveGroceryDto[],
  stores: StoreDto[]
): ActiveGroceryDto[] {
  const activeGroceries = groceries
    .map((grocery) => {
      //Get locationId for this store-grocery combo
      let thisStoreLocation = grocery.groceryStoreLocations.find(
        (gsl) => gsl.storeId === storeId
      );

      if (thisStoreLocation) {
        //Use location to get sequence
        let location = stores
          .find((s) => s.storeId === storeId)
          .storeLocations.find(
            (l) => l.storeLocationId === thisStoreLocation.storeLocationId
          );
        if (location) {
          grocery.sequence = location.sequence;
          grocery.locationName = location.locationName;
        } else {
          grocery.sequence = 0;
          grocery.locationName = "Unknown";
        }
      } else {
        grocery.sequence = 0;
        grocery.locationName = "Unknown";
      }
      return grocery;
    })
    .sort(function (a, b) {
      return a.groceryName > b.groceryName ? 1 : -1;
    }) //then sort it by sequence
    .sort(function (a, b) {
      return a.sequence - b.sequence;
    });
  return activeGroceries;
}

export function sortRecipesByName(
  recipes: RecipeDto[],
  direction: SortDirection
): RecipeDto[] {
  return recipes.sort((rec1, rec2) => {
    let a = rec1.recipeName.toUpperCase();
    let b = rec2.recipeName.toUpperCase();
    return sortEm(a, b, direction);
  });
}

function sortEm(a, b, direction: SortDirection) {
  if (a === b) {
    return 0;
  }

  if (!a) {
    return 1;
  }

  if (!b) {
    return -1;
  }

  if (direction === SortDirection.Ascending) {
    return a < b ? -1 : a > b ? 1 : 0;
  }
  return a > b ? -1 : a > b ? 1 : 0;
}

export function sortRecipes(
  recipes: Array<RecipeDto>,
  property: keyof RecipeDto,
  direction: SortDirection
): Array<RecipeDto> {
  if (property === "rating") {
    return recipes.sort((rec1, rec2) => {
      let a = rec1.rating;
      let b = rec2.rating;
      return sortEm(a, b, direction);
    });
  }

  if (property === "lastCooked") {
    return recipes.sort((rec1, rec2) => {
      let a = rec1.lastCooked;
      let b = rec2.lastCooked;
      return sortEm(a, b, direction);
    });
  }

  return sortRecipesByName(recipes, direction);
}

export function filterRecipes(
  recipes: RecipeDto[],
  searchString: string
): RecipeDto[] {
  if (!searchString) return recipes;

  let visibleRecipes = recipes.filter(
    (r) =>
      r.recipeName.toLowerCase().indexOf(searchString.toLowerCase()) !== -1 ||
      r.ingredients.filter(
        (i) =>
          i.ingredientName.toLowerCase().indexOf(searchString.toLowerCase()) !==
          -1
      ).length
  );

  return visibleRecipes;
}
