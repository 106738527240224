import axios from "axios";
import { routes } from "../constants/routes";
import { ApiResult } from "../models/ApiResult";
import { RecipeDto } from "../models/RecipeDto";

export const getRecipePhoto = async (recipeId: number): Promise<any> => {
  const result = await axios.get(routes.recipePhoto(recipeId), {
    responseType: "blob",
  });

  const image = URL.createObjectURL(result.data);

  return image;
};

export const saveRecipePhoto = async (
  formData: FormData
): Promise<RecipeDto> => {
  const result: ApiResult<RecipeDto> = await axios.post(
    routes.saveRecipePhoto,
    formData
  );
  if (result.data) {
    return result.data;
  }
  throw new Error(result.errorMessage);
};
