


    // $Classes/Enums/Interfaces(filter)[template][separator]
    // filter (optional): Matches the name or full name of the current item. * = match any, wrap in [] to match attributes or prefix with : to match interfaces or base classes.
    // template: The template to repeat for each matched item
    // separator (optional): A separator template that is placed between all templates e.g. $Properties[public $name: $Type][, ]

    // More info: http://frhagn.github.io/Typewriter/
import axios from "axios";
import {ApiResult} from  "./ApiResult";


import { GroceryStoreLocationDto } from './GroceryStoreLocationDto';
import { StoreLocationDto } from './StoreLocationDto';
import { NewStoreForm } from './NewStoreForm';
import { StoreDto } from './StoreDto';


export interface IStoresController {

 
        
         updateGroceryLocation(update: GroceryStoreLocationDto):Promise<ApiResult<GroceryStoreLocationDto>>;
        
        
         allStores():Promise<ApiResult<StoreDto[]>>;
        
        
         updateStoreLocations(storeLocations: StoreLocationDto[]):Promise<ApiResult<StoreDto>>;
        
        
         addStore(newStore: NewStoreForm):Promise<ApiResult<StoreDto>>;
           
	
}
export const StoresController :IStoresController =
{

 
	updateGroceryLocation(update: GroceryStoreLocationDto):Promise<ApiResult<GroceryStoreLocationDto>>{
		return new Promise<ApiResult<GroceryStoreLocationDto>>((resolve, reject) => {
		axios
			.post("/Stores/UpdateGroceryLocation",update)
			.then(resp => {
				const vm = resp.data as ApiResult<GroceryStoreLocationDto>;
				if(vm.successInd){
					resolve(vm);
				}
				
				reject(vm.errorMessage);
			})
			.catch(err => {
				reject(err);
			});
		});
	},

	allStores():Promise<ApiResult<StoreDto[]>>{
		return new Promise<ApiResult<StoreDto[]>>((resolve, reject) => {
		axios
			.post("/Stores/AllStores",)
			.then(resp => {
				const vm = resp.data as ApiResult<StoreDto[]>;
				if(vm.successInd){
					resolve(vm);
				}
				
				reject(vm.errorMessage);
			})
			.catch(err => {
				reject(err);
			});
		});
	},

	updateStoreLocations(storeLocations: StoreLocationDto[]):Promise<ApiResult<StoreDto>>{
		return new Promise<ApiResult<StoreDto>>((resolve, reject) => {
		axios
			.post("/Stores/UpdateStoreLocations",storeLocations)
			.then(resp => {
				const vm = resp.data as ApiResult<StoreDto>;
				if(vm.successInd){
					resolve(vm);
				}
				
				reject(vm.errorMessage);
			})
			.catch(err => {
				reject(err);
			});
		});
	},

	addStore(newStore: NewStoreForm):Promise<ApiResult<StoreDto>>{
		return new Promise<ApiResult<StoreDto>>((resolve, reject) => {
		axios
			.post("/Stores/AddStore",newStore)
			.then(resp => {
				const vm = resp.data as ApiResult<StoreDto>;
				if(vm.successInd){
					resolve(vm);
				}
				
				reject(vm.errorMessage);
			})
			.catch(err => {
				reject(err);
			});
		});
	},


}


/*
Class:ToDoController
Get Type args:ToDoListDto[]ToDoListDto=False
Returning null for method:AddToDo
Get Type args:ToDoDto[]ToDoDto=False
ActiveToDoDto
ToDoDto
ToDoListDto
GenerateImportsForType null
ToDoDtoimport { ActiveToDoDto } from './ActiveToDoDto';
import { ToDoDto } from './ToDoDto';
import { ToDoListDto } from './ToDoListDto';
Class:ToDoController
Get Type args:ToDoListDto[]ToDoListDto=False
Returning null for method:AddToDo
Get Type args:ToDoDto[]ToDoDto=False
ActiveToDoDto
ToDoDto
ToDoListDto
GenerateImportsForType null
ToDoDtoimport { ActiveToDoDto } from './ActiveToDoDto';
import { ToDoDto } from './ToDoDto';
import { ToDoListDto } from './ToDoListDto';
Class:StoresController
Get Type args:GroceryStoreLocationDto
Get Type args:StoreDto[]StoreDto=False
Get Type args:StoreDto
Get Type args:StoreDto
GroceryStoreLocationDto
StoreLocationDto[]
Sub typeStoreLocationDto[]
StoreLocationDto
NewStoreForm
GroceryStoreLocationDto
StoreDto
StoreDto
StoreDtoimport { GroceryStoreLocationDto } from './GroceryStoreLocationDto';
import { StoreLocationDto } from './StoreLocationDto';
import { NewStoreForm } from './NewStoreForm';
import { StoreDto } from './StoreDto';
Class:PlanController
Get Type args:MealPlanDto
Get Type args:MealPlanDto[]MealPlanDto=False
Get Type args:MealPlanDto[]MealPlanDto=False
Get Type args:MealPlanDto
Get Type args:MealPlanDto
MealPlanDto
MealPlanDto
MealPlanDto
MealPlanDto
MealPlanDto
MealPlanDto
MealPlanDto
MealPlanDtoimport { MealPlanDto } from './MealPlanDto';
Class:GroceriesController
Get Type args:ShoppingListViewModel
Get Type args:ActiveGroceryDto
Get Type args:GroceryDto[]GroceryDto=False
Get Type args:ActiveGroceryDto
Get Type args:ActiveGroceryDto[]ActiveGroceryDto=False
Get Type args:GroceryDto
ListUpdate
GroceryDto
GroceryDto
ShoppingListViewModel
ActiveGroceryDto
GroceryDto
ActiveGroceryDto
ActiveGroceryDto
GroceryDtoimport { ListUpdate } from './ListUpdate';
import { GroceryDto } from './GroceryDto';
import { ShoppingListViewModel } from './ShoppingListViewModel';
import { ActiveGroceryDto } from './ActiveGroceryDto';
Class:RecipesController
Get Type args:RecipeDto[]RecipeDto=False
Get Type args:RecipeDto
Get Type args:IngredientDTO
Get Type args:IngredientDTO
Get Type args:RecipeDto
Get Type args:RecipeDto
RecipeDto
IngredientDTO
IngredientDTO
RecipeStepDTO[]
Sub typeRecipeStepDTO[]
RecipeStepDTO
RecipeDto
RecipeDto
RecipeDto
IngredientDTO
IngredientDTO
RecipeDto
RecipeDtoimport { RecipeDto } from './RecipeDto';
import { IngredientDTO } from './IngredientDTO';
import { RecipeStepDTO } from './RecipeStepDTO';
Class:UserController
Get Type args:User
Get Type args:User
Get Type args:User
LoginRequest
RegisterCommand
User
User
Userimport { LoginRequest } from './LoginRequest';
import { RegisterCommand } from './RegisterCommand';
import { User } from './User';
Class:ToDoController
Get Type args:ToDoListDto[]ToDoListDto=False
Returning null for method:AddToDo
Get Type args:ToDoDto[]ToDoDto=False
ActiveToDoDto
ToDoDto
ToDoListDto
GenerateImportsForType null
ToDoDtoimport { ActiveToDoDto } from './ActiveToDoDto';
import { ToDoDto } from './ToDoDto';
import { ToDoListDto } from './ToDoListDto';
Class:StoresController
Get Type args:GroceryStoreLocationDto
Get Type args:StoreDto[]StoreDto=False
Get Type args:StoreDto
Get Type args:StoreDto
GroceryStoreLocationDto
StoreLocationDto[]
Sub typeStoreLocationDto[]
StoreLocationDto
NewStoreForm
GroceryStoreLocationDto
StoreDto
StoreDto
StoreDtoimport { GroceryStoreLocationDto } from './GroceryStoreLocationDto';
import { StoreLocationDto } from './StoreLocationDto';
import { NewStoreForm } from './NewStoreForm';
import { StoreDto } from './StoreDto';

*/