import * as React from "react";
import { Field, useField } from "formik";
import { DropdownOption } from "../../models/DropdownOption";

type DropdownOptions = {
  options: Array<DropdownOption>;
  name: string;
};

export const FormikSelect = ({ options, name }: DropdownOptions) => {
  const [field] = useField(name);

  return (
    <select name={name} {...field}>
      {options.map((option) => {
        return (
          <option key={option.id} value={option.id}>
            {option.displayValue}
          </option>
        );
      })}
    </select>
  );
};
