import React, { useState, useEffect, useRef } from "react";
import { RecipeDto } from "../../models/RecipeDto";
import { PlannedRecipeDto } from "../../models/PlannedRecipeDto";
import {
  Modal,
  Button,
  Dropdown,
  Label,
  Form,
  Checkbox,
  Message,
  Icon,
} from "semantic-ui-react";
import { AddIngredientsTable } from "../recipes/AddIngredientsTable";
import { format } from "date-fns";
import { RecipeSearch } from "../recipes/RecipeSearch";
import FlexContainer from "../../components/containers/FlexContainer";
import { Alignment, Orientation, PushDirection } from "../../components/Enums";
import FlexChild from "../../components/containers/FlexChild";
import { IngredientDTO } from "../../models/IngredientDTO";
import { dayformat } from "../../constants/dateFormatters";
import {
  usePlannedRecipeAssignment,
  converteToAddedIngredientArray,
} from "./recipeAssignment.hook";

interface RecipeAssignmentModalProps {
  availableRecipes: RecipeDto[];
  availableDates: Date[];
  alreadyPlannedRecipe?: PlannedRecipeDto;
  show: boolean;
  onCancel(): void;
  onDelete(uniqueId: number): void;
  onSnooze(recipe: PlannedRecipeDto): void;
  onAdd(plannedFood: PlannedRecipeDto | undefined): void;
}

type DateDropdownOption = {
  text: string;
  value: number;
};

export const RecipeAssignmentModal: React.FC<RecipeAssignmentModalProps> = (
  props
) => {
  const {
    ingredients,
    plannedRecipe: planRecipe,
    selectedRecipeChanged,
    setIngredients,
    multiplier,
    countServingsChecked,
    setCountServingsChecked,
    setMultiplier,
    recipeDetails,
  } = usePlannedRecipeAssignment(props.alreadyPlannedRecipe);

  const {
    show,
    onCancel,
    onAdd,
    onDelete,
    availableDates,
    alreadyPlannedRecipe,
    availableRecipes,
    onSnooze,
  } = props;
  const unassignedValue = {
    text: "Unassigned",
    value: availableDates ? availableDates.length : 0,
  };

  const dateOptions: DateDropdownOption[] = props.availableDates
    .map((d, index) => {
      return {
        text: format(d, "EEE M/d"),
        value: index,
      };
    })
    .concat(unassignedValue);
  let preselectedDateValue;
  if (alreadyPlannedRecipe) {
    preselectedDateValue =
      alreadyPlannedRecipe && alreadyPlannedRecipe.plannedDay !== undefined
        ? dateOptions.find(
            (d) => d.text === dayformat(alreadyPlannedRecipe.plannedDay)
          )
        : unassignedValue;
  } else {
    preselectedDateValue = unassignedValue;
  }
  const [plannedDayValue, setPlannedDayValue] =
    useState<DateDropdownOption>(preselectedDateValue);
  const showDelete = planRecipe && planRecipe.plannedRecipeId;

  return (
    <Modal open={show}>
      <Modal.Header closeButton>
        <FlexContainer
          orientation={Orientation.Horizontal}
          alignment={Alignment.Center}
        >
          <span>Add Meal</span>
          <FlexChild pushDirection={PushDirection.Right}>
            <FlexContainer orientation={Orientation.Horizontal}>
              <Button
                content="Snooze"
                color="orange"
                icon={<Icon name="alarm"></Icon>}
                onClick={() => {
                  onSnooze(planRecipe);
                }}
              ></Button>
              {showDelete && (
                <Button
                  onClick={() => {
                    const uniqueId = planRecipe
                      ? planRecipe.plannedRecipeId
                      : null;
                    onDelete(uniqueId);
                  }}
                  negative
                >
                  Delete
                </Button>
              )}
            </FlexContainer>
          </FlexChild>
        </FlexContainer>
      </Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Field>
            <label>Pick a Recipe</label>
            <RecipeSearch
              recipeChanged={(recipe) => {
                selectedRecipeChanged(recipe);
              }}
              availableRecipes={availableRecipes}
              selectedRecipe={planRecipe}
            ></RecipeSearch>
          </Form.Field>
          {recipeDetails.current?.recipeDescription && (
            <Message>
              <label>Description: </label>
              {recipeDetails.current?.recipeDescription}
            </Message>
          )}
          <Form.Field>
            <label>Pick a day:</label>
            <Dropdown
              fluid
              selection
              placeholder="Pick a day"
              value={plannedDayValue.value}
              onChange={(e, data) => {
                const dispValue = dateOptions.find(
                  (d) => d.value === data.value
                );
                setPlannedDayValue(dispValue);
              }}
              options={dateOptions}
            ></Dropdown>
          </Form.Field>
          <Form.Field>
            <Form.Input
              name="Multiplier"
              type="number"
              value={multiplier}
              onChange={(e, data) => {
                setMultiplier(parseInt(data.value));
              }}
              label="Serving Multiplier"
            ></Form.Input>
          </Form.Field>
          <Form.Field>
            <Checkbox
              label="Count servings"
              checked={countServingsChecked}
              onChange={() => setCountServingsChecked(!countServingsChecked)}
            ></Checkbox>
          </Form.Field>
          {planRecipe && (
            <IngredientsField
              ingredients={ingredients}
              servings={multiplier}
              ingredientsChanged={(newIngredients) => {
                setIngredients([...newIngredients]);
              }}
            ></IngredientsField>
          )}
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button color={"orange"} onClick={() => onCancel()}>
          Cancel
        </Button>
        <Button
          onClick={() => {
            const dateValue = {
              plannedDay: availableDates.find(
                (d) => format(d, "EEE M/d") === plannedDayValue.text
              ),
            };

            const ingredsToAdd = converteToAddedIngredientArray(ingredients);

            onAdd({
              ...planRecipe,
              addedIngredients: ingredsToAdd,
              ...dateValue,
              multiplier: multiplier,
              countServings: countServingsChecked,
            });
          }}
          positive
          icon="checkmark"
          labelPosition="right"
          content="Save"
        />
      </Modal.Actions>
    </Modal>
  );
};

const IngredientsField: React.FC<{
  ingredients: IngredientDTO[];
  servings: number;
  ingredientsChanged(ingredients: IngredientDTO[]): void;
}> = ({ ingredients, servings, ingredientsChanged }) => {
  return (
    <Form.Field>
      <label>
        Ingredients{" "}
        <Label>
          {ingredients
            ? ingredients.filter((i) => i.addToShoppingList).length
            : 0}{" "}
        </Label>
      </label>
      <AddIngredientsTable
        ingredients={ingredients}
        ingredientsUpdated={ingredientsChanged}
      ></AddIngredientsTable>
    </Form.Field>
  );
};
