import * as React from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { GroceryDto } from "../../models/GroceryDto";
interface QuantityProps {
  grocery: GroceryDto;
  qtyUpdated(newQty: number, grocery: GroceryDto): void;
}

interface QuantityState {
  isOpen: boolean;
  qty: number;
}
class QuantityBox extends React.Component<QuantityProps, QuantityState> {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      qty: this.props.grocery.quantity,
    } as QuantityState;
  }

  _handleKeyPress = (e) => {
    if (e.key === "Enter") {
      this.props.qtyUpdated(this.state.qty, this.props.grocery);
      this.setState({ isOpen: false });
    }
  };

  handleQtyUpdate = (event) => {
    this.setState({ qty: Number(event.currentTarget.value) });
  };

  showEdit = () => {
    this.setState({ isOpen: true });
  };
  componentDidMount() {
    //todo auto select the text
  }
  render() {
    if (!this.state.isOpen) {
      return <div onClick={this.showEdit}>{this.props.grocery.quantity}</div>;
    }

    return (
      <OutsideClickHandler
        onOutsideClick={() => {
          if (this.state.isOpen) {
            this.setState({ isOpen: false });
          }
        }}
      >
        <input
          className="table-input no-spin"
          type="number"
          onKeyPress={this._handleKeyPress}
          value={this.state.qty}
          placeholder="Enter text"
          onChange={this.handleQtyUpdate}
        />
      </OutsideClickHandler>
    );
  }
}

export default QuantityBox;
