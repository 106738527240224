


    // $Classes/Enums/Interfaces(filter)[template][separator]
    // filter (optional): Matches the name or full name of the current item. * = match any, wrap in [] to match attributes or prefix with : to match interfaces or base classes.
    // template: The template to repeat for each matched item
    // separator (optional): A separator template that is placed between all templates e.g. $Properties[public $name: $Type][, ]

    // More info: http://frhagn.github.io/Typewriter/
import axios from "axios";
import {ApiResult} from  "./ApiResult";


import { ActiveToDoDto } from './ActiveToDoDto';
import { ToDoDto } from './ToDoDto';
import { ListDetailsQuery } from './ListDetailsQuery';
import { ToDoListDto } from './ToDoListDto';
import { ToDoTemplateDto } from './ToDoTemplateDto';
import { ToDoListTemplateDto } from './ToDoListTemplateDto';
import { ToDoListTemplateQuery } from './ToDoListTemplateQuery';


export interface IToDoController {

 
        
         activeLists():Promise<ApiResult<ToDoListDto[]>>;
        
        
         addToDo(newToDo: ActiveToDoDto):Promise<ApiResult<ActiveToDoDto>>;
        
        
         searchToDos(search: ToDoDto):Promise<ApiResult<ToDoDto[]>>;
        
        
         listDetails(listId: ListDetailsQuery):Promise<ApiResult<ToDoListDto>>;
        
        
         createNewList(dto: ToDoListDto):Promise<ApiResult<ToDoListDto>>;
        
        
         upsertToDo(newToDo: ActiveToDoDto):Promise<ApiResult<ActiveToDoDto>>;
        
        
         updateListStatus(listDto: ToDoListDto):Promise<ApiResult<ToDoListDto>>;
        
        
         createListTemplate(listDto: ToDoListDto):Promise<ApiResult<ToDoListTemplateDto>>;
        
        
         upsertToDoTemplate(dto: ToDoTemplateDto):Promise<ApiResult<ToDoTemplateDto>>;
        
        
         getActiveTemplates():Promise<ApiResult<ToDoListTemplateDto[]>>;
        
        
         addTemplateToList(template: ToDoListTemplateDto):Promise<ApiResult<ToDoListDto>>;
        
        
         deleteActiveToDo(dto: ActiveToDoDto):Promise<ApiResult<ActiveToDoDto>>;
        
        
         deleteFromTemplate(dto: ToDoTemplateDto):Promise<ApiResult<ToDoTemplateDto>>;
        
        
         deleteTemplate(dto: ToDoListTemplateDto):Promise<ApiResult<ToDoListTemplateDto>>;
        
        
         templateDetails(query: ToDoListTemplateQuery):Promise<ApiResult<ToDoListTemplateDto>>;
           
	
}
export const ToDoController :IToDoController =
{

 
	activeLists():Promise<ApiResult<ToDoListDto[]>>{
		return new Promise<ApiResult<ToDoListDto[]>>((resolve, reject) => {
		axios
			.post("/ToDo/ActiveLists",)
			.then(resp => {
				const vm = resp.data as ApiResult<ToDoListDto[]>;
				if(vm.successInd){
					resolve(vm);
				}
				
				reject(vm.errorMessage);
			})
			.catch(err => {
				reject(err);
			});
		});
	},

	addToDo(newToDo: ActiveToDoDto):Promise<ApiResult<ActiveToDoDto>>{
		return new Promise<ApiResult<ActiveToDoDto>>((resolve, reject) => {
		axios
			.post("/ToDo/AddToDo",newToDo)
			.then(resp => {
				const vm = resp.data as ApiResult<ActiveToDoDto>;
				if(vm.successInd){
					resolve(vm);
				}
				
				reject(vm.errorMessage);
			})
			.catch(err => {
				reject(err);
			});
		});
	},

	searchToDos(search: ToDoDto):Promise<ApiResult<ToDoDto[]>>{
		return new Promise<ApiResult<ToDoDto[]>>((resolve, reject) => {
		axios
			.post("/ToDo/SearchToDos",search)
			.then(resp => {
				const vm = resp.data as ApiResult<ToDoDto[]>;
				if(vm.successInd){
					resolve(vm);
				}
				
				reject(vm.errorMessage);
			})
			.catch(err => {
				reject(err);
			});
		});
	},

	listDetails(listId: ListDetailsQuery):Promise<ApiResult<ToDoListDto>>{
		return new Promise<ApiResult<ToDoListDto>>((resolve, reject) => {
		axios
			.post("/ToDo/ListDetails",listId)
			.then(resp => {
				const vm = resp.data as ApiResult<ToDoListDto>;
				if(vm.successInd){
					resolve(vm);
				}
				
				reject(vm.errorMessage);
			})
			.catch(err => {
				reject(err);
			});
		});
	},

	createNewList(dto: ToDoListDto):Promise<ApiResult<ToDoListDto>>{
		return new Promise<ApiResult<ToDoListDto>>((resolve, reject) => {
		axios
			.post("/ToDo/CreateNewList",dto)
			.then(resp => {
				const vm = resp.data as ApiResult<ToDoListDto>;
				if(vm.successInd){
					resolve(vm);
				}
				
				reject(vm.errorMessage);
			})
			.catch(err => {
				reject(err);
			});
		});
	},

	upsertToDo(newToDo: ActiveToDoDto):Promise<ApiResult<ActiveToDoDto>>{
		return new Promise<ApiResult<ActiveToDoDto>>((resolve, reject) => {
		axios
			.post("/ToDo/UpsertToDo",newToDo)
			.then(resp => {
				const vm = resp.data as ApiResult<ActiveToDoDto>;
				if(vm.successInd){
					resolve(vm);
				}
				
				reject(vm.errorMessage);
			})
			.catch(err => {
				reject(err);
			});
		});
	},

	updateListStatus(listDto: ToDoListDto):Promise<ApiResult<ToDoListDto>>{
		return new Promise<ApiResult<ToDoListDto>>((resolve, reject) => {
		axios
			.post("/ToDo/UpdateListStatus",listDto)
			.then(resp => {
				const vm = resp.data as ApiResult<ToDoListDto>;
				if(vm.successInd){
					resolve(vm);
				}
				
				reject(vm.errorMessage);
			})
			.catch(err => {
				reject(err);
			});
		});
	},

	createListTemplate(listDto: ToDoListDto):Promise<ApiResult<ToDoListTemplateDto>>{
		return new Promise<ApiResult<ToDoListTemplateDto>>((resolve, reject) => {
		axios
			.post("/ToDo/CreateListTemplate",listDto)
			.then(resp => {
				const vm = resp.data as ApiResult<ToDoListTemplateDto>;
				if(vm.successInd){
					resolve(vm);
				}
				
				reject(vm.errorMessage);
			})
			.catch(err => {
				reject(err);
			});
		});
	},

	upsertToDoTemplate(dto: ToDoTemplateDto):Promise<ApiResult<ToDoTemplateDto>>{
		return new Promise<ApiResult<ToDoTemplateDto>>((resolve, reject) => {
		axios
			.post("/ToDo/UpsertToDoTemplate",dto)
			.then(resp => {
				const vm = resp.data as ApiResult<ToDoTemplateDto>;
				if(vm.successInd){
					resolve(vm);
				}
				
				reject(vm.errorMessage);
			})
			.catch(err => {
				reject(err);
			});
		});
	},

	getActiveTemplates():Promise<ApiResult<ToDoListTemplateDto[]>>{
		return new Promise<ApiResult<ToDoListTemplateDto[]>>((resolve, reject) => {
		axios
			.post("/ToDo/GetActiveTemplates",)
			.then(resp => {
				const vm = resp.data as ApiResult<ToDoListTemplateDto[]>;
				if(vm.successInd){
					resolve(vm);
				}
				
				reject(vm.errorMessage);
			})
			.catch(err => {
				reject(err);
			});
		});
	},

	addTemplateToList(template: ToDoListTemplateDto):Promise<ApiResult<ToDoListDto>>{
		return new Promise<ApiResult<ToDoListDto>>((resolve, reject) => {
		axios
			.post("/ToDo/AddTemplateToList",template)
			.then(resp => {
				const vm = resp.data as ApiResult<ToDoListDto>;
				if(vm.successInd){
					resolve(vm);
				}
				
				reject(vm.errorMessage);
			})
			.catch(err => {
				reject(err);
			});
		});
	},

	deleteActiveToDo(dto: ActiveToDoDto):Promise<ApiResult<ActiveToDoDto>>{
		return new Promise<ApiResult<ActiveToDoDto>>((resolve, reject) => {
		axios
			.post("/ToDo/DeleteActiveToDo",dto)
			.then(resp => {
				const vm = resp.data as ApiResult<ActiveToDoDto>;
				if(vm.successInd){
					resolve(vm);
				}
				
				reject(vm.errorMessage);
			})
			.catch(err => {
				reject(err);
			});
		});
	},

	deleteFromTemplate(dto: ToDoTemplateDto):Promise<ApiResult<ToDoTemplateDto>>{
		return new Promise<ApiResult<ToDoTemplateDto>>((resolve, reject) => {
		axios
			.post("/ToDo/DeleteFromTemplate",dto)
			.then(resp => {
				const vm = resp.data as ApiResult<ToDoTemplateDto>;
				if(vm.successInd){
					resolve(vm);
				}
				
				reject(vm.errorMessage);
			})
			.catch(err => {
				reject(err);
			});
		});
	},

	deleteTemplate(dto: ToDoListTemplateDto):Promise<ApiResult<ToDoListTemplateDto>>{
		return new Promise<ApiResult<ToDoListTemplateDto>>((resolve, reject) => {
		axios
			.post("/ToDo/DeleteTemplate",dto)
			.then(resp => {
				const vm = resp.data as ApiResult<ToDoListTemplateDto>;
				if(vm.successInd){
					resolve(vm);
				}
				
				reject(vm.errorMessage);
			})
			.catch(err => {
				reject(err);
			});
		});
	},

	templateDetails(query: ToDoListTemplateQuery):Promise<ApiResult<ToDoListTemplateDto>>{
		return new Promise<ApiResult<ToDoListTemplateDto>>((resolve, reject) => {
		axios
			.post("/ToDo/TemplateDetails",query)
			.then(resp => {
				const vm = resp.data as ApiResult<ToDoListTemplateDto>;
				if(vm.successInd){
					resolve(vm);
				}
				
				reject(vm.errorMessage);
			})
			.catch(err => {
				reject(err);
			});
		});
	},


}


/*
Class:ToDoController
Get Type args:ToDoListDto[]ToDoListDto=False
Get Type args:ActiveToDoDto
Get Type args:ToDoDto[]ToDoDto=False
Get Type args:ToDoListDto
Get Type args:ToDoListDto
Get Type args:ActiveToDoDto
Get Type args:ToDoListDto
Get Type args:ToDoListTemplateDto
Get Type args:ToDoTemplateDto
Get Type args:ToDoListTemplateDto[]ToDoListTemplateDto=False
Get Type args:ToDoListDto
Get Type args:ActiveToDoDto
Get Type args:ToDoTemplateDto
Get Type args:ToDoListTemplateDto
Get Type args:ToDoListTemplateDto
ActiveToDoDto
ToDoDto
ListDetailsQuery
ToDoListDto
ActiveToDoDto
ToDoListDto
ToDoListDto
ToDoTemplateDto
ToDoListTemplateDto
ActiveToDoDto
ToDoTemplateDto
ToDoListTemplateDto
ToDoListTemplateQuery
ToDoListDto
ActiveToDoDto
ToDoDto
ToDoListDto
ToDoListDto
ActiveToDoDto
ToDoListDto
ToDoListTemplateDto
ToDoTemplateDto
ToDoListTemplateDto
ToDoListDto
ActiveToDoDto
ToDoTemplateDto
ToDoListTemplateDto
ToDoListTemplateDtoimport { ActiveToDoDto } from './ActiveToDoDto';
import { ToDoDto } from './ToDoDto';
import { ListDetailsQuery } from './ListDetailsQuery';
import { ToDoListDto } from './ToDoListDto';
import { ToDoTemplateDto } from './ToDoTemplateDto';
import { ToDoListTemplateDto } from './ToDoListTemplateDto';
import { ToDoListTemplateQuery } from './ToDoListTemplateQuery';

*/