import React from "react";
import cn from "classnames";
interface ClickableTextProps {
  text?: string;
  className?: string;
  isDragging?: boolean;
}
export const ClickableText: React.FC<
  ClickableTextProps & React.HTMLAttributes<HTMLElement>
> = ({ text, className, children, isDragging, ...rest }) => {
  return (
    <span
      className={cn("clickable-text", className, isDragging && "dragging")}
      {...rest}
    >
      {text || children}
    </span>
  );
};
