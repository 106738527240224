import { ToDoController } from "../../models/ToDoController";
import { useQuery, useMutation } from "react-query";
import { queryClient } from "../../index";
import { ActiveToDoDto } from "../../models/ActiveToDoDto";
import { ToDos } from "../../constants/magicStrings";
import { ToDoListDto } from "../../models/ToDoListDto";
import { ToDoListTemplateDto } from "../../models/ToDoListTemplateDto";

type ListSummary = {
  name: string;
  done: number;
  notDone: number;
  toDoListId: number;
};

function getListSummary(list: ToDoListDto): ListSummary {
  const done = list.toDos.filter((todo) => todo.isChecked)?.length || 0;
  const notDone = list.toDos.filter((todo) => !todo.isChecked)?.length || 0;
  return {
    name: list.listName,
    done,
    notDone,
    toDoListId: list.toDoListId,
  };
}

export const useActiveLists = () => {
  const { data, error, status } = useQuery(
    ToDos.ACTIVE_LISTS,
    ToDoController.activeLists
  );

  const {
    mutateAsync,
    data: mutationData,
    status: mutationStatus,
  } = useMutation(
    (newList: ToDoListDto) => {
      return ToDoController.createNewList(newList);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(ToDos.ACTIVE_LISTS);
      },
    }
  );

  return {
    allLists: data?.data,
    summaries: data?.data.map((list) => getListSummary(list)),
    error,
    status,
    createList: mutateAsync,
  };
};

export const useList = (listId: number, showDone: boolean, filter?: string) => {
  const query = useQuery([ToDos.LIST_DETAILS, listId], async () => {
    return await ToDoController.listDetails({ listId });
  });

  const { isError, isIdle, isLoading, status, mutateAsync, error, isSuccess } =
    useMutation(
      (newToDo: ActiveToDoDto) => {
        return ToDoController.upsertToDo(newToDo);
      },
      {
        onSuccess: () => {
          debugger;
          queryClient.invalidateQueries([ToDos.LIST_DETAILS, listId]);
        },
      }
    );

  const { mutateAsync: updateListStatus, isLoading: updatingStatus } =
    useMutation(
      (listUpdate: ToDoListDto) => {
        return ToDoController.updateListStatus(listUpdate);
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([ToDos.LIST_DETAILS, listId]);
        },
      }
    );

  const { mutateAsync: deleteTodo, isLoading: isDeleting } = useMutation(
    (dto: ActiveToDoDto) => {
      return ToDoController.deleteActiveToDo(dto);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([ToDos.LIST_DETAILS, listId]);
      },
    }
  );

  const { mutateAsync: saveAsTemplate, isLoading: saveAsTemplateLoading } =
    useMutation(
      (listUpdate: ToDoListDto) => {
        return ToDoController.createListTemplate(listUpdate);
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([ToDos.LIST_DETAILS, listId]);
        },
      }
    );

  const {
    mutateAsync: addTemplateToList,
    isLoading: addTemplateToListLoading,
  } = useMutation(
    (template: ToDoListTemplateDto) => {
      return ToDoController.addTemplateToList(template);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([ToDos.LIST_DETAILS, listId]);
      },
    }
  );

  const notDone = query.data?.data?.toDos?.filter((t) => !t.isChecked);

  return {
    allToDos: query.data?.data.toDos || [],
    deleteTodo,
    list: query.data?.data,
    visibleTodos: getVisibleToDos(query.data?.data?.toDos, showDone, filter),
    isLoading: query.isLoading,
    notDoneCount: notDone?.length || 0,
    doneCount: query.data?.data?.toDos?.filter((t) => t.isChecked)?.length || 0,
    error: query.error,
    upsertToDo: mutateAsync,
    isError,
    isIdle,
    isMutating:
      isLoading ||
      updatingStatus ||
      saveAsTemplateLoading ||
      addTemplateToListLoading ||
      isDeleting,
    status,
    mutationError: error,
    mutationSuccess: isSuccess,
    updateListStatus,
    saveAsTemplate,
    addTemplateToList,
  };
};

const getVisibleToDos = (
  toDos: ActiveToDoDto[],
  showDone: boolean,
  filter?: string
) => {
  if (!toDos) {
    return [];
  }

  const doneCriteria = showDone
    ? toDos
    : toDos.filter((todo) => !todo.isChecked);
  return filterToDosByText(doneCriteria, filter);
};

function filterToDosByText(todos: ActiveToDoDto[], filter?: string) {
  if (!filter) {
    return todos;
  }
  return todos.filter((todo) =>
    todo.toDo?.text.toLowerCase().includes(filter.toLowerCase())
  );
}
