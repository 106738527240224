import { useState } from "react";

const storageKey = `recipe-filter`;

export const useRecipeFilter = () => {
  const [recipeFilter, setRecipeFilter] = useState(() => {
    const val = getValueFromLs();
    return val || "";
  });

  const setFilter = (newFilter: string) => {
    setRecipeFilter(newFilter);
    localStorage.setItem(storageKey, JSON.stringify(newFilter));
  };

  return {
    recipeFilter,
    setFilter,
  };
};

function getValueFromLs() {
  const val = localStorage.getItem(storageKey);
  if (val) {
    return JSON.parse(val) as string;
  }
}
