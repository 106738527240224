import React, { useState, useEffect } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { PageContainer } from "../../components/containers/PageContainer";
import {
  Segment,
  Checkbox,
  Button,
  TextArea,
  Form,
  Divider,
  Input,
} from "semantic-ui-react";
import { DetailsPageHeader } from "../../components/DetailsPageHeader";
import { FormattedParagraph } from "../../components/FormattedParagraph";
import { GroceryDto } from "../../models/GroceryDto";
import { useFmgToast } from "../common/toast.hook";
import FlexContainer from "../../components/containers/FlexContainer";
import { Orientation, PushDirection } from "../../components/Enums";
import FlexChild from "../../components/containers/FlexChild";
import { routes } from "../../constants/routes";
import { ScrollToTop } from "../../components/utils/ScrollToTop";
import {
  frequencyDropDownOptions,
  mealTypeDropDownOptions,
} from "../../constants/units";
import { FrequencyDetailsDisplay } from "../../components/FrequencyDetailsDisplay";
import { useAvailableGroceries } from "./useAvailableGroceries";
type RouteProps = {
  groceryId: string;
};

type ModalOrRoute = RouteComponentProps<RouteProps>;

export const GroceryDetails: React.FC<ModalOrRoute> = (props) => {
  const { groceries, updateGrocery, deleteGrocery, isBusy } =
    useAvailableGroceries();
  const [grocery, setGrocery] = useState<GroceryDto>(null);
  const { addSuccess, addWarning } = useFmgToast();
  const history = useHistory();

  useEffect(() => {
    if (groceries.length) {
      const matchingGrocery = groceries.find(
        (g) => g.groceryId === Number(props.match.params.groceryId)
      );
      if (matchingGrocery) {
        setGrocery({ ...matchingGrocery });
      }
    }
  }, [groceries, props.match.params.groceryId]);

  const [editMode, setEdit] = useState(false);
  return (
    <PageContainer>
      <ScrollToTop></ScrollToTop>
      <Segment loading={isBusy}>
        {grocery && (
          <>
            <DetailsPageHeader
              editMode={editMode}
              onEdit={() => setEdit(!editMode)}
              headerName={grocery.name}
              onHeaderChange={(newName) => {
                setGrocery({ ...grocery, name: newName });
              }}
            ></DetailsPageHeader>
            <Divider></Divider>
            <Form>
              <Form.Field>
                <h4>Notes</h4>
                {editMode ? (
                  <TextArea
                    value={grocery.notes}
                    onChange={(e) => {
                      setGrocery({ ...grocery, notes: e.currentTarget.value });
                    }}
                  ></TextArea>
                ) : (
                  <FormattedParagraph>{grocery.notes}</FormattedParagraph>
                )}
              </Form.Field>
              <Form.Field>
                <Checkbox
                  disabled={!editMode}
                  label="Organic"
                  onClick={() =>
                    setGrocery({ ...grocery, organic: !grocery.organic })
                  }
                  checked={grocery.organic}
                ></Checkbox>
              </Form.Field>
              {editMode ? (
                <>
                  <h4>Frequency</h4>
                  <Form.Field>
                    <span>Plan this every </span>
                    <Input
                      value={grocery.autoPlanSettings?.value}
                      disabled={!editMode}
                      type="number"
                      onChange={(e) => {
                        setGrocery({
                          ...grocery,
                          autoPlanSettings: {
                            ...grocery.autoPlanSettings,
                            value: Number(e.currentTarget.value),
                          },
                        });
                      }}
                    ></Input>
                  </Form.Field>
                  <Form.Field>
                    <Form.Select
                      disabled={!editMode}
                      options={frequencyDropDownOptions}
                      value={grocery.autoPlanSettings?.frequency}
                      onChange={(e, val) => {
                        setGrocery({
                          ...grocery,
                          autoPlanSettings: {
                            ...grocery.autoPlanSettings,
                            frequency: String(val.value),
                          },
                        });
                      }}
                    />
                  </Form.Field>
                  <Form.Field>
                    <h4>Meal Type</h4>
                    <Form.Select
                      value={grocery.mealType}
                      onChange={(_, val) => {
                        setGrocery({
                          ...grocery,
                          mealType: String(val.value),
                        });
                      }}
                      options={mealTypeDropDownOptions}
                    ></Form.Select>
                  </Form.Field>
                </>
              ) : (
                <FrequencyDetailsDisplay
                  settings={grocery.autoPlanSettings}
                  mealType={grocery.mealType}
                />
              )}

              {editMode && (
                <FlexContainer orientation={Orientation.Horizontal}>
                  <Button
                    type="submit"
                    positive
                    onClick={() => {
                      updateGrocery(grocery).then(() => {
                        addSuccess(`Updated Grocery`);
                      });
                    }}
                  >
                    Save
                  </Button>

                  <FlexChild pushDirection={PushDirection.Right}>
                    <Button
                      type="submit"
                      negative
                      onClick={() => {
                        deleteGrocery(grocery).then(() => {
                          addWarning(`Deleted ${grocery.name}`);
                          history.push(routes.groceries);
                        });
                      }}
                    >
                      Delete
                    </Button>
                  </FlexChild>
                </FlexContainer>
              )}
            </Form>
          </>
        )}
      </Segment>
    </PageContainer>
  );
};
