import React from "react";
import { MealPlanDto } from "../../models/MealPlanDto";
import { Modal } from "semantic-ui-react";
export type ShoppingListSummaryProps = {
  plan: MealPlanDto;
  open: boolean;
  onClose(): void;
};

export const ShoppingListSummary: React.FC<ShoppingListSummaryProps> = ({
  plan,
  open,
  onClose,
}) => {
  return (
    <Modal onClose={onClose} open={open} closeIcon>
      <Modal.Header>Shopping Summary</Modal.Header>
      <Modal.Content>
        {plan.plannedFoods.map((food) => {
          return (
            <ShoppingItemSummary
              key={food.plannedFoodId}
              name={food.groceryName}
              quantity={food.shoppingQuantity}
              unit={food.shoppingUnit}
            />
          );
        })}

        {plan.plannedRecipes.flatMap((recipe) => {
          return recipe.addedIngredients.map((ingred) => {
            return (
              <ShoppingItemSummary
                key={`${ingred.plannedRecipeId}-${ingred.ingredientName}`}
                name={ingred.ingredientName}
                quantity={ingred.shoppingQuantity}
                unit={ingred.shoppingUnit}
              />
            );
          });
        })}
      </Modal.Content>
    </Modal>
  );
};

export const ShoppingItemSummary = ({ name, quantity, unit }) => {
  return (
    <p>
      <strong>{name}</strong> - {quantity} {unit}
    </p>
  );
};
