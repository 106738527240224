import React from "react";
import { StoreDto } from "../../models/StoreDto";
import { Dropdown, Button, Icon } from "semantic-ui-react";

interface StoreDropdownProps {
  stores: StoreDto[];
  selectedStore: StoreDto;
  onChange(storeId: number): void;
}
export const StoreDropdown = (props: StoreDropdownProps) => {
  const { stores, selectedStore, onChange } = props;

  return (
    <Button size={"large"}>
      <Icon name={"cart"}></Icon>
      <Dropdown
        options={stores.map((s) => {
          return { key: s.storeId, value: s.storeId, text: s.storeName };
        })}
        onChange={(e, data) => {
          onChange(Number(data.value));
        }}
        value={selectedStore.storeId}
      ></Dropdown>
    </Button>
  );
};
