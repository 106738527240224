export const nameof = <T>(name: keyof T) => name;

export const convertToDate = (date: Date | string | Date[]): Date => {
  if (typeof date === "string") {
    return new Date(date);
  }

  if (Array.isArray(date)) {
    return date[0];
  }

  return date;
};
