import * as React from "react";
import { Modal, Message, Form, Button, Checkbox } from "semantic-ui-react";
import { NewStoreForm } from "../../models/NewStoreForm";

interface AddStoreModalProps {
  show: boolean;
  addStore(store: NewStoreForm): void;
  onHide(): void;
  loading: boolean;
}
export const NewStoreModal = (props: AddStoreModalProps) => {
  const [newStoreForm, setForm] = React.useState<NewStoreForm>({
    storeName: "",
    isDefault: false,
  });

  return (
    <Modal size={"small"} open={props.show} onClose={props.onHide} closeIcon>
      <Modal.Header>Add a Store</Modal.Header>
      <Modal.Content>
        <Message info>Pick a store name and add locations after</Message>
        <Form loading={props.loading}>
          <Form.Field>
            <label>Store Name</label>
            <input
              type="text"
              value={newStoreForm.storeName}
              onChange={(e) => {
                setForm({
                  ...newStoreForm,
                  storeName: e.currentTarget.value,
                });
              }}
            />
          </Form.Field>
          <Form.Field>
            <Checkbox
              label="Default Store"
              checked={newStoreForm.isDefault}
              onChange={(e) => {
                setForm({
                  ...newStoreForm,
                  isDefault: !newStoreForm.isDefault,
                });
              }}
            ></Checkbox>
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          positive
          disabled={newStoreForm.storeName === "" || props.loading}
          onClick={() => props.addStore(newStoreForm)}
        >
          Add
        </Button>
        <Button negative onClick={() => props.onHide()}>
          Cancel
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
