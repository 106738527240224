import React, { useEffect, useRef, useState } from "react";
import {
  Header,
  Segment,
  Table,
  Responsive,
  Button,
  Icon,
  Progress,
  Popup,
  Label,
  Input,
  Dropdown,
} from "semantic-ui-react";
import FlexContainer from "../../components/containers/FlexContainer";
import {
  Orientation,
  Alignment,
  PushDirection,
  Gap,
  JustifyContent,
} from "../../components/Enums";
import FlexChild from "../../components/containers/FlexChild";
import { BigCheckbox } from "../../components/BigCheckbox";
import { AddToDoModal } from "./AddToDoModal";
import { useList } from "./useList";
import { RouteComponentProps, useHistory } from "react-router";
import { ClickableText } from "../../components/ClickableText";
import { ActiveToDoDto } from "../../models/ActiveToDoDto";
import { useFmgToast } from "../common/toast.hook";
import { formatDistance, formatDistanceStrict } from "date-fns";
import { convertToDate } from "../../util";
import { superShortDate } from "../../constants/dateFormatters";
import { useShowDone } from "./useShowDone";
import { ListOptionsModal } from "./ListOptionsModal";
import { RowStatus } from "../../models/RowStatus";
import { routes } from "../../constants/routes";
import { SelectTemplateModal } from "./SelectTemplateModal";
import { PrintableList } from "./PrintableList";
type RouteProps = {
  listId: string;
};

export function ToDoListPage(props: RouteComponentProps<RouteProps>) {
  const { listId } = props.match.params;
  const [filter, setFilter] = useState("");
  const [print, setPrint] = useState(false);
  const { showDone, toggleShowDone } = useShowDone(Number(listId));
  const {
    allToDos,
    visibleTodos,
    isLoading,
    upsertToDo,
    isMutating,
    mutationSuccess,
    list,
    doneCount,
    notDoneCount,
    updateListStatus,
    saveAsTemplate,
    addTemplateToList,
    deleteTodo,
  } = useList(Number(listId), showDone, filter);

  const { addSuccess, addWarning, addError } = useFmgToast();
  const history = useHistory();
  const [showAddModal, setShowAddModal] = React.useState<{
    show: boolean;
    editTodo?: ActiveToDoDto | null;
  }>({
    show: false,
    editTodo: null,
  });
  const keepModalOpen = useRef(false);

  useEffect(() => {
    if (mutationSuccess) {
      // addSuccess("ToDo added");
    }

    if (mutationSuccess && keepModalOpen.current === false) {
      setShowAddModal({ show: false });
    }
  }, [mutationSuccess]);

  const onSubmit = async (newToDo: ActiveToDoDto, keepOpen) => {
    keepModalOpen.current = keepOpen;
    return upsertToDo({
      ...newToDo,
      toDoListId: list.toDoListId,
    });
  };

  useEffect(() => {
    window.onbeforeprint = () => {
      setPrint(true);
    };

    window.onafterprint = () => {
      setPrint(false);
    };

    return () => {
      window.onbeforeprint = null;
      window.onafterprint = null;
    };
  }, []);

  useEffect(() => {
    if (print) {
      window.print();
    }
  }, [print]);

  const percentDone = (doneCount / (doneCount + notDoneCount)) * 100;

  const [showOptionsModal, setShowOptionsModal] = useState(false);
  const [showTemplateModal, setShowTemplateModal] = useState(false);

  return (
    <div>
      {print && <PrintableList list={list} />}
      {showTemplateModal && (
        <SelectTemplateModal
          show={showTemplateModal}
          onClose={() => setShowTemplateModal(false)}
          isMutating={isMutating}
          onSubmit={async (template) => {
            try {
              await addTemplateToList({
                ...template,
                toDoListId: list.toDoListId,
              });
              addSuccess(`Added todos from template ${template.listName}`);
              setShowTemplateModal(false);
            } catch (err) {
              console.error(err);
              addError("Error adding template");
            }
          }}
        />
      )}
      {showOptionsModal && (
        <ListOptionsModal
          show={showOptionsModal}
          busy={isMutating}
          onClose={() => setShowOptionsModal(false)}
          onSaveAsTemplate={async () => {
            await saveAsTemplate(list);
            addSuccess("List saved as template");
            setShowOptionsModal(false);
          }}
          onPrint={() => {
            setShowOptionsModal(false);
            setPrint(true);
          }}
          onArchive={async () => {
            await updateListStatus({
              status: RowStatus.Archived,
              toDoListId: list.toDoListId,
            });
            addWarning("Archived");
            setShowOptionsModal(false);
          }}
          onDelete={async () => {
            await updateListStatus({
              status: RowStatus.Deleted,
              toDoListId: list.toDoListId,
            });
            addWarning("Deleted");
            history.push(routes.todos);
          }}
        ></ListOptionsModal>
      )}
      {showAddModal.show && (
        <AddToDoModal
          busy={isMutating}
          show={showAddModal.show}
          editToDo={showAddModal.editTodo}
          onDelete={async (editTodo: ActiveToDoDto) => {
            await deleteTodo(editTodo);
            setShowAddModal({ show: false });
            addWarning("Deleted todo");
          }}
          onSubmit={async (newToDo: ActiveToDoDto, keepOpen) => {
            await onSubmit(newToDo, keepOpen);
          }}
          onClose={() => setShowAddModal({ show: false })}
        ></AddToDoModal>
      )}
      <Header attached="top" as="h4" block>
        <FlexContainer
          orientation={Orientation.Horizontal}
          gap={Gap.Medium}
          alignment={Alignment.Center}
        >
          <ClickableText onClick={() => setShowOptionsModal(true)}>
            {list?.listName}
          </ClickableText>
          {/* <span>{list?.status}</span> */}
          {list?.listName !== undefined && (
            <span>
              {doneCount || 0} / {allToDos?.length || 0}
            </span>
          )}

          <FlexChild pushDirection={PushDirection.Right}>
            <FlexContainer orientation={Orientation.Horizontal}>
              <Button size="small" onClick={() => toggleShowDone()}>
                {showDone ? "Hide Done" : "Show Done"}
              </Button>
              <Button.Group positive>
                <Button onClick={() => setShowAddModal({ show: true })}>
                  Add
                </Button>
                <Dropdown
                  className="button icon"
                  floating
                  options={[
                    <Dropdown.Item onClick={() => setShowTemplateModal(true)}>
                      From Template
                    </Dropdown.Item>,
                  ]}
                  trigger={<></>}
                ></Dropdown>
              </Button.Group>
            </FlexContainer>
          </FlexChild>
        </FlexContainer>
      </Header>
      <Segment
        loading={isLoading || isMutating}
        attached="bottom"
        className="table-container"
      >
        <Progress percent={percentDone} attached="top" indicating />
        <Input
          placeholder="Filter"
          value={filter}
          onChange={(event) => setFilter(event.target.value)}
          type={"text"}
        ></Input>
        <Table compact striped id="mainTable" unstackable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell textAlign="center" width={3}>
                Due
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="center" width={2}>
                Move
              </Table.HeaderCell>
              <Table.HeaderCell textAlign={"center"} width={1}>
                Done
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {visibleTodos
              ?.sort((a, b) => (a.order > b.order ? 1 : -1))
              .map((todo) => (
                <Responsive
                  as={Table.Row}
                  key={todo.activeToDoId}
                  className="check-element"
                  negative={
                    todo?.dueDate && convertToDate(todo.dueDate) <= new Date()
                  }
                >
                  <Table.Cell>
                    <ClickableText
                      onClick={() => {
                        setShowAddModal({ show: true, editTodo: todo });
                      }}
                    >
                      {" "}
                      {todo.toDo?.text}
                    </ClickableText>
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    {todo.dueDate && (
                      <Popup
                        content={formatDistance(
                          convertToDate(todo.dueDate),
                          new Date(),
                          {
                            addSuffix: true,
                          }
                        )}
                        trigger={
                          <ClickableText
                            onClick={() => {
                              setShowAddModal({ show: true, editTodo: todo });
                            }}
                          >
                            {superShortDate(todo.dueDate)}
                          </ClickableText>
                        }
                      />
                    )}
                    {/* <Progress size="tiny" indicating percent={80}></Progress> TODO maybe a progress bar to visually indicate how much time is left  */}
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    <FlexContainer
                      orientation={Orientation.Horizontal}
                      justifyContent={JustifyContent.JustifyCenter}
                    >
                      <Button
                        size={"mini"}
                        onClick={() => {
                          upsertToDo({
                            ...todo,
                            toDoListId: list.toDoListId,
                            order: todo.order - 1,
                          });
                        }}
                        icon={<Icon name="arrow up" />}
                      />
                      <Button
                        size={"mini"}
                        onClick={() => {
                          upsertToDo({
                            ...todo,
                            toDoListId: list.toDoListId,
                            order: todo.order + 1,
                          });
                        }}
                        icon={<Icon name="arrow down" />}
                      />
                    </FlexContainer>
                  </Table.Cell>

                  <Table.Cell textAlign={"center"}>
                    <BigCheckbox
                      isChecked={todo.isChecked}
                      handlechange={() => {
                        upsertToDo({
                          ...todo,
                          toDoListId: list.toDoListId,
                          isChecked: !todo.isChecked,
                        });
                      }}
                    />
                  </Table.Cell>
                </Responsive>
              ))}
          </Table.Body>
        </Table>
      </Segment>
    </div>
  );
}
