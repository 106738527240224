import React, { useState } from "react";
import { Grid, Header, Segment, Button } from "semantic-ui-react";
import { NewPlanForm } from "./NewPlanForm";
import { PlanDashboardTable } from "./PlanDashboardTable";
import { PlanDashboardRow } from "./PlanDashboardRow";
import { useHistory } from "react-router-dom";
import { routes } from "../../constants/routes";
import FlexContainer from "../../components/containers/FlexContainer";
import FlexChild from "../../components/containers/FlexChild";
import { PushDirection, Orientation } from "../../components/Enums";
import { useFmgToast } from "../common/toast.hook";
import { useMealPlans } from "./usePlan";

export const MealPlanDashboard = () => {
  const history = useHistory();
  const { addError, addSuccess } = useFmgToast();
  const { mealPlans, createPlan } = useMealPlans();
  const [showNewModal, setShowNew] = useState(false);

  return (
    <Grid.Column mobile={16} computer={8}>
      {showNewModal && (
        <NewPlanForm
          submitForm={async (newPlan) => {
            try {
              await createPlan(newPlan);
              setShowNew(false);
              addSuccess(`Meal plan created successfully`);
            } catch (err) {
              addError(`There was an error creating your meal plan.`);
              console.error(err);
            }
          }}
          show={true}
          onClose={() => setShowNew(false)}
        ></NewPlanForm>
      )}
      <Header as="h4" attached="top" block>
        Meal Plans
      </Header>
      <Segment attached="bottom">
        <FlexContainer orientation={Orientation.Horizontal}>
          <FlexChild pushDirection={PushDirection.Right}>
            <Button onClick={() => setShowNew(true)} positive>
              New Plan
            </Button>
          </FlexChild>
        </FlexContainer>
        <PlanDashboardTable>
          {mealPlans.map((plan) => {
            return (
              <PlanDashboardRow
                key={plan.mealPlanId}
                mealPlan={plan}
                planClicked={(mealPlan) =>
                  history.push(routes.planDetails(mealPlan.mealPlanId))
                }
              ></PlanDashboardRow>
            );
          })}
        </PlanDashboardTable>
      </Segment>
      {/* <QuickRecipeTable
        currentSort={recipeSort}
        onSort={sortRecipesChanged}
        filterValue={recipeFilter}
        onFilterChange={(newVal) => setRecipeFilter(newVal)}
        isBusy={false}
        onNewClick={() => setShowNew(true)}
      >
        {recipes.visibleRecipes.map((recipe) => {
          return (
            <QuickRecipeRow
              key={recipe.recipeId}
              recipe={recipe}
              recipeClicked={() =>
                props.history.push(routes.recipeDetails(recipe.recipeId))
              }
              ratingChanged={async (newRating) => {
                await modifyRecipeDetails({ ...recipe, rating: newRating });
                addToast("Saved Successfully", { appearance: "success" });
              }}
            ></QuickRecipeRow>
          );
        })}
      </QuickRecipeTable> */}
    </Grid.Column>
  );
};
