import * as React from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { EditNumberUpdate } from "../EditNumberUpdate";
import { Input } from "semantic-ui-react";
interface ClickToEditTextProps {
  entityId: number;
  currentValue: number;
  numberUpdated(newVal: EditNumberUpdate): void;
}

interface ClickToEditTextState {
  isOpen: boolean;
  numberValue: number;
}

class ClickToEditText extends React.Component<
  ClickToEditTextProps,
  ClickToEditTextState
> {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      numberValue: this.props.currentValue
    } as ClickToEditTextState;
  }

  _handleKeyPress = e => {
    if (e.key === "Enter") {
      let updt: EditNumberUpdate = {
        entityId: this.props.entityId,
        newVal: this.state.numberValue
      };
      this.props.numberUpdated(updt);
      this.setState({ isOpen: false });
    }
  };

  saveQty = () => {
    alert("save qty!");
  };

  handleUpdate = event => {
    this.setState({ numberValue: event.currentTarget.value });
  };

  showEdit = () => {
    this.setState({ isOpen: true });
  };

  componentDidMount() {
    //todo auto select the text
  }

  render() {
    if (!this.state.isOpen) {
      return <div onClick={this.showEdit}>{this.props.currentValue}</div>;
    }

    return (
      <OutsideClickHandler
        onOutsideClick={() => {
          if (this.state.isOpen) {
            this.setState({ isOpen: false });
          }
        }}
      >
        <Input
          className="table-input no-spin"
          type="number"
          onKeyPress={this._handleKeyPress}
          value={this.state.numberValue}
          placeholder="Enter number"
          onChange={this.handleUpdate}
        />
      </OutsideClickHandler>
    );
  }
}

export default ClickToEditText;
