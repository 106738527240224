import { MealPlanDto } from "../../models/MealPlanDto";
import { Table } from "semantic-ui-react";
import { ClickableText } from "../../components/ClickableText";
import React from "react";
import { isWithinInterval } from "date-fns";
import { convertToDate } from "../../util";
import { dayAndYearFormat } from "../../constants/dateFormatters";
import classNames from "classnames";

type PlanDashboardRowProps = {
  mealPlan: MealPlanDto;
  planClicked: (plan: MealPlanDto) => void;
};

export const PlanDashboardRow: React.FC<PlanDashboardRowProps> = ({
  mealPlan,
  planClicked,
}) => {
  const isCurrent = isWithinInterval(new Date(), {
    start: convertToDate(convertToDate(mealPlan.startDate).toDateString()),
    end: convertToDate(convertToDate(mealPlan.endDate).toDateString()),
  });

  return (
    <Table.Row className={classNames(isCurrent && "highlight")}>
      <Table.Cell>
        <ClickableText onClick={() => planClicked(mealPlan)}>
          {dayAndYearFormat(mealPlan.startDate)}
        </ClickableText>
      </Table.Cell>
      <Table.Cell>
        <ClickableText onClick={() => planClicked(mealPlan)}>
          {dayAndYearFormat(mealPlan.endDate)}
        </ClickableText>
      </Table.Cell>
      <Table.Cell>{mealPlan.status}</Table.Cell>
    </Table.Row>
  );
};
