import * as React from "react";
import { RecipeDto } from "../../models/RecipeDto";
import { RecipesController } from "../../models/RecipesController";
import { sortRecipesByName } from "../../sorting";
import { SortDirection } from "../../components/Enums";
import { StoreDto } from "../../models/StoreDto";
import { StoresController } from "../../models/StoresController";

type StoreContext = {
  stores: Array<StoreDto>;
  upsertStore: (newStores: StoreDto) => void;
};
export const StoreContext = React.createContext<StoreContext>(
  {} as StoreContext
);

const { Provider } = StoreContext;

export const StoreProvider = (props) => {
  const [stores, setStores] = React.useState<Array<StoreDto>>([]);
  // const [recipes, setRecipes] = React.useState<Array<RecipeDto>>([]);
  React.useEffect(() => {
    StoresController.allStores()
      .then((response) => {
        const data = response.data;
        setStores(data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const upsertStore = (modifiedStore: StoreDto) => {
    const unchangedStores = stores.filter(
      (s) => s.storeId !== modifiedStore.storeId
    );
    setStores([...unchangedStores, modifiedStore]);
  };

  return (
    <Provider
      value={{
        stores: stores,
        upsertStore: upsertStore,
      }}
    >
      {props.children}
    </Provider>
  );
};
