import * as React from "react";
import { Modal, Button } from "semantic-ui-react";
interface ConfirmModalProps {
  prompt: string;
  title?: string;
  onClose(confirmDelete: boolean): void;
  onHide(): void;
  show: boolean;
}
interface ConfirmModalState {
  show: boolean;
}
class ConfirmModal extends React.Component<
  ConfirmModalProps,
  ConfirmModalState
> {
  constructor(props) {
    super(props);
    this.state = { show: false };
  }

  render() {
    const { prompt, title, show } = this.props;
    return (
      <Modal
        size={"mini"}
        open={show}
        onClose={() => this.props.onHide()}
        closeIcon
      >
        {title && <Modal.Header>{title}</Modal.Header>}
        <Modal.Content>
          <p>{prompt}</p>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={() => this.props.onClose(false)}>
            No
          </Button>
          <Button
            onClick={() => this.props.onClose(true)}
            positive
            icon="checkmark"
            labelPosition="right"
            content="Yes"
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

export default ConfirmModal;
