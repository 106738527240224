import { UnitOfMeasure } from "../models/UnitOfMeasure";
import { DropdownOption } from "../models/DropdownOption";
import { Frequency } from "../models/Frequency";
import { DropdownItemProps } from "semantic-ui-react";
import { MealType } from "../models/MealType";

export const unitsOfMeasure = [
  UnitOfMeasure.Each,
  UnitOfMeasure.Lbs,
  UnitOfMeasure.Oz,
  UnitOfMeasure.Cans,
  UnitOfMeasure.Package,
  UnitOfMeasure.Teaspoon,
  UnitOfMeasure.Tablespoon,
  UnitOfMeasure.Cup,
  UnitOfMeasure.Pint,
  UnitOfMeasure.Pinch,
  UnitOfMeasure.Gallon,
  UnitOfMeasure.Quart,
  UnitOfMeasure.Unknown,
];

export const unitDropdownOptions: Array<DropdownOption> = unitsOfMeasure.map(
  (unit) => {
    return {
      displayValue: unit,
      id: unit,
    };
  }
);

export const frequencyUnits = [...Object.values(Frequency)];

export const frequencyDropDownOptions: Array<DropdownItemProps> =
  frequencyUnits.map((unit) => {
    return {
      text: unit,
      value: unit,
    };
  });

export const mealTypeDropDownOptions = Object.values(MealType).map(
  (mealType) => {
    return {
      text: mealType,
      value: mealType,
    };
  }
);
