import React, { useState, useEffect, useRef } from "react";
import Highlighter from "react-highlight-words";
import {
  Modal,
  Button,
  Form,
  TextArea,
  Search,
  Loader,
  Ref,
} from "semantic-ui-react";
import { ToDoDto } from "../../models/ToDoDto";
import { ToDoController } from "../../models/ToDoController";
import FlexContainer from "../../components/containers/FlexContainer";
import { ActiveToDoDto } from "../../models/ActiveToDoDto";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import { convertToDate } from "../../util";
import { useDelay } from "../useDelay";
import FlexChild from "../../components/containers/FlexChild";
import { Alignment, Orientation, PushDirection } from "../../components/Enums";
import { ToDoTemplateDto } from "../../models/ToDoTemplateDto";

type AddToDoModalProps = {
  show: boolean;
  onClose: () => void;
  onDelete: (existingToDo: ActiveToDoDto) => Promise<void>;
  onSubmit: (newToDo: ActiveToDoDto, keepOpen: boolean) => Promise<void>;
  editToDo?: ActiveToDoDto | ToDoTemplateDto;
  busy: boolean;
};

type SearchResult = {
  title: string;
} & ToDoDto;

type ResultProps = SearchResult & {
  searchString: string;
};
const Result = (props: ResultProps) => {
  if (!props.title) {
    return null;
  }

  const searchWords = props.searchString.split(" ");
  return (
    <Highlighter
      highlightClassName="highlight"
      searchWords={searchWords}
      autoEscape={true}
      textToHighlight={props.title}
    />
  );
};

export function AddToDoModal(props: AddToDoModalProps) {
  const { show, onClose, onSubmit, busy, editToDo, onDelete } = props;
  const searchBox = useRef<HTMLTextAreaElement>(null);

  const [searching, setSearching] = useState(false);

  const existingToDoSelected = useRef(false);
  const [selectedResult, setSelectedResult] = useState<ToDoDto>(
    props.editToDo?.toDo || {}
  );
  const [searchResults, setSearchResults] = useState<SearchResult[]>([]);
  const [todoForm, setToDoForm] = React.useState<{
    text: string;
    dueDate: Date;
  }>({
    text: editToDo?.toDo?.text || "",
    dueDate: getDueDate(editToDo),
  });
  const onResultSelect = (e, data) => {
    const result: SearchResult = data.result;
    setSelectedResult(result);
  };

  const handleSearchChange = (e, { value }) => {
    if (existingToDoSelected.current) {
      setSelectedResult({});
    }
    setToDoForm((prevState) => ({ ...prevState, text: value }));
  };

  const searchTerm = useDelay(todoForm.text, 400);

  useEffect(() => {
    if (!searchTerm) {
      setSearchResults([]);
      return;
    }

    if (searchTerm.length < 3) {
      return;
    }

    setSearching(true);
    ToDoController.searchToDos({ text: searchTerm })
      .then((result) => {
        setSearchResults(
          result.data.map((t) => {
            return { ...t, title: t.text };
          })
        );
      })
      .finally(() => {
        setSearching(false);
      });
  }, [searchTerm]);

  useEffect(() => {
    if (!show) {
      setToDoForm((prev) => {
        return {
          ...prev,
          text: "",
        };
      });
    }
  }, [show]);

  useEffect(() => {
    if (selectedResult.toDoId) {
      existingToDoSelected.current = true;
      setToDoForm((prev) => {
        return {
          ...prev,
          text: selectedResult.text,
        };
      });
    }
  }, [selectedResult.text, selectedResult.toDoId]);

  return (
    <Modal size={"small"} open={show} onClose={onClose} closeIcon>
      <Modal.Header>
        <FlexContainer
          orientation={Orientation.Horizontal}
          alignment={Alignment.Center}
        >
          {editToDo ? "Edit ToDo" : "Create New ToDo"}
          {searching && (
            <Loader className="workaround" active inline size="small" />
          )}
          {editToDo ? (
            <FlexChild helperClass="delete-todo">
              <Button
                disabled={busy}
                onClick={() => onDelete(editToDo)}
                negative
              >
                Delete
              </Button>
            </FlexChild>
          ) : null}
        </FlexContainer>
      </Modal.Header>
      <Modal.Content>
        <Form loading={busy}>
          <Form.Field>
            <Search
              input={
                <textarea
                  ref={searchBox}
                  placeholder="Start typing to search..."
                />
              }
              icon={"search"}
              onResultSelect={onResultSelect}
              onSearchChange={handleSearchChange}
              showNoResults={false}
              results={searchResults}
              minCharacters={3}
              resultRenderer={(result) => (
                <Result searchString={todoForm.text} {...result} />
              )}
              rows={4}
              value={todoForm.text}
            />
          </Form.Field>
          <Form.Field className="text-align-center">
            <label>Due Date</label>
            <SemanticDatepicker
              name="startDate"
              type="basic"
              value={todoForm.dueDate}
              pointing="right"
              onChange={(event, data) => {
                setToDoForm((prev) => {
                  return {
                    ...prev,
                    dueDate: convertToDate(data.value),
                  };
                });
              }}
            />
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions
        className={"horizontal-flex-container justify-space-between"}
      >
        <Button negative onClick={() => onClose()}>
          Cancel
        </Button>
        <FlexContainer>
          <Button
            positive
            disabled={!todoForm || busy}
            onClick={async () => {
              await onSubmit(
                {
                  ...editToDo,
                  toDo: {
                    text: todoForm.text,
                    toDoId: selectedResult?.toDoId,
                  },
                  toDoId: selectedResult?.toDoId,
                  ...(todoForm.dueDate && { dueDate: todoForm.dueDate }),
                },
                true
              );
              searchBox.current?.select();
            }}
          >
            Add & Another
          </Button>
          <Button
            positive
            disabled={!todoForm || busy}
            onClick={async () => {
              await onSubmit(
                {
                  ...editToDo,
                  toDo: {
                    text: todoForm.text,
                    toDoId: selectedResult?.toDoId,
                  },
                  toDoId: selectedResult?.toDoId,
                  ...(todoForm.dueDate && { dueDate: todoForm.dueDate }),
                },
                false
              );
            }}
          >
            {editToDo ? "Save" : "Add"}
          </Button>
        </FlexContainer>
      </Modal.Actions>
    </Modal>
  );
}

function getDueDate(todo: ActiveToDoDto | ToDoTemplateDto): Date | null {
  if (isActiveTodo(todo) && todo?.dueDate) {
    return convertToDate(todo?.dueDate);
  }
  return null;
}

function isActiveTodo(
  todo: ActiveToDoDto | ToDoTemplateDto
): todo is ActiveToDoDto {
  return (todo as ActiveToDoDto)?.dueDate !== undefined;
}
