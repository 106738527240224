import { useMutation, useQuery } from "react-query";
import { queryClient } from "../..";
import { GROCERIES } from "../../constants/magicStrings";
import { GroceriesController } from "../../models/GroceriesController";
import { GroceryDto } from "../../models/GroceryDto";

export const useAvailableGroceries = () => {
  const { data, isLoading, error } = useQuery(
    GROCERIES.AVAILABLE_GROCERIES,
    GroceriesController.availableGroceries
  );

  const {
    mutateAsync: updateGrocery,
    data: mutationData,
    status: mutationStatus,
    isLoading: mutationIsLoading,
  } = useMutation(
    (grocery: GroceryDto) => {
      return GroceriesController.modifyGrocery(grocery);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(GROCERIES.AVAILABLE_GROCERIES);
      },
    }
  );

  const deleteGrocery = async (grocery: GroceryDto) => {
    return updateGrocery({ ...grocery, activeInd: false });
  };

  return {
    groceries: data?.data || [],
    deleteGrocery,
    updateGrocery,
    isBusy: mutationIsLoading || isLoading,
  };
};
