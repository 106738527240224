import { GroceriesController } from "../../models/GroceriesController";
import { useMutation, useQuery } from "react-query";
import { Shopping } from "../../constants/magicStrings";
import { GroceryDto } from "../../models/GroceryDto";
import { queryClient } from "../..";
import { ListUpdate } from "../../models/ListUpdate";
import { StoresController } from "../../models/StoresController";
import { GroceryStoreLocationDto } from "../../models/GroceryStoreLocationDto";
import { ShoppingListViewModel } from "../../models/ShoppingListViewModel";
import { ApiResult } from "../../models/ApiResult";

export const useGroceryUpdates = () => {
  const { mutate: addGroceryToList } = useMutation(
    (grocery: GroceryDto | string) => {
      const groceryToAdd = getGroceryToAdd(grocery);
      //optimistic update to set loading
      return GroceriesController.addToList(groceryToAdd);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(Shopping.SHOPPING_LIST);
      },
    }
  );

  const { mutate: removeDone } = useMutation(
    () => {
      return GroceriesController.removeDone();
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(Shopping.SHOPPING_LIST);
      },
    }
  );

  const { mutate: listUpdate } = useMutation(
    (update: ListUpdate) => {
      return GroceriesController.listUpdate(update);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(Shopping.SHOPPING_LIST);
      },
      onMutate: (variables: ListUpdate) => {
        setGroceryLoading(variables.groceryId);
      },
    }
  );

  const { mutate: updateLocation } = useMutation(
    (update: GroceryStoreLocationDto) => {
      return StoresController.updateGroceryLocation(update);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(Shopping.SHOPPING_LIST);
      },
      onMutate: (variables: GroceryStoreLocationDto) => {
        setGroceryLoading(variables.groceryId);
      },
    }
  );

  return {
    removeDone,
    listUpdate,
    addGroceryToList,
    updateLocation,
  };
};

export const useShoppingListData = () => {
  const { error, data, isLoading } = useQuery(
    Shopping.SHOPPING_LIST,
    GroceriesController.shoppingList
  );

  const { mutate: removeDone } = useMutation(
    () => {
      //optimistic update to set loading
      return GroceriesController.removeDone();
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(Shopping.SHOPPING_LIST);
      },
    }
  );

  return {
    shoppingListData: data
      ? data?.data
      : {
          shoppingList: [],
          stores: [],
        },
    busy: isLoading,
    removeDone,
  };
};

function setGroceryLoading(grocId: number) {
  queryClient.setQueryData<ApiResult<ShoppingListViewModel>>(
    Shopping.SHOPPING_LIST,
    (old) => {
      debugger;
      return {
        ...old,
        data: {
          ...old.data,
          shoppingList: old.data.shoppingList.map((grocery) => {
            if (grocery.groceryId === grocId) {
              return {
                ...grocery,
                isSaving: true,
              };
            } else {
              return grocery;
            }
          }),
        },
      };
    }
  );
}

function getGroceryToAdd(grocery: GroceryDto | string): GroceryDto {
  if (typeof grocery === "string") {
    return {
      name: grocery,
    };
  } else {
    return {
      name: grocery.name,
      groceryId: grocery.groceryId,
    };
  }
}
