import * as React from "react";
import { Orientation, JustifyContent, Alignment, Gap } from "../Enums";
import cn from "classnames";
type FlexContainerProps = {
  orientation?: Orientation;
  alignment?: Alignment;
  className?: string;
  gap?: Gap;
  justifyContent?: JustifyContent;
} & React.HTMLAttributes<HTMLDivElement>;

export const FlexContainer: React.FC<FlexContainerProps> = ({
  orientation,
  alignment,
  className,
  justifyContent,
  gap,
  ...props
}) => {
  return (
    <div
      className={cn(orientation, alignment, className, justifyContent, gap)}
      {...props}
    >
      {props.children}
    </div>
  );
};

export default FlexContainer;
