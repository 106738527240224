import React from "react";
import { PlannedFoodDto } from "../../models/PlannedFoodDto";
import { getPlanType } from "../util";
import { PlanType } from "../plan/CreatePlan";
import { Label } from "semantic-ui-react";
import { PlannedRecipeDto } from "../../models/PlannedRecipeDto";
import { useRecipes } from "../recipes/useRecipes";
import { useAvailableGroceries } from "../groceries/useAvailableGroceries";
export const DayServingsDisplay: React.FC<{
  day: PlannedRecipeDto | PlannedFoodDto;
}> = ({ day }) => {
  const { groceries } = useAvailableGroceries();
  const { recipes } = useRecipes();
  let name: string;
  const type = getPlanType(day);
  // let qty;
  if (type === PlanType.Recipe) {
    const convertedRecipe = day as PlannedRecipeDto;
    name = convertedRecipe.recipeName;
    // qty = `${(day as PlannedRecipeDto).servings}`;
    const matchingRecipe = recipes.find(
      (r) => r.recipeId === convertedRecipe.recipeId
    );
    const labelDisplay = convertedRecipe.countServings
      ? `${convertedRecipe.multiplier} servings`
      : null;

    return (
      <span>
        {name}
        {labelDisplay && (
          <React.Fragment>
            {" -"}
            <Label>{labelDisplay}</Label>
          </React.Fragment>
        )}
        <Label>{matchingRecipe?.mealType}</Label>
      </span>
    );
  }

  if (type === PlanType.Food) {
    const convertedDay = day as PlannedFoodDto;
    const matchingGrocery = groceries.find(
      (g) => g.groceryId === convertedDay.groceryId
    );
    name = convertedDay.groceryName;
    let qty = `${convertedDay.shoppingQuantity}`;
    const shoppingDisplay =
      convertedDay.shoppingQuantity && convertedDay.shoppingUnit
        ? `${convertedDay.shoppingQuantity} ${convertedDay.shoppingUnit}`
        : null;
    const labelDisplay = convertedDay.countServings
      ? `${qty} qty`
      : shoppingDisplay;

    return (
      <span>
        {name}
        {labelDisplay && (
          <React.Fragment>
            {" -"}
            <Label>{labelDisplay}</Label>
          </React.Fragment>
        )}
        <Label>{matchingGrocery?.mealType}</Label>
      </span>
    );
  }
};
