import React from "react";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import { Modal, Form, Button, Select } from "semantic-ui-react";
import { ToDoListTemplateDto } from "../../models/ToDoListTemplateDto";
import { convertToDate } from "../../util";

import { useTemplates } from "./useTemplates";

type SelectTemplateModalProps = {
  show: boolean;
  isMutating: boolean;
  onClose: () => void;
  onSubmit: (template: ToDoListTemplateDto) => void;
};

export function SelectTemplateModal(props: SelectTemplateModalProps) {
  const { isLoading, templates } = useTemplates();

  const [selectedTemplate, setSelectedTemplate] = React.useState(null);
  const [dueDate, setDueDate] = React.useState(null);
  return (
    <Modal size={"small"} open={props.show} onClose={props.onClose} closeIcon>
      <Modal.Header>Select Template</Modal.Header>
      <Modal.Content>
        <Form loading={isLoading || props.isMutating}>
          <Form.Field>
            <Select
              placeholder="Select a template"
              onChange={(e, { value }) => {
                setSelectedTemplate(value);
              }}
              options={templates.map((t) => {
                return {
                  key: t.toDoListTemplateId,
                  text: `${t.listName} - ${t.toDos?.length || 0}`,
                  value: t.toDoListTemplateId,
                };
              })}
            ></Select>
          </Form.Field>
          <Form.Field className="text-align-center">
            <label>Due Date</label>
            <SemanticDatepicker
              name="startDate"
              type="basic"
              value={dueDate}
              pointing="right"
              onChange={(event, data) => {
                setDueDate(convertToDate(data.value));
              }}
            />
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions
        className={"horizontal-flex-container justify-space-between"}
      >
        <Button negative onClick={() => props.onClose()}>
          Cancel
        </Button>
        <Button
          positive
          disabled={!selectedTemplate}
          onClick={() => {
            const template = templates.find(
              (t) => t.toDoListTemplateId === selectedTemplate
            );
            props.onSubmit({
              ...template,
              ...(dueDate && { dueDate }),
            });
          }}
        >
          Add
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
