import axios from "axios";
import { jwtKey } from "./constants/magicStrings";
export const initalizeAxiosInterceptors = () => {
  axios.defaults.baseURL = "/api";
  // const responseBody = (response: AxiosResponse) => response.data;

  axios.interceptors.request.use(
    (config) => {
      const token = window.localStorage.getItem(jwtKey);
      if (token) config.headers.Authorization = `Bearer ${token}`;
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(undefined, (error) => {
    if (error.message === "Network Error" && !error.response) {
      console.log("Network error - make sure API is running!");
    }
    const { status, data, config } = error.response;
    if (status === 404) {
      // history.push('/notfound')
    }
    if (
      status === 400 &&
      config.method === "get" &&
      data.errors.hasOwnProperty("id")
    ) {
      // history.push('/notfound')
    }
    if (status === 500) {
      // toast.error('Server error - check the terminal for more info!')
    }
    throw error.response;
  });
};

const TokenService = {
  saveToken: (token: string): void =>
    window.localStorage.setItem(jwtKey, token),
  logout: (): void => window.localStorage.removeItem(jwtKey),
};

export { TokenService };
