import React from "react";
import {
  Header,
  Container,
  Segment,
  Button,
  Form,
  Message,
  Modal,
} from "semantic-ui-react";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import { addDays, differenceInDays } from "date-fns/esm";
import { Formik } from "formik";
import { nameof } from "../../util";
interface NewPlanFormData {
  startDate: Date;
  endDate: Date;
  skipMeals?: number;
  servingsNeeded?: number;
}

interface NewPlanFormProps {
  submitForm(form: NewPlanFormData): void;
  show: boolean;
  onClose: () => void;
}

export const NewPlanForm: React.FC<NewPlanFormProps> = ({
  submitForm,
  show,
  onClose,
}) => {
  const initialValues: NewPlanFormData = {
    startDate: new Date(),
    endDate: addDays(new Date(), 7),
    servingsNeeded: 16,
  };
  return (
    <Modal open={show} onClose={() => onClose()}>
      <Modal.Header attached={"top"} as="h4" block>
        New Plan
      </Modal.Header>
      <Formik
        initialValues={initialValues}
        validate={(values) => {
          let errors: any = {};
          if (!values.endDate) {
            errors.endDate = "End date is required";
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          submitForm(values);
        }}
      >
        {({
          setFieldValue,
          isValid,
          errors,
          values,
          isSubmitting,
          handleSubmit,
          setFieldTouched,
          getFieldMeta,
        }) => (
          <Segment attached={"bottom"} loading={isSubmitting}>
            <Form>
              <Form.Group widths="equal">
                <Form.Field>
                  <label>Start Date (including):</label>
                  <SemanticDatepicker
                    name="startDate"
                    type="basic"
                    value={values.startDate}
                    pointing="right"
                    onChange={(event, data) => {
                      setFieldValue("startDate", data.value);
                      const { touched } = getFieldMeta("servingsNeeded");
                      if (!touched) {
                        const servings =
                          (differenceInDays(values.endDate, values.startDate) +
                            1) *
                          2;
                        setFieldValue("servingsNeeded", servings);
                      }
                    }}
                  />
                </Form.Field>
                <Form.Field>
                  <label>End Date</label>
                  <SemanticDatepicker
                    name="endDate"
                    type="basic"
                    value={values.endDate}
                    pointing="right"
                    onChange={(event, data) =>
                      setFieldValue("endDate", data.value)
                    }
                  />
                </Form.Field>
              </Form.Group>
              <Form.Field>
                <Form.Input
                  name="servingsNeeded"
                  type="number"
                  value={values.servingsNeeded}
                  onChange={(e, data) => {
                    setFieldTouched(
                      nameof<NewPlanFormData>("servingsNeeded"),
                      true
                    );
                    setFieldValue("servingsNeeded", data.value);
                  }}
                  label="Servings Needed"
                  width={"4"}
                ></Form.Input>
                {errors.endDate && (
                  <Message negative>
                    <p>{errors.endDate}</p>
                  </Message>
                )}
              </Form.Field>
              {isValid && (
                <Message info>
                  Servings Needed (2 per day):
                  {Math.max(
                    (differenceInDays(values.endDate, values.startDate) + 1) *
                      2 -
                      (values.skipMeals || 0),
                    0
                  )}
                </Message>
              )}
              <Button
                load={isSubmitting}
                attached={"bottom"}
                type="submit"
                onClick={() => handleSubmit()}
                disabled={!isValid}
                positive
              >
                Ok
              </Button>
            </Form>
          </Segment>
        )}
      </Formik>
    </Modal>
  );
};
