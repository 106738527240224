import { template } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import {
  Header,
  Button,
  Segment,
  Table,
  Responsive,
  Icon,
} from "semantic-ui-react";
import { ClickableText } from "../../components/ClickableText";
import FlexChild from "../../components/containers/FlexChild";
import FlexContainer from "../../components/containers/FlexContainer";
import {
  Orientation,
  Gap,
  Alignment,
  PushDirection,
  JustifyContent,
} from "../../components/Enums";
import { routes } from "../../constants/routes";
import { ActiveToDoDto } from "../../models/ActiveToDoDto";
import { ToDoListTemplateDto } from "../../models/ToDoListTemplateDto";
import { ToDoTemplateDto } from "../../models/ToDoTemplateDto";
import { useFmgToast } from "../common/toast.hook";
import { AddToDoModal } from "./AddToDoModal";
import { TemplateOptionsModal } from "./TemplateOptionsModal";
import { useTemplate } from "./useTemplates";

type RouteProps = {
  templateId: string;
};

export const TemplateDetailsPage = (props: RouteComponentProps<RouteProps>) => {
  const { templateId } = props.match.params;
  const keepModalOpen = useRef(false);
  const history = useHistory();
  const {
    details,
    isLoading,
    isMutating,
    removeFromTemplate,
    upsertToDoTemplate,
    mutationSuccess,
    deleteTemplate,
  } = useTemplate(Number(templateId));
  const [showOptionsModal, setShowOptionsModal] = useState(false);
  const [showAddModal, setShowAddModal] = React.useState<{
    show: boolean;
    editTodo?: ToDoListTemplateDto | null;
  }>({
    show: false,
    editTodo: null,
  });
  const { addSuccess, addWarning } = useFmgToast();

  useEffect(() => {
    if (mutationSuccess && keepModalOpen.current === false) {
      setShowAddModal({ show: false });
    }

    if (mutationSuccess) {
      addSuccess("Template updated");
    }
  }, [addSuccess, mutationSuccess]);

  const remove = async (dto: ToDoTemplateDto) => {
    await removeFromTemplate(dto);
    addSuccess("Removed Todo");
  };

  const update = async (dto: ToDoListTemplateDto, keepOpen: boolean) => {
    keepModalOpen.current = keepOpen;
    await upsertToDoTemplate({
      ...dto,
      toDoListTemplateId: Number(templateId),
    });
  };

  return (
    <div>
      {showOptionsModal && (
        <TemplateOptionsModal
          show={showOptionsModal}
          onClose={() => setShowOptionsModal(false)}
          busy={isMutating}
          onDelete={async () => {
            await deleteTemplate({
              toDoListTemplateId: details.toDoListTemplateId,
            });
            addWarning("Deleted template");
            history.push(routes.todoTemplates);
          }}
        ></TemplateOptionsModal>
      )}
      {showAddModal.show && (
        <AddToDoModal
          busy={isMutating}
          show={showAddModal.show}
          editToDo={showAddModal.editTodo}
          onDelete={async (editTodo: ActiveToDoDto) => {
            await remove(editTodo);
          }}
          onSubmit={async (newToDo: ActiveToDoDto, keepOpen) => {
            await update(newToDo, keepOpen);
          }}
          onClose={() => setShowAddModal({ show: false })}
        ></AddToDoModal>
      )}
      <Header attached="top" as="h4" block>
        <FlexContainer
          orientation={Orientation.Horizontal}
          gap={Gap.Small}
          alignment={Alignment.Center}
        >
          <span>Template:</span>
          <ClickableText onClick={() => setShowOptionsModal(true)}>
            {details?.listName}
          </ClickableText>
          <FlexChild pushDirection={PushDirection.Right}>
            <FlexContainer orientation={Orientation.Horizontal}>
              <Button.Group positive>
                <Button onClick={() => setShowAddModal({ show: true })}>
                  Add
                </Button>
              </Button.Group>
            </FlexContainer>
          </FlexChild>
        </FlexContainer>
      </Header>
      <Segment
        loading={isLoading || isMutating}
        attached="bottom"
        className="table-container"
      >
        <Table compact striped id="mainTable" unstackable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell textAlign="center" width={2}>
                Move
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="center" width={2}>
                Delete
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {details?.toDos
              ?.sort((a, b) => (a.order > b.order ? 1 : -1))
              .map((todoTemplate) => (
                <Responsive
                  as={Table.Row}
                  key={todoTemplate.toDoTemplateId}
                  className="check-element"
                >
                  <Table.Cell>
                    <ClickableText
                      onClick={() => {
                        setShowAddModal({ show: true, editTodo: todoTemplate });
                      }}
                    >
                      {todoTemplate.toDo?.text}
                    </ClickableText>
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    <FlexContainer
                      orientation={Orientation.Horizontal}
                      justifyContent={JustifyContent.JustifyCenter}
                    >
                      <Button
                        disabled={isMutating}
                        size={"mini"}
                        onClick={() => {
                          upsertToDoTemplate({
                            ...todoTemplate,
                            toDoListTemplateId: todoTemplate.toDoListTemplateId,
                            order: todoTemplate.order - 1,
                          });
                        }}
                        icon={<Icon name="arrow up" />}
                      />
                      <Button
                        size={"mini"}
                        disabled={isMutating}
                        onClick={() => {
                          upsertToDoTemplate({
                            ...todoTemplate,
                            toDoListTemplateId: todoTemplate.toDoListTemplateId,
                            order: todoTemplate.order + 1,
                          });
                        }}
                        icon={<Icon name="arrow down" />}
                      />
                    </FlexContainer>
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    <Button
                      icon
                      negative
                      disabled={isLoading}
                      size={"tiny"}
                      onClick={() => remove(todoTemplate)}
                    >
                      <Icon name="trash"></Icon>
                    </Button>
                  </Table.Cell>
                </Responsive>
              ))}
          </Table.Body>
        </Table>
      </Segment>
    </div>
  );
};
