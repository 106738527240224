import React from "react";
import "./App.css";
import { LoggedInNavBar, LoggedOutNavBar } from "./features/nav/NavBar";
import { UserProvider, UserConsumer } from "./UserProvider";
import { LoginForm } from "./features/login/LoginForm";
import { RegisterForm } from "./features/register/RegisterForm";
import { Route, Switch } from "react-router-dom";
import { HomePage } from "./features/home/HomePage";
import { RecipeDashboard } from "./features/recipes/RecipeDashboard";
import { Stores } from "./features/stores/Stores";
import { FmgBody } from "./components/containers/FmgBody";
import { TestPage } from "./features/test/TestPage";
import { ToastProvider } from "react-toast-notifications";
import RecipeDetailsPage from "./features/recipes/RecipeDetailsPage";
import { StoreDetails } from "./features/stores/StoreDetails";
import { GroceryDetails } from "./features/groceries/GroceryDetails";
import { ScrollToTop } from "./components/utils/ScrollToTop";
import { StoreProvider } from "./features/stores/StoreProvider";
import { MealPlanDashboard } from "./features/plan/PlanDashboard";
import { MealPlanDetailsPage } from "./features/plan/PlanDetailsPage";
import { GroceryDashboard } from "./features/groceries/GroceryDashboard";
import { ToDoListPage } from "./features/todos/ToDoListPage";
import { ToDoDashboard } from "./features/todos/ToDoDashboard";
import { routes } from "./constants/routes";
import { TemplateDetailsPage } from "./features/todos/TemplateDetailsPage";
import { TemplateDashboard } from "./features/todos/TemplateDashboard";
export const App = () => {
  return (
    <ToastProvider autoDismiss={true}>
      <UserProvider>
        <UserConsumer>
          {(userInfo) =>
            userInfo?.isBusy ? null : userInfo?.data.token ? (
              <StoreProvider>
                <header>
                  <LoggedInNavBar
                    userInfo={userInfo?.data}
                    logout={userInfo.operations?.logout}
                  ></LoggedInNavBar>
                </header>
                <FmgBody>
                  <Switch>
                    <Route
                      exact
                      path={["/login", "/"]}
                      component={HomePage}
                    ></Route>
                    <Route exact path="/stores" component={Stores}></Route>
                    <Route
                      exact
                      path="/recipes"
                      component={RecipeDashboard}
                    ></Route>
                    <Route
                      path="/recipes/:recipeId"
                      component={RecipeDetailsPage}
                    ></Route>
                    <Route
                      exact
                      path="/plans"
                      component={MealPlanDashboard}
                    ></Route>
                    <Route
                      path="/plans/:mealPlanId"
                      component={MealPlanDetailsPage}
                    ></Route>
                    <Route
                      path="/stores/:storeId"
                      component={StoreDetails}
                    ></Route>
                    <Route path="/test" component={TestPage}></Route>
                    <Route
                      path="/groceries/:groceryId"
                      component={GroceryDetails}
                    ></Route>
                    <Route
                      path="/groceries"
                      component={GroceryDashboard}
                    ></Route>
                    <Route
                      path="/groceries"
                      component={GroceryDashboard}
                    ></Route>
                    <Route
                      path="/todos/lists/:listId"
                      component={ToDoListPage}
                    ></Route>
                    <Route path="/todos" component={ToDoDashboard}></Route>
                    <Route
                      path={routes.todoTemplate.route}
                      component={TemplateDetailsPage}
                    ></Route>
                    <Route
                      path={routes.todoTemplates}
                      component={TemplateDashboard}
                    ></Route>
                  </Switch>
                </FmgBody>
              </StoreProvider>
            ) : (
              <React.Fragment>
                <header>
                  <LoggedOutNavBar></LoggedOutNavBar>
                </header>
                <FmgBody>
                  <Route
                    exact
                    path={["/login", "/"]}
                    component={LoginForm}
                  ></Route>
                  <Route
                    exact
                    path="/register"
                    component={RegisterForm}
                  ></Route>
                </FmgBody>
              </React.Fragment>
            )
          }
        </UserConsumer>
      </UserProvider>
    </ToastProvider>
  );
};

export default App;
