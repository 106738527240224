import * as React from "react";
import { Checkbox } from "semantic-ui-react";
interface BigCheckboxProps {
  isChecked: boolean;
  className?: string;
  label?: string;
  handlechange?(event: React.FormEvent<HTMLInputElement>): void;
  [key: string]: any;
}

export const BigCheckbox = (props: BigCheckboxProps) => {
  const { isChecked, handlechange, className, ...rest } = props;
  return (
    <Checkbox
      type={"checkbox"}
      checked={isChecked}
      onChange={handlechange}
      className={className ? className : "big-checkbox"}
      {...rest}
    ></Checkbox>
  );
};

export default BigCheckbox;
