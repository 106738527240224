import * as React from "react";
import { useMutation, useQuery } from "react-query";
import { queryClient } from "../..";
import { RECIPES } from "../../constants/magicStrings";
import { IngredientDTO } from "../../models/IngredientDTO";
import { RecipeDto } from "../../models/RecipeDto";
import { RecipesController } from "../../models/RecipesController";
import { AddRecipeForm } from "./AddRecipeModal";

export const useRecipes = () => {
  const { isLoading, data } = useQuery(RECIPES.RECIPES, RecipesController.all);

  const {
    mutate: modifyRecipeDetails,
    data: mutationData,
    status: mutationStatus,
    isLoading: mutationIsLoading,
  } = useMutation(
    (updatedRecipe: RecipeDto) => {
      return RecipesController.modifyRecipeAttribute(updatedRecipe);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(RECIPES.RECIPES);
      },
    }
  );

  const {
    mutate: addRecipe,
    isLoading: addIsLoading,
    status: addStatus,
  } = useMutation(
    (recipeForm: AddRecipeForm) => {
      return RecipesController.createRecipe(recipeForm);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(RECIPES.RECIPES);
      },
    }
  );

  const { mutate: saveAllSteps, status: saveAllStatus } = useMutation(
    (steps: RecipeDto[]) => {
      return RecipesController.saveAllSteps(steps);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(RECIPES.RECIPES);
      },
    }
  );

  const { mutate: deleteIngredient, status: deleteIngredStatus } = useMutation(
    (ingredient: IngredientDTO) => {
      return RecipesController.deleteIngredient(ingredient);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(RECIPES.RECIPES);
      },
    }
  );

  const { mutate: upsertIngredient, status: upsertIngredientStatus } =
    useMutation(
      (ingredient: IngredientDTO) => {
        return RecipesController.upsertIngredient(ingredient);
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(RECIPES.RECIPES);
        },
      }
    );

  return {
    recipes: data ? data.data : [],
    loading: isLoading,
    isModifying: mutationIsLoading,
    isAdding: addIsLoading,
    modifyRecipeDetails: modifyRecipeDetails,
    addRecipe: addRecipe,
    saveAllSteps,
    deleteIngredient,
    upsertIngredient,
  };
};
