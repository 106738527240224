import { useState, useEffect, useContext } from "react";
import { ActiveGroceryDto } from "../../models/ActiveGroceryDto";
import { StoreDto } from "../../models/StoreDto";
import { sortGroceryList } from "../../sorting";
import { GroceryDto } from "../../models/GroceryDto";
import { useShoppingListData } from "./useShoppingListData";
import { useAvailableGroceries } from "../groceries/useAvailableGroceries";

type ShoppingList = {
  allItems: Array<ActiveGroceryDto>;
  availableStores: Array<StoreDto>;
  selectedStore?: StoreDto;
  visibleList: Array<ActiveGroceryDto>;
  setSelectedStore: (store: StoreDto) => void;
};

const initialShoppingList: ShoppingList = {
  availableStores: [],
  allItems: [],
  visibleList: [],
  selectedStore: null,
  setSelectedStore: null,
};

type ShoppingListHook = ShoppingList & { busy: boolean };

export const useVisibleShoppingList = (showDone: boolean): ShoppingListHook => {
  const { busy, shoppingListData } = useShoppingListData();
  const [selectedStore, setSelectedStore] = useState<StoreDto>(null);
  if (!shoppingListData) {
    return {
      busy,
      ...initialShoppingList,
    };
  }

  if (shoppingListData.stores.length && selectedStore === null) {
    const storeToPick =
      shoppingListData.stores.find((s) => s.isDefault) ||
      shoppingListData.stores[0];
    setSelectedStore(storeToPick);
  }

  const sortedList = sortGroceryList(
    selectedStore?.storeId,
    showDone
      ? shoppingListData.shoppingList
      : shoppingListData.shoppingList.filter((s) => s.isChecked === false),
    shoppingListData.stores
  );

  return {
    busy,
    allItems: shoppingListData.shoppingList,
    availableStores: shoppingListData.stores,
    selectedStore: selectedStore,
    setSelectedStore: setSelectedStore,
    visibleList: sortedList,
  };
};

export const useGrocerySelection = () => {
  const [groceryFreetext, setGroceryFreetext] = useState("");
  const [selectedGrocery, setSelectedGrocery] = useState<GroceryDto>(null);
  useEffect(() => {
    setSelectedGrocery(null);
  }, [groceryFreetext]);

  const { groceries: availableGroceries } = useAvailableGroceries();

  return {
    groceryFreetext,
    setGroceryFreetext,
    selectedGrocery,
    setSelectedGrocery,
    availableGroceries,
  };
};
