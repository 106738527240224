import React, { useState } from "react";
import FlexContainer from "./containers/FlexContainer";
import FlexChild from "./containers/FlexChild";
import { Orientation, PushDirection } from "./Enums";
import { Checkbox, Input } from "semantic-ui-react";

type DetailsHeaderProps = {
  editMode: boolean;
  onEdit: () => void;
  headerName: string;
  onHeaderChange?: (newVal: string) => void;
};

export function DetailsPageHeader(props: DetailsHeaderProps) {
  const { editMode, onHeaderChange } = props;
  return (
    <FlexContainer orientation={Orientation.Horizontal}>
      <FlexChild pushDirection={PushDirection.Left}>
        {!editMode ? (
          <h3>{props.headerName}</h3>
        ) : (
          <Input
            value={props.headerName}
            onChange={(e) => onHeaderChange(e.currentTarget.value)}
          />
        )}
      </FlexChild>
      <FlexChild pushDirection={PushDirection.Right}>
        <Checkbox
          checked={props.editMode}
          onChange={() => props.onEdit()}
          toggle
          label={"Edit"}
        ></Checkbox>
      </FlexChild>
    </FlexContainer>
  );
}
