import { useContext, useState, useEffect, useRef } from "react";
import { PlannedRecipeDto } from "../../models/PlannedRecipeDto";
import { IngredientDTO } from "../../models/IngredientDTO";
import { AddedIngredientDto } from "../../models/AddedIngredientDto";
import { RecipeDto } from "../../models/RecipeDto";
import { useRecipes } from "../recipes/useRecipes";

export const usePlannedRecipeAssignment = (
  alreadyPlannedRecipe: PlannedRecipeDto
) => {
  const originalRecipe = useRef<RecipeDto>(null);
  const { recipes } = useRecipes();
  const [plannedRecipe, setPlannedRecipe] =
    useState<PlannedRecipeDto>(alreadyPlannedRecipe);

  const [multiplier, setMultiplier] = useState(
    alreadyPlannedRecipe?.multiplier || 0
  );

  const [countServingsChecked, setCountServingsChecked] = useState(
    alreadyPlannedRecipe?.countServings || false
  );

  const initialIngredients: Array<IngredientDTO> = getInitialIngredients(
    alreadyPlannedRecipe,
    recipes
  );

  const [ingredients, setIngredients] =
    useState<Array<IngredientDTO>>(initialIngredients);

  useEffect(() => {
    if (originalRecipe.current) {
      setIngredients(originalRecipe.current.ingredients);
    }
  }, [plannedRecipe.recipeId]);

  useEffect(() => {
    if (originalRecipe.current) {
      const multipliedIngredients = ingredients?.map((ingred) => {
        const origIngredient = originalRecipe.current.ingredients?.find(
          (i) => i.ingredientId === ingred.ingredientId
        );

        const newQty = origIngredient?.shoppingQuantity * multiplier;
        return {
          ...ingred,
          shoppingQuantity: newQty,
        };
      });
      debugger;
      setIngredients(multipliedIngredients);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [multiplier]);

  const selectedRecipeChanged = (selectedRecipe: RecipeDto) => {
    originalRecipe.current = selectedRecipe;
    const newRecipe: PlannedRecipeDto = {
      recipeId: selectedRecipe.recipeId,
      recipeName: selectedRecipe.recipeName,
      plannedDay: plannedRecipe.plannedDay,
    };
    setPlannedRecipe(newRecipe);
  };

  return {
    recipes,
    plannedRecipe,
    ingredients,
    multiplier,
    setMultiplier,
    setIngredients,
    setCountServingsChecked,
    countServingsChecked,
    recipeDetails: originalRecipe,
    selectedRecipeChanged: selectedRecipeChanged,
  };
};

export function converteToAddedIngredientArray(
  ingredients: Array<IngredientDTO>
): Array<AddedIngredientDto> {
  return ingredients
    ?.filter((i) => i.addToShoppingList)
    .map((i) => {
      const addedIngred: AddedIngredientDto = {
        ingredientName: i.ingredientName,
        shoppingQuantity: i.shoppingQuantity,
        shoppingUnit: i.shoppingUnit,
      };
      return addedIngred;
    });
}

function convertToIngredientArray(
  addedIngredients: Array<AddedIngredientDto>
): Array<IngredientDTO> {
  return addedIngredients?.map((added) => {
    return convertToIngredientDto(added);
  });
}

function convertToIngredientDto(
  addedIngredient: AddedIngredientDto
): IngredientDTO {
  return {
    ...addedIngredient,
    addToShoppingList: true,
  };
}

function getInitialIngredients(
  alreadyPlannedRecipe: PlannedRecipeDto,
  recipes: Array<RecipeDto>
): Array<IngredientDTO> {
  if (!alreadyPlannedRecipe || !alreadyPlannedRecipe.plannedRecipeId) {
    return [];
  }

  const matchingRecipe = recipes.find(
    (r) => r.recipeId === alreadyPlannedRecipe.recipeId
  );
  const addedIngredNames = alreadyPlannedRecipe?.addedIngredients?.map(
    (i) => i.ingredientName
  );

  const ingredsNotAdded: Array<IngredientDTO> = matchingRecipe.ingredients
    ?.filter((i) => !addedIngredNames.includes(i.ingredientName))
    .map((unchecker) => {
      return {
        ...unchecker,
        addToShoppingList: false,
      };
    });

  const convertedAdded = convertToIngredientArray(
    alreadyPlannedRecipe?.addedIngredients
  );

  return [...(convertedAdded || []), ...(ingredsNotAdded || [])];
}
