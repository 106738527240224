import React, { useContext, useState, useEffect, useRef } from "react";
import { StoreContext } from "./StoreProvider";
import { PageContainer } from "../../components/containers/PageContainer";
import {
  Header,
  Segment,
  Loader,
  Item,
  Ref,
  Button,
  Icon,
  Checkbox,
  Grid,
  Divider,
} from "semantic-ui-react";
import { StoreDropdown } from "../home/StoreDropdown";
import { StoreDto } from "../../models/StoreDto";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";
import { StoreLocationDto } from "../../models/StoreLocationDto";
import FlexContainer from "../../components/containers/FlexContainer";
import { Orientation, PushDirection, Alignment } from "../../components/Enums";
import FlexChild from "../../components/containers/FlexChild";
import { isEqual } from "lodash";
import nanoid from "nanoid";
import { StoresController } from "../../models/StoresController";
import { useToasts } from "react-toast-notifications";
import { NewStoreModal } from "./NewStoreModal";

export const Stores = () => {
  const initialized = useRef(false);
  const [loading, setLoading] = useState(false);
  const storeContext = useContext(StoreContext);
  const cleanStore = useRef<StoreDto>(null);
  const [isDirty, setDirty] = useState(false);
  const { addToast } = useToasts();
  const [editMode, setEditMode] = useState(false);
  const [showNewModal, setShowNewModal] = useState(false);

  const { stores, upsertStore } = storeContext;
  const [selectedStore, setSelectedStore] = useState<StoreDto>();
  useEffect(() => {
    if (!selectedStore) {
      const storeToSet = stores.find((s) => s.isDefault) || stores[0];
      if (storeToSet) {
        setDirty(false);
        setSelectedStore(storeToSet);
        initialized.current = true;
      }
    }
  }, [selectedStore, stores, stores.length]);

  useEffect(() => {
    if (cleanStore.current?.storeId !== selectedStore?.storeId) {
      cleanStore.current = selectedStore;
    }
  }, [selectedStore]);

  useEffect(() => {
    if (
      !isEqual(cleanStore.current, selectedStore) &&
      initialized.current === true &&
      cleanStore.current &&
      selectedStore
    ) {
      setDirty(true);
    } else {
      setDirty(false);
    }
  }, [selectedStore]);

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const { destination, source, draggableId } = result;

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const movedLocation = selectedStore.storeLocations.find(
      (s) =>
        s.storeLocationId === Number(draggableId) ||
        String(s.storeLocationId) === draggableId
    );

    const newLocations: Array<StoreLocationDto> = selectedStore.storeLocations.map(
      (storeLocation) => {
        if (storeLocation.storeLocationId === movedLocation.storeLocationId) {
          return {
            ...storeLocation,
            sequence: destination.index + 1,
          };
        }
        //destination index is sequence - 1
        const thisIndex = storeLocation.sequence - 1;
        //unchanged
        if (
          (source.index > thisIndex && destination.index > thisIndex) ||
          (source.index < thisIndex && destination.index < thisIndex)
        ) {
          return storeLocation;
        }

        //add 1
        if (thisIndex < source.index && thisIndex >= destination.index) {
          return {
            ...storeLocation,
            sequence: storeLocation.sequence + 1,
          };
        }

        //remove 1
        if (thisIndex > source.index && thisIndex <= destination.index) {
          return {
            ...storeLocation,
            sequence: storeLocation.sequence - 1,
          };
        }
        console.error("Shouldnt get here");
        return storeLocation;
      }
    );

    setSelectedStore({
      ...selectedStore,
      storeLocations: newLocations,
    });
  };

  return (
    <div>
      <NewStoreModal
        loading={loading}
        addStore={async (store) => {
          try {
            setLoading(true);
            const result = await StoresController.addStore(store);
            if (result.successInd) {
              setShowNewModal(false);
              setLoading(false);
              addToast(`Added store ${result.data.storeName}`, {
                appearance: "success",
              });
              upsertStore(result.data);
              setSelectedStore(result.data);
            } else {
              throw result.errorMessage;
            }
          } catch (err) {
            addToast(`There was an error adding a store`, {
              appearance: "error",
            });
          } finally {
            setLoading(false);
          }
        }}
        onHide={() => setShowNewModal(false)}
        show={showNewModal}
      ></NewStoreModal>
      <Header attached="top" as="h4" block>
        <FlexContainer
          orientation={Orientation.Horizontal}
          alignment={Alignment.Baseline}
        >
          Stores
          {editMode && (
            <FlexChild>
              <Button
                onClick={() => setShowNewModal(true)}
                positive
                size={"small"}
              >
                <Icon name="add"></Icon> Add
              </Button>
            </FlexChild>
          )}
          <FlexChild pushDirection={PushDirection.Right}>
            <Checkbox
              checked={editMode}
              onChange={() => setEditMode(!editMode)}
              toggle
              label={"Edit"}
            ></Checkbox>
          </FlexChild>
        </FlexContainer>
      </Header>
      <Segment attached="bottom">
        {stores && stores.length ? (
          <>
            {selectedStore && (
              <>
                <Segment attached="bottom">
                  <Grid>
                    <Grid.Row>
                      <Grid.Column mobile={12} computer={6}>
                        <StoreDropdown
                          stores={stores}
                          onChange={(storeId) => {
                            setSelectedStore(
                              stores.find((s) => s.storeId === storeId)
                            );
                          }}
                          selectedStore={selectedStore}
                        ></StoreDropdown>
                      </Grid.Column>
                      <Grid.Column
                        className="store-edit-buttons"
                        mobile={12}
                        computer={6}
                      >
                        {editMode && (
                          <>
                            <Button
                              disabled={!isDirty}
                              onClick={async () => {
                                try {
                                  const removedStringIds = selectedStore.storeLocations.map(
                                    (s) => {
                                      if (isNaN(s.storeLocationId)) {
                                        return {
                                          ...s,
                                          storeLocationId: 0,
                                        };
                                      } else {
                                        return s;
                                      }
                                    }
                                  );
                                  const result = await StoresController.updateStoreLocations(
                                    removedStringIds
                                  );
                                  if (result.successInd) {
                                    addToast(
                                      `Update locations for ${result.data.storeName}`,
                                      {
                                        appearance: "success",
                                      }
                                    );
                                  }
                                  upsertStore(result.data);
                                } catch (err) {
                                  addToast(`There was an error updating`, {
                                    appearance: "error",
                                  });
                                }
                              }}
                              positive
                            >
                              Save
                            </Button>
                            <Button
                              onClick={() => {
                                const newLocations: Array<StoreLocationDto> = selectedStore.storeLocations.map(
                                  (storeLoc) => {
                                    return {
                                      ...storeLoc,
                                      sequence: storeLoc.sequence + 1,
                                    };
                                  }
                                );
                                const blankLocation: StoreLocationDto = {
                                  locationName: "",
                                  locationDescription: "",
                                  sequence: 1,
                                  storeLocationId: (nanoid() as unknown) as number,
                                  storeId: selectedStore.storeId,
                                };
                                newLocations.push(blankLocation);
                                setSelectedStore({
                                  ...selectedStore,
                                  storeLocations: newLocations,
                                });
                              }}
                              positive
                            >
                              Add Location
                            </Button>
                          </>
                        )}
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                  <Divider></Divider>
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId={"locations"}>
                      {(droppable) => (
                        <Ref innerRef={droppable.innerRef}>
                          <Item.Group divided {...droppable.droppableProps}>
                            {selectedStore.storeLocations
                              .sort((a, b) => a.sequence - b.sequence)
                              .map((location, index) => {
                                return (
                                  <LocationDisplay
                                    canEdit={editMode}
                                    onChange={(newLocation) => {
                                      setSelectedStore({
                                        ...selectedStore,
                                        storeLocations: [
                                          ...selectedStore.storeLocations.filter(
                                            (l) =>
                                              l.storeLocationId !==
                                              newLocation.storeLocationId
                                          ),
                                          newLocation,
                                        ],
                                      });
                                    }}
                                    index={index}
                                    key={location.storeLocationId}
                                    location={location}
                                  ></LocationDisplay>
                                );
                              })}
                            {droppable.placeholder}
                          </Item.Group>
                        </Ref>
                      )}
                    </Droppable>
                  </DragDropContext>
                </Segment>
              </>
            )}
          </>
        ) : (
          <Loader></Loader>
        )}
      </Segment>
    </div>
  );
};

type LocationDisplayProps = {
  location: StoreLocationDto;
  index: any;
  canEdit: boolean;
  onChange: (location: StoreLocationDto) => void;
};
const LocationDisplay = (props: LocationDisplayProps) => {
  const { location, index, onChange, canEdit } = props;
  const [isEditing, setIsEditing] = useState(false);
  return (
    <Draggable
      isDragDisabled={!canEdit}
      draggableId={String(location.storeLocationId)}
      index={index}
    >
      {(provided) => (
        <Ref innerRef={provided.innerRef}>
          <Item {...provided.draggableProps}>
            <Item.Content>
              <Item.Header>
                {location.sequence}.{" "}
                {isEditing ? (
                  <input
                    onChange={(e) => {
                      onChange({
                        ...location,
                        locationName: e.target.value,
                      });
                    }}
                    value={location.locationName}
                  ></input>
                ) : (
                  <span>{location.locationName}</span>
                )}{" "}
                {canEdit && (
                  <>
                    <Icon
                      onClick={() => setIsEditing(!isEditing)}
                      name={isEditing ? "x" : "edit"}
                    ></Icon>
                    <Icon {...provided.dragHandleProps} name={"bars"}></Icon>
                  </>
                )}
              </Item.Header>
              <Item.Description>
                {isEditing ? (
                  <textarea
                    onChange={(e) => {
                      onChange({
                        ...location,
                        locationDescription: e.target.value,
                      });
                    }}
                    value={location.locationDescription}
                  ></textarea>
                ) : (
                  <span>{location.locationDescription}</span>
                )}
              </Item.Description>
            </Item.Content>
          </Item>
        </Ref>
      )}
    </Draggable>
  );
};
