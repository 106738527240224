import * as React from "react";
import { PushDirection } from "../Enums";
import classNames from "classnames";
interface FlexChildProps {
  pushDirection?: PushDirection;
  helperClass?: string;
}
class FlexChild extends React.Component<FlexChildProps, any> {
  render() {
    const { children, pushDirection, helperClass } = this.props;
    if (!this.props.pushDirection) {
      return (
        <div className={classNames("horizontal-child", helperClass)}>
          {children}
        </div>
      );
    }

    if (pushDirection === PushDirection.Right) {
      return (
        <div
          className={classNames(
            "horizontal-child flex-push-right",
            helperClass
          )}
        >
          {children}
        </div>
      );
    }

    if (pushDirection === PushDirection.Left) {
      return (
        <div
          className={classNames("horizontal-child flex-push-left", helperClass)}
        >
          {children}
        </div>
      );
    }

    return (
      <div className={classNames("horizontal-child", helperClass)}>
        {children}
      </div>
    );
  }
}

export default FlexChild;
