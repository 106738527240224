import React from "react";
import { RecipeDto } from "../../models/RecipeDto";
import { formatDistance, formatDistanceStrict } from "date-fns";
import {
  Header,
  Segment,
  Input,
  Table,
  Rating,
  Loader,
  Button,
  Icon,
  Responsive,
} from "semantic-ui-react";
import { convertToDate } from "../../util";
import { ClickableText } from "../../components/ClickableText";
import { SortState } from "../home/recipeList.hook";
import FlexContainer from "../../components/containers/FlexContainer";
import { Orientation, PushDirection } from "../../components/Enums";
import FlexChild from "../../components/containers/FlexChild";
import { dayformat } from "../../constants/dateFormatters";
interface QuickRecipeRowProps {
  recipe: RecipeDto;
  recipeClicked: () => void;
  ratingChanged: (newRating) => void;
}

interface TableProps {
  isBusy: boolean;
  onFilterChange: (newFilterValue: string) => void;
  onSort: (column: keyof RecipeDto) => void;
  currentSort: SortState;
  filterValue: string;
  onNewClick?: () => void;
}

export const QuickRecipeTable: React.FC<TableProps> = ({
  isBusy,
  children,
  onFilterChange,
  filterValue,
  currentSort,
  onSort,
  onNewClick,
}) => {
  return (
    <React.Fragment>
      {isBusy && <Loader />}
      <Header attached="top" as="h4" block>
        Recipes
      </Header>
      <Segment attached="bottom">
        <FlexContainer orientation={Orientation.Horizontal}>
          <Input
            placeholder="Filter Recipes"
            value={filterValue}
            onChange={(event) => onFilterChange(event.target.value)}
            type={"text"}
          ></Input>
          {onNewClick && (
            <FlexChild pushDirection={PushDirection.Right}>
              <Button onClick={onNewClick} positive>
                New
              </Button>
            </FlexChild>
          )}
        </FlexContainer>
        <Table color={"green"} sortable unstackable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell
                active
                onClick={() => {
                  onSort("recipeName");
                }}
                sorted={
                  currentSort.columnName === "recipeName"
                    ? currentSort.direction
                    : null
                }
              >
                Name
              </Table.HeaderCell>
              <Table.HeaderCell
                onClick={() => onSort("rating")}
                sorted={
                  currentSort.columnName === "rating"
                    ? currentSort.direction
                    : null
                }
              >
                Rating
              </Table.HeaderCell>
              <Table.HeaderCell
                onClick={() => onSort("lastCooked")}
                sorted={
                  currentSort.columnName === "lastCooked"
                    ? currentSort.direction
                    : null
                }
              >
                Cooked
              </Table.HeaderCell>
              <Table.HeaderCell>
                <Icon name="calendar"></Icon>
                <Icon name="sync"></Icon>
              </Table.HeaderCell>
              <Responsive
                as={Table.HeaderCell}
                minWidth={Responsive.onlyMobile.maxWidth}
              >
                Meal Type
              </Responsive>
            </Table.Row>
          </Table.Header>
          <Table.Body>{children}</Table.Body>
        </Table>
      </Segment>
    </React.Fragment>
  );
};

export const QuickRecipeRow: React.FC<QuickRecipeRowProps> = (props) => {
  const { recipe, recipeClicked, ratingChanged } = props;
  return (
    <Table.Row>
      <Table.Cell>
        <ClickableText onClick={recipeClicked}>
          {recipe.recipeName}
        </ClickableText>
      </Table.Cell>
      <Table.Cell>
        <Rating
          onRate={(_, data) => {
            if (data.rating && data.rating !== recipe.rating) {
              ratingChanged(data.rating);
            }
          }}
          icon={"star"}
          rating={recipe.rating}
          maxRating={3}
          clearable
        />
      </Table.Cell>
      <Table.Cell>
        {recipe.lastCooked
          ? formatDistanceStrict(convertToDate(recipe.lastCooked), new Date())
          : "Never"}
      </Table.Cell>
      <Table.Cell>
        {recipe.autoPlanSettings?.value &&
          `${recipe.autoPlanSettings?.value} ${recipe.autoPlanSettings?.frequency}`}
      </Table.Cell>
      <Responsive as={Table.Cell} minWidth={Responsive.onlyMobile.maxWidth}>
        {recipe.mealType}
      </Responsive>
    </Table.Row>
  );
};
