import * as React from "react";
import { RecipeDto } from "../../models/RecipeDto";
import {
  Message,
  Label,
  Button,
  Checkbox,
  Icon,
  Form,
  Input,
  Loader,
  Segment,
  Image,
  TextArea,
} from "semantic-ui-react";
import Highlighter from "react-highlight-words";
import FlexContainer from "../../components/containers/FlexContainer";
import FlexChild from "../../components/containers/FlexChild";
import { PushDirection, Orientation, Alignment } from "../../components/Enums";
import { useState } from "react";
import { IngredientDTO } from "../../models/IngredientDTO";
import { GrocerySearch } from "../home/GrocerySearch";
import { useGrocerySelection } from "../home/shoppingListHook";
import { ClickableText } from "../../components/ClickableText";
import { Formik, Field } from "formik";
import { FormikSelect } from "../../components/inputs/FormikSelect";
import {
  unitDropdownOptions,
  frequencyDropDownOptions,
  mealTypeDropDownOptions,
} from "../../constants/units";
import { RecipesController } from "../../models/RecipesController";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { PageContainer } from "../../components/containers/PageContainer";
import { RecipeStepDTO } from "../../models/RecipeStepDTO";
import classNames from "classnames";
import { getRecipePhoto, saveRecipePhoto } from "../../services/photoService";
import { ClickableImage } from "../../components/ClickableImage";
import { useFmgToast } from "../common/toast.hook";
import { FormattedParagraph } from "../../components/FormattedParagraph";
import { FrequencyDetailsDisplay } from "../../components/FrequencyDetailsDisplay";
import { useRecipes } from "./useRecipes";
import { queryClient } from "../..";
import { RECIPES } from "../../constants/magicStrings";

type RouteProps = {
  recipeId: string;
};

type ModalOrRoute = RouteComponentProps<RouteProps>;

export const RecipeDetailsPage: React.FC<ModalOrRoute> = (props) => {
  const history = useHistory();
  const {
    recipes,
    modifyRecipeDetails,
    loading,
    saveAllSteps,
    deleteIngredient,
    upsertIngredient,
  } = useRecipes();
  const recipeId = props.match.params.recipeId;
  const matchingRecipe = recipes.find((r) => r.recipeId === Number(recipeId));

  const [recipe, setRecipe] = useState<RecipeDto>(null);

  const [recipeImage, setRecipeImage] = useState(null);
  const [photoUploading, setPhotoUploading] = useState(false);

  //TODO new Recipe modal
  const { availableGroceries } = useGrocerySelection();

  const [selectedIngredient, setSelectedIngred] = useState<IngredientDTO>(null);
  const { addSuccess, addError } = useFmgToast();
  const [editMode, setEditMode] = useState(false);
  React.useEffect(() => {
    if (matchingRecipe?.photoFileName) {
      getRecipePhoto(Number(recipeId)).then((result) => {
        setRecipeImage(result);
      });
    }
  }, [matchingRecipe, recipeId]);

  React.useEffect(() => {
    const matchingRecipe = recipes.find((r) => r.recipeId === Number(recipeId));
    if (matchingRecipe) {
      setRecipe(matchingRecipe);
    }
  }, [recipeId, recipes]);

  if (!matchingRecipe) {
    return <Loader></Loader>;
  }

  return (
    <PageContainer>
      {recipe && (
        <Segment loading={loading}>
          <FlexContainer orientation={Orientation.Horizontal}>
            <FlexChild pushDirection={PushDirection.Left}>
              <h3>{matchingRecipe.recipeName}</h3>
            </FlexChild>
            <FlexChild pushDirection={PushDirection.Right}>
              {editMode && (
                <Button
                  negative
                  onClick={async () => {
                    await RecipesController.deleteRecipe(recipe);
                    addSuccess(`${recipe.recipeName} deleted`);
                    history.push("/recipes");
                  }}
                >
                  Delete
                </Button>
              )}
              <Checkbox
                checked={editMode}
                onChange={() => setEditMode(!editMode)}
                toggle
                label={"Edit"}
              ></Checkbox>
            </FlexChild>
          </FlexContainer>
          <h4>Description</h4>
          {editMode ? (
            <Form>
              <Form.Field>
                <TextArea
                  value={recipe.recipeDescription}
                  onChange={(e) => {
                    setRecipe({
                      ...recipe,
                      recipeDescription: e.currentTarget.value,
                    });
                  }}
                />
              </Form.Field>
              <RightSideSave
                onSave={async () => {
                  await modifyRecipeDetails(recipe);
                  addSuccess("Updated description");
                }}
                content="Save Description"
              />
            </Form>
          ) : (
            <FormattedParagraph>
              {matchingRecipe.recipeDescription}
            </FormattedParagraph>
          )}
          {editMode && (
            <FlexChild pushDirection={PushDirection.Right}>
              <div className={"grocery-search-container"}>
                <GrocerySearch
                  placeholderText={"Add Ingredient"}
                  availableGroceries={availableGroceries}
                  groceryChanged={(grocery) =>
                    setSelectedIngred({
                      ingredientName: grocery.name,
                      recipeId: matchingRecipe.recipeId,
                    })
                  }
                  freetextValueChanged={(textVal) => {
                    setSelectedIngred({
                      ingredientName: textVal,
                      recipeId: matchingRecipe.recipeId,
                    });
                  }}
                ></GrocerySearch>
                <Button
                  onClick={async () => {
                    await upsertIngredient(selectedIngredient);
                    addSuccess(
                      `Added ingredient ${selectedIngredient.ingredientName}`
                    );
                  }}
                  disabled={!selectedIngredient}
                  positive
                >
                  Add
                </Button>
              </div>
            </FlexChild>
          )}
          <Message info>
            <FlexContainer
              orientation={Orientation.Horizontal}
              alignment={Alignment.Baseline}
            >
              <h4>
                Ingredients{" "}
                <Label>
                  {matchingRecipe.ingredients &&
                    matchingRecipe.ingredients.length}
                </Label>
              </h4>
            </FlexContainer>

            <div className="ingredients-container scroll-container">
              {matchingRecipe.ingredients &&
                matchingRecipe.ingredients.map((ingredient) => {
                  return (
                    <IngredientDisplay
                      onSave={async (values) => {
                        const result = await RecipesController.upsertIngredient(
                          values
                        );
                        addSuccess(
                          `Updated ingredient ${
                            result
                              ? result.data.ingredientName
                              : ingredient.ingredientName
                          }`
                        );
                      }}
                      onDelete={async (ingred) => {
                        await deleteIngredient(ingred);

                        addSuccess(
                          `Deleted ingredient ${ingred.ingredientName}`
                        );
                      }}
                      canEdit={editMode}
                      key={ingredient.ingredientId}
                      ingredient={ingredient}
                    ></IngredientDisplay>
                  );
                })}
            </div>
          </Message>
          <StepContainer
            saveAll={async (steps) => {
              await saveAllSteps(steps);
              addSuccess(`Updated steps`);
            }}
            saveOne={async (step) => {
              console.log(step);
              await RecipesController.all();
            }}
            recipe={matchingRecipe}
            editMode={editMode}
          ></StepContainer>
          {editMode ? (
            <Form>
              <h4>Frequency</h4>
              <Form.Field>
                <span>Plan this every </span>
                <Input
                  value={recipe.autoPlanSettings?.value || 0}
                  type="number"
                  onChange={(e) => {
                    setRecipe({
                      ...recipe,
                      autoPlanSettings: {
                        ...recipe.autoPlanSettings,
                        value: Number(e.currentTarget.value),
                      },
                    });
                  }}
                ></Input>
              </Form.Field>
              <Form.Field>
                <Form.Select
                  options={frequencyDropDownOptions}
                  value={recipe?.autoPlanSettings?.frequency}
                  onChange={(_, val) => {
                    setRecipe({
                      ...recipe,
                      autoPlanSettings: {
                        ...recipe.autoPlanSettings,
                        frequency: String(val.value),
                      },
                    });
                  }}
                />
              </Form.Field>
              <Form.Field>
                <h4>Meal Type</h4>
                <Form.Select
                  value={recipe.mealType}
                  onChange={(_, val) => {
                    setRecipe({
                      ...recipe,
                      mealType: String(val.value),
                    });
                  }}
                  options={mealTypeDropDownOptions}
                ></Form.Select>
              </Form.Field>
              <RightSideSave
                onSave={async () => {
                  await modifyRecipeDetails(recipe);
                  addSuccess("Updated Frequency & Meal type");
                }}
                content="Save Frequency"
              ></RightSideSave>
            </Form>
          ) : (
            <FrequencyDetailsDisplay
              settings={recipe.autoPlanSettings}
              mealType={recipe.mealType}
            ></FrequencyDetailsDisplay>
          )}
          {recipeImage && !editMode && (
            <ClickableImage
              src={recipeImage}
              alt="recipeImage"
            ></ClickableImage>
          )}
          {editMode && (
            <RecipePhotoUpload
              loading={photoUploading}
              onSave={async (file) => {
                setPhotoUploading(true);
                const formData = new FormData();
                formData.append("recipeId", recipeId);
                formData.append("file", file);

                try {
                  await saveRecipePhoto(formData);
                  addSuccess(`Saved photo successfully`);
                  queryClient.invalidateQueries(RECIPES.RECIPES);
                } catch (err) {
                  addError(`Error uploading photo`);
                } finally {
                  setPhotoUploading(false);
                }
              }}
            />
          )}
        </Segment>
      )}
    </PageContainer>
  );
};

type PhotoUploadProps = {
  onSave: (file: File) => void;
  loading: boolean;
};
const RecipePhotoUpload: React.FC<PhotoUploadProps> = ({ onSave, loading }) => {
  const [image, setImage] = useState({
    file: null,
    preview: null,
  });

  return (
    <div>
      <h4>Photo</h4>
      <Input
        type="file"
        accept="image/*"
        onChange={(event) => {
          const firstFile = event.target?.files[0];
          if (firstFile) {
            setImage({
              file: firstFile,
              preview: URL.createObjectURL(firstFile),
            });
          } else {
            setImage({
              file: null,
              preview: null,
            });
          }
        }}
      ></Input>
      <FlexContainer orientation={Orientation.Horizontal}>
        <FlexChild pushDirection={PushDirection.Right}>
          <Button
            loading={loading}
            onClick={() => {
              onSave(image.file);
            }}
            positive
            disabled={!image.file}
          >
            Upload
          </Button>
        </FlexChild>
      </FlexContainer>
      <h5>Preview:</h5>
      {image.preview && (
        <Image fluid src={image.preview} alt="recipePreview"></Image>
      )}
    </div>
  );
};

type StepContainerProps = {
  recipe: RecipeDto;
  editMode: boolean;
  saveAll: (steps: Array<RecipeStepDTO>) => Promise<void>;
  saveOne: (step: RecipeStepDTO) => Promise<void>;
};

export const StepContainer = (props: StepContainerProps) => {
  const { recipe, editMode } = props;
  const [steps, setSteps] = useState(recipe.steps);

  return (
    <>
      <FlexContainer orientation={Orientation.Horizontal}>
        <h4>
          Steps <Label>{recipe.steps ? recipe.steps.length : 0}</Label>
        </h4>
        {editMode && (
          <FlexChild pushDirection={PushDirection.Right}>
            <Button
              positive
              onClick={() => {
                setSteps([
                  ...steps,
                  {
                    sequence: steps.length + 1,
                    instructions: "",
                    recipeId: recipe.recipeId,
                  },
                ]);
              }}
            >
              Add Step
            </Button>
          </FlexChild>
        )}
      </FlexContainer>
      <div className={classNames("scroll-container steps-container")}>
        {steps &&
          steps
            .sort((s1: any, s2: any) => {
              return s1.sequence - s2.sequence;
            })
            .map((step) => {
              return (
                <StepDisplay
                  key={step.recipeStepId || step.sequence}
                  highlightWords={recipe.ingredients?.map(
                    (i) => i.ingredientName
                  )}
                  onSave={props.saveOne}
                  onChange={(newStep) => {
                    setSteps([
                      ...steps.filter((s) => s.sequence !== newStep.sequence),
                      newStep,
                    ]);
                  }}
                  step={step}
                  canEdit={editMode}
                ></StepDisplay>
              );
            })}
      </div>
      {editMode && (
        <RightSideSave
          content={"Save All"}
          onSave={() => props.saveAll(steps)}
        ></RightSideSave>
      )}
    </>
  );
};

type SaveProps = {
  content: string | React.ReactElement;
  onSave?: () => void;
};

export function RightSideSave(props: SaveProps) {
  return (
    <FlexContainer orientation={Orientation.Horizontal}>
      <FlexChild pushDirection={PushDirection.Right}>
        <Button
          onClick={() => {
            props.onSave();
          }}
          positive
        >
          {props.content}
        </Button>
      </FlexChild>
    </FlexContainer>
  );
}

type StepDisplayProps = {
  step: RecipeStepDTO;
  canEdit: boolean;
  highlightWords: Array<string>;
  onChange: (step: RecipeStepDTO) => void;
  onSave: (step: RecipeStepDTO) => Promise<void>;
};
export const StepDisplay = (props: StepDisplayProps) => {
  const { step, highlightWords, canEdit } = props;
  const [editText, setEditText] = useState(step.recipeStepId ? false : true);
  return (
    <FlexContainer
      orientation={Orientation.Horizontal}
      alignment={Alignment.Baseline}
    >
      <FlexChild>
        <span>{step.sequence}.</span>
      </FlexChild>
      {editText ? (
        <>
          <Input
            className={"grow"}
            type="textarea"
            value={step.instructions}
            onChange={(e) =>
              props.onChange({
                ...step,
                instructions: e.target.value,
              })
            }
          ></Input>
          <Button
            onClick={() => {
              props.onSave({
                recipeStepId: step.recipeStepId,
                recipeId: step.recipeId,
                instructions: step.instructions,
              });
            }}
            positive
            size={"mini"}
            icon={"check"}
          ></Button>
        </>
      ) : (
        <Highlighter
          highlightClassName="highlight"
          searchWords={highlightWords}
          autoEscape={true}
          textToHighlight={step.instructions}
        />
      )}

      {canEdit && (
        <Icon
          onClick={() => setEditText(!editText)}
          name={editText ? "x" : "edit"}
        ></Icon>
      )}
    </FlexContainer>
  );
};

type IngredForm = Pick<
  IngredientDTO,
  | "addToShoppingList"
  | "cookingQuantity"
  | "cookingUnit"
  | "shoppingQuantity"
  | "shoppingUnit"
>;

type IngredientDisplayProps = {
  ingredient: IngredientDTO;
  canEdit: boolean;
  onSave: (ingredient: IngredientDTO) => Promise<void>;
  onDelete: (ingredient: IngredientDTO) => Promise<void>;
};

export const IngredientDisplay = ({
  ingredient,
  canEdit,
  onSave,
  onDelete,
}: IngredientDisplayProps) => {
  const [isEdit, setEdit] = useState(false);
  const initialValues: IngredForm = {
    ...ingredient,
  };

  const [deletBusy, setDeleteBusy] = useState(false);
  return (
    <div>
      {isEdit && canEdit ? (
        <ClickableText onClick={() => canEdit && setEdit(!isEdit)}>
          {ingredient.ingredientName}
        </ClickableText>
      ) : (
        <span>{ingredient.ingredientName}</span>
      )}{" "}
      - {ingredient.cookingQuantity} {ingredient.cookingUnit}
      {canEdit && (
        <Icon
          onClick={() => setEdit(!isEdit)}
          size={"large"}
          name={!isEdit ? "edit" : "x"}
        ></Icon>
      )}
      {isEdit && canEdit && (
        <Formik
          initialValues={initialValues}
          onSubmit={(values, { setSubmitting }) => {
            onSave(values)
              .then(() => {})
              .finally(() => {
                setSubmitting(false);
              });
          }}
        >
          {({ isSubmitting, submitForm }) => (
            <Form loading={isSubmitting}>
              <Form.Field inline>
                <label>Cooking Qty</label>
                <Field name={"cookingQuantity"} type="number" />
              </Form.Field>
              <Form.Field inline>
                <label>Coolking Unit</label>
                <FormikSelect
                  options={unitDropdownOptions}
                  name="cookingUnit"
                ></FormikSelect>
              </Form.Field>
              <Form.Field inline>
                <label>Shopping Qty</label>
                <Field name="shoppingQuantity" type="number" />
              </Form.Field>
              <Form.Field inline>
                <label>Shopping Unit</label>
                <FormikSelect
                  options={unitDropdownOptions}
                  name="shoppingUnit"
                ></FormikSelect>
              </Form.Field>
              <Form.Field inline>
                <label>Auto Add to shopping list</label>
                <Field
                  type="checkbox"
                  className="big-checkbox"
                  name="addToShoppingList"
                  isChecked={true}
                  handlechange={() => {}}
                ></Field>
              </Form.Field>
              <FlexContainer orientation={Orientation.Horizontal}>
                <Button
                  positive
                  loading={isSubmitting}
                  onClick={submitForm}
                  type="submit"
                >
                  Save
                </Button>
                <FlexChild pushDirection={PushDirection.Right}>
                  <Button
                    negative
                    loading={deletBusy}
                    onClick={async () => {
                      try {
                        setDeleteBusy(true);
                        await onDelete(ingredient);
                      } catch {
                        setDeleteBusy(false);
                      }
                    }}
                  >
                    Delete
                  </Button>
                </FlexChild>
              </FlexContainer>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};

export default RecipeDetailsPage;
