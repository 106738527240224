import * as React from "react";
import { UserInfo, UserLoginForm } from "./models/UserInfo";
import { TokenService } from "./axiosAgent";
import { history } from ".";
import { UserController } from "./models/UserController";
import { useToasts } from "react-toast-notifications";
export const UserContext = React.createContext<UserContextInfo>(
  {} as UserContextInfo
);

const { Provider, Consumer } = UserContext;

export type UserContextInfo = {
  data: UserInfo;
  isBusy: boolean;
  operations?: UserApi;
};

export type UserApi = {
  login: (form: UserLoginForm) => Promise<{}>;
  logout(): void;
};

export const UserProvider = (props) => {
  const { addToast } = useToasts();
  const initialized = React.useRef(false);
  const [isBusy, setBusy] = React.useState(true);
  const [form, setForm] = React.useState({
    data: {
      email: "",
      token: "",
    },
  });

  React.useEffect(() => {
    setBusy(true);
    var token = localStorage.getItem("jwt");
    if (token) {
      UserController.currentUser()
        .then((info) => {
          setForm({
            data: {
              email: info.data.email,
              token: info.data.token,
            },
          });
          setBusy(false);
        })
        .catch((err) => {
          localStorage.removeItem("jwt");
          setBusy(false);
          history.push("/login");
        });
    } else {
      setBusy(false);
    }
  }, [null]);

  const handleLogin = (form: UserLoginForm) => {
    return new Promise<{}>((resolve, reject) => {
      UserController.login(form)
        .then((response) => {
          TokenService.saveToken(response.data.token);
          setForm({
            data: {
              email: response.data.email,
              token: response.data.token,
            },
          });
          setBusy(false);
          //Todo history.push
          resolve({});
          history.push("/");
        })
        .catch((err) => {
          addToast(`There was an error logging in`, {
            appearance: "error",
          });
          reject(err);
        });
    });
  };

  const handleLogout = () => {
    TokenService.logout();
    setForm({
      data: {
        email: "",
        token: "",
      },
    });
    setBusy(false);
    history.push("/login");
  };

  return (
    <Provider
      value={{
        data: form.data,
        isBusy: isBusy,
        operations: {
          login: handleLogin,
          logout: handleLogout,
        },
      }}
    >
      {props.children}
    </Provider>
  );
};

export { Consumer as UserConsumer };
