import * as React from "react";
import { Modal, Button, Message, Form } from "semantic-ui-react";

export interface AddRecipeForm {
  recipeName: string;
  description?: string;
}

interface AddRecipeModalProps {
  show: boolean;
  loading: boolean;
  addRecipe(recipe: AddRecipeForm): void;
  onHide(): void;
}
interface AddRecipeModalState {
  form: AddRecipeForm;
}
class AddRecipeModal extends React.Component<
  AddRecipeModalProps,
  AddRecipeModalState
> {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        description: "",
        recipeName: "",
      },
    };
  }

  render() {
    return (
      <Modal
        size={"small"}
        open={this.props.show}
        onClose={this.props.onHide}
        closeIcon
      >
        <Modal.Header>Add New Recipe</Modal.Header>
        <Modal.Content>
          <Message info>
            Pick a name and comments/description for the new recipe
          </Message>
          <Form loading={this.props.loading}>
            <Form.Field>
              <label>Recipe Name</label>
              <input
                type="text"
                value={this.state.form.recipeName}
                onChange={(e: any) => {
                  const recipe = { ...this.state.form };
                  recipe.recipeName = e.currentTarget.value;
                  this.setState({ form: recipe });
                }}
              />
            </Form.Field>
            <Form.Field>
              <label>Description:</label>
              <textarea
                rows={4}
                value={this.state.form.description}
                onChange={(e: any) => {
                  const recipe = { ...this.state.form };
                  recipe.description = e.currentTarget.value;
                  this.setState({ form: recipe });
                }}
              />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            positive
            disabled={!this.state.form.recipeName}
            onClick={() => this.props.addRecipe(this.state.form)}
          >
            Add
          </Button>
          <Button negative onClick={() => this.props.onHide()}>
            Cancel
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default AddRecipeModal;
