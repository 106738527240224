import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Header,
  Button,
  Segment,
  Table,
  Responsive,
  Progress,
  Icon,
} from "semantic-ui-react";
import { ClickableText } from "../../components/ClickableText";
import FlexChild from "../../components/containers/FlexChild";
import FlexContainer from "../../components/containers/FlexContainer";
import { Alignment, Orientation, PushDirection } from "../../components/Enums";
import { dayAndYearFormat } from "../../constants/dateFormatters";
import { routes } from "../../constants/routes";
import { CreateListModal } from "./CreateListModal";
import { TemplateOptionsModal } from "./TemplateOptionsModal";
import { useTemplates } from "./useTemplates";

export const TemplateDashboard = () => {
  const { isLoading, templates, createTemplate, creating } = useTemplates();
  const [showCreate, setShowCreate] = useState(false);
  const history = useHistory();

  return (
    <div>
      <CreateListModal
        show={showCreate}
        busy={creating}
        title={"Create New Template"}
        onClose={() => setShowCreate(false)}
        onSubmit={async (newList) => {
          await createTemplate({
            listName: newList.listName,
          });
          setShowCreate(false);
        }}
      />
      <Header attached="top" as="h4" block>
        <FlexContainer
          alignment={Alignment.Center}
          orientation={Orientation.Horizontal}
        >
          List Templates
          <Icon name="paper plane outline"></Icon>
          <FlexChild pushDirection={PushDirection.Right}>
            <Button onClick={() => setShowCreate(true)} positive>
              + New Template
            </Button>
          </FlexChild>
        </FlexContainer>
      </Header>
      <Segment
        loading={isLoading}
        attached="bottom"
        className="table-container"
      >
        <Table striped id="mainTable" unstackable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell textAlign={"center"}>Count</Table.HeaderCell>
              <Table.HeaderCell textAlign={"center"}>Created</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {templates?.map((template) => (
              <Responsive
                as={Table.Row}
                key={template.toDoListTemplateId}
                className="check-element"
              >
                <Table.Cell>
                  <ClickableText
                    onClick={() =>
                      history.push(
                        routes.todoTemplate.navigate(
                          template.toDoListTemplateId
                        )
                      )
                    }
                  >
                    {template.listName}
                  </ClickableText>
                </Table.Cell>
                <Table.Cell textAlign="center">
                  <span>{template.toDos?.length}</span>
                </Table.Cell>
                <Table.Cell textAlign="center">
                  <span>{dayAndYearFormat(template.createDtTm)}</span>
                </Table.Cell>
              </Responsive>
            ))}
          </Table.Body>
        </Table>
      </Segment>
    </div>
  );
};
