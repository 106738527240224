import React, { useState } from "react";
import { Segment, Label, Header, Item } from "semantic-ui-react";
import { PlannedRecipeDto } from "../../models/PlannedRecipeDto";
import FlexContainer from "../../components/containers/FlexContainer";
import { Orientation } from "../../components/Enums";
import { PlannedFoodDto } from "../../models/PlannedFoodDto";
import { ShoppingListSummary } from "./ShoppingListSummary";
import { areIntervalsOverlappingWithOptions } from "date-fns/fp";
import { ClickableText } from "../../components/ClickableText";
interface PlanSummaryProps {
  plannedRecipes: PlannedRecipeDto[];
  plannedFoods: PlannedFoodDto[];
  servingsNeeded?: number;
  status: string;
}
export const PlanSummary: React.FC<PlanSummaryProps> = ({
  plannedRecipes,
  plannedFoods,
  servingsNeeded,
  status,
  children,
}) => {
  const totalRecipeServings =
    plannedRecipes &&
    plannedRecipes
      .filter((s) => s.countServings && !isNaN(s.multiplier))
      .map((s) => s.multiplier)
      .reduce((a, b) => {
        return a + b;
      }, 0);

  const totalFoodServings =
    plannedFoods &&
    plannedFoods
      .filter((f) => f.countServings && !isNaN(f.shoppingQuantity))
      .map((s) => s.shoppingQuantity)
      .reduce((a, b) => a + b, 0);

  const recipeShopping = plannedRecipes
    .map((s) => (s.addedIngredients ? s.addedIngredients.length : 0))
    .reduce((a, b) => {
      return a + b;
    }, 0);

  const plannedFoodShopping = plannedFoods ? plannedFoods.length : 0;

  const [showShoppingSummary, setshowShoppingSummary] = useState(false);
  return (
    <Segment>
      <Item>
        <Item.Content>
          <Item.Header as="h3">Summary</Item.Header>
          <Header as="h4">Status: {status}</Header>
          <FlexContainer orientation={Orientation.Horizontal}>
            <Label>
              Total servings: {totalRecipeServings + totalFoodServings || 0} /{" "}
              {servingsNeeded || ""}
            </Label>
            <Label onClick={() => setshowShoppingSummary(true)}>
              <ClickableText>
                Shopping List: {plannedFoodShopping + recipeShopping}{" "}
              </ClickableText>
            </Label>
            <ShoppingListSummary
              open={showShoppingSummary}
              onClose={() => setshowShoppingSummary(false)}
              plan={{
                plannedFoods: plannedFoods,
                plannedRecipes: plannedRecipes,
              }}
            ></ShoppingListSummary>
            {children}
          </FlexContainer>
        </Item.Content>
      </Item>
    </Segment>
  );
};
